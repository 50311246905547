export class ContractTemplate {
    id = null;
    description = '';
    allowLinkedWorkOrders = null;
    allowSelfDispatch = null;
    enableNonFlaggingTimesheets = null;
    active = true;
    workOrderTypeId = null;
    workOrderTypeDescription = '';
    contractScheduleTypeId = null;
    contractScheduleTypeDescription = '';
    chargeTypes = [];
    compliances = [];
    createdById = '';
    createdByName = '';
    createdOn = null;
    modifiedById = '';
    modifiedByName = '';
    modifiedOn = null;
}
