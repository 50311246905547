import React from 'react';
import {
    createDataSource,
    createGridOptions,
    DataGrid,
    booleanCellRenderer,
    TextFilterDefaults,
    indexCellRenderer,
    SelectToEditLinkCellRenderer,
    LinkCellRenderer,
    BooleanFilterDefaults,
    ButtonCellRenderer,
} from '../common/dataGrid/DataGrid';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import CommonContext, {
    ApiRoutes,
    AppNavPaths,
    ApplicationPermissions,
} from '../Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressBook } from '@fortawesome/free-solid-svg-icons';
import { LinearProgress } from '@material-ui/core';
import { PageHeading, PageWrap, toasty } from '../common/forms/FormElements';
import { util } from '../Util';
import { withRouter } from 'react-router-dom';
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';
import OrganizationContactForm from '../organization/OrganizationContactForm';
import { LocationContact } from '../location/Location';
import ContactActivityForm from '../contactActivity/ContactActivityForm';
import { getTenantUserProfile } from '../common/TenantUserProfile';
import authService from '../api-authorization/AuthorizeService';

class ContactIndex extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            rowData: [],
            locationContactTypes: [],
            companies: [],
            selectedContact: {},
            showContactsForm: false,
            selectedException: null,
            selectedRow: null,
            contactFormIsReadOnly: false,
        };
        this.onAddContact = this.onAddContact.bind(this);
        this.onEditContact = this.onEditContact.bind(this);
        this.yesNoCellRenderer = this.yesNoCellRenderer.bind(this);
        this.onAddCommunication = this.onAddCommunication.bind(this);

        this.contactFormRef = React.createRef();
        this.contactActivityFormRef = React.createRef();


    }

    componentDidMount = () => this.populateState();
    componentWillUnmount = () =>
        (this.setState = (state, callback) => {
            return;
        });

    yesNoCellRenderer = booleanCellRenderer;

    onEditCompany = (id) => {
        this.props.history.push(`${AppNavPaths.Organization}/${id}`);
    };

    locationContactTypeDescriptionRenderer = (locationContactTypeIdWrapped) => {
        if (
            !locationContactTypeIdWrapped ||
            !locationContactTypeIdWrapped.value
        ) {
            return null;
        }

        //Could also make a Set from the array and check with "has", but doubt it's worth it.
        const rowIds = locationContactTypeIdWrapped.value;

        const allLocationContactTypes = this.state.locationContactTypes;

        const found = allLocationContactTypes.filter(lct => rowIds.indexOf(lct.id) > -1);

        if (found.length === 0) return null;

        const descs = found.map(x => x.description).join(", ");

        return descs;
    };

    onAddCommunication = (id) => {
        const { gridOptions } = this.state;

        let contactName = null;

        gridOptions.api.forEachNode((node) => {
            if (node && node.data && node.data.id == id) {
                const contact = node.data;
                contactName = `${contact.lastName}, ${contact.firstName}`;
            }
        });

        this.contactActivityFormRef.current.openNew(id, contactName);
    };

    async populateState() {
        await authService.isAuthenticated();

        const { tenantSettings } = (this.context ?? {}).tenant ?? {};
        const contactActivitiesEnabled = Boolean(
            tenantSettings && tenantSettings.contactActivitiesEnabled
        );

        const [locationContactTypes, allCompanies, tenantUserProfile] =
            await Promise.all([
                util.fetch.js(ApiRoutes.locationContactType.all()),
                util.fetch.js(ApiRoutes.company.all()),
                getTenantUserProfile(),
            ]);

        const { userPermissions } = { ...tenantUserProfile };
        const hasActivityAccess = userPermissions.includes(
            ApplicationPermissions.contactactivity_create
        );

        const companies = allCompanies.filter((c) =>
            Boolean(c.active && c.locationId)
        );

        let gridOptions = createGridOptions(this);

        gridOptions.components = {
            selectFilter: DataGridSelectFilter,
            selectFloatingFilter: DataGridSelectFloatingFilter,
            linkCellRenderer: LinkCellRenderer,
            companyNameRenderer: SelectToEditLinkCellRenderer,
            buttonCellRenderer: ButtonCellRenderer,
        };

        const companyFilterParams = {
            suppressFilterButton: true,
            labelText: 'Filter by Organization',
            options: companies,
            optionsLabel: 'companyName',
            optionsValue: 'id',
        };

        const companyLinkParams = companies.map((c) => {
            return { value: c.id, text: c.companyName };
        });

        const locationContactTypeFilterParams = {
            suppressFilterButton: true,
            labelText: 'Filter by Contact Type',
            options: locationContactTypes,
            optionsLabel: 'description',
            optionsValue: 'id',
        };

        let isActiveFilterParams = BooleanFilterDefaults;
        isActiveFilterParams.initialFilterValue = 'true';

        gridOptions.columnDefs = [
            {
                flex: 0,
                maxWidth: 80,
                headerName: '',
                valueGetter: 'node.id',
                sortable: false,
                cellRenderer: indexCellRenderer,
            },
            {
                colId: 'lastName',
                sortable: true,
                headerName: 'Last Name',
                field: 'lastName',
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilter: true,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
                cellRenderer: 'linkCellRenderer',
                cellRendererParams: {
                    clicked: this.onEditContact,
                    nameField: 'lastName',
                    idField: 'id',
                    title: 'Edit this Contact',
                },
            },
            {
                colId: 'firstName',
                sortable: true,
                headerName: 'First Name',
                field: 'firstName',
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilter: true,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                coldId: 'companyId',
                sortable: false,
                headerName: 'Organization',
                field: 'companyId',
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: companyFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: companyFilterParams,
                cellRenderer: 'companyNameRenderer',
                cellRendererParams: {
                    clicked: this.onEditCompany,
                    idField: 'companyId',
                    title: 'Edit This Company',
                    entity: 'company',
                    displayValues: companyLinkParams,
                },
            },
            {
                coldId: 'locationContactTypeId',
                sortable: false,
                headerName: 'Contact Type',
                field: 'contactTypes',
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: locationContactTypeFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: locationContactTypeFilterParams,
                cellRenderer: this.locationContactTypeDescriptionRenderer,
            },
            {
                colId: 'title',
                sortable: true,
                headerName: 'Title',
                field: 'title',
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilter: true,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'email',
                sortable: true,
                headerName: 'Email',
                field: 'email',
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilter: true,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'mobilePhone',
                sortable: true,
                headerName: 'Mobile Phone',
                field: 'mobilePhone',
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilter: true,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'isActive',
                sortable: true,
                headerName: 'Active?',
                field: 'isActive',
                cellRenderer: this.yesNoCellRenderer,
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: isActiveFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: isActiveFilterParams,
            },
        ];

        if (contactActivitiesEnabled && hasActivityAccess) {
            gridOptions.columnDefs.push({
                colId: 'addActivity',
                sortable: false,
                cellRenderer: 'buttonCellRenderer',
                cellRendererParams: {
                    clicked: this.onAddCommunication,
                    idField: 'id',
                    title: 'Add Activity',
                    buttonText: 'Activity',
                    action: 'create',
                    category: 'contact_activity',
                },
            });
        }

        let dataSource = createDataSource(
            ApiRoutes.locationContact.search(),
            gridOptions
        );

        this.setState({
            loading: false,
            gridOptions: gridOptions,
            dataSource: dataSource,
            locationContactTypes,
            companies
        });
    }

    onAddContact = () => {
        this.contactFormRef.current.resetForm();

        const selectedContact = new LocationContact();

        this.setState({
            selectedContact,
            showContactsForm: true,
        });
    };

    onEditContact = async (id) => {
        const selectedContact = await util.fetch.js(
            ApiRoutes.locationContact.byId(id)
        );

        this.setState({ selectedContact, showContactsForm: true });
    };

    onOrganizationContactSaved = async () => {
        await this.setState({ contactFormIsReadOnly: true });

        let { selectedContact, gridOptions } = this.state;

        let saved = false;

        if (selectedContact.id) {
            saved = await this.updateLocationContact(selectedContact);
        } else {
            saved = await this.createLocationContact(selectedContact);
        }

        if (saved) {
            this.contactFormRef.current.resetForm();

            gridOptions.refresh();

            this.setState({
                showContactsForm: false,
            });
        }

        this.setState({ contactFormIsReadOnly: false });
    };

    handleSaveError = (error) =>
        toasty.error(`Unable to save contact: ${error.toString()}`);

    updateLocationContact = async (selectedContact) => {
        const updateResult = await util.fetch
            .put(
                ApiRoutes.locationContact.update(selectedContact.id),
                selectedContact,
                util.fetch.format.none
            )
            .catch(this.handleSaveError);

        return Boolean(updateResult.ok);
    };

    createLocationContact = async (selectedContact) => {
        const createResult = await util.fetch
            .post(ApiRoutes.locationContact.create(), selectedContact)
            .catch(this.handleSaveError);

        return Boolean(createResult && !isNaN(createResult));
    };

    onContactActivityFormClosed = () => {
        this.setState({ showContactsForm: false });
    };

    render() {
        const { loading } = this.state;

        if (loading) {
            return <LinearProgress variant="indeterminate" color="secondary" />;
        }

        const {
            rowData,
            gridOptions,
            selectedContact,
            showContactsForm,
            companies,
            contactFormIsReadOnly,
        } = this.state;

        return (
            <PageWrap>
                <PageHeading>
                    <FontAwesomeIcon
                        icon={faAddressBook}
                        className="mr-2 text-muted"
                    />
                    <span>Contacts</span>
                </PageHeading>
                <DataGridToolbar
                    entity="location_contact"
                    gridApi={this.state.gridApi}
                    dataSource={this.state.dataSource}
                    onAdd={this.onAddContact}
                    addLabel="Add Contact"
                    hideExcelButton={true}
                    gridOptions={gridOptions}
                    serverExport={{
                        apiPath: ApiRoutes.locationContact.excelExport(),
                        filePrefix: 'Contacts',
                    }}
                    serverExportDisabled={showContactsForm}
                />
                <DataGrid
                    domLayout={'normal'}
                    rowData={rowData}
                    gridOptions={gridOptions}
                    gridStatus={this.state.gridStatus}
                />
                <OrganizationContactForm
                    ref={this.contactFormRef}
                    readOnly={contactFormIsReadOnly}
                    allowContactTypeEdit={!contactFormIsReadOnly}
                    allowAllContactTypes={ true }
                    show={showContactsForm}
                    contact={selectedContact}
                    companies={companies}
                    showCompany={true}
                    onClose={() => {
                        this.setState({ showContactsForm: false });
                    }}
                    onChange={(e) => {
                        let { selectedContact } = this.state;
                        selectedContact[e.target.name] = e.target.value;
                        this.setState({ selectedContact: selectedContact });
                    }}
                    onCheckedChanged={(e) => {
                        let { selectedContact } = this.state;
                        selectedContact[e.target.name] = e.target.checked;
                        this.setState({ selectedContact: selectedContact });
                    }}                              
                    onContactTypeChanged={(items) => {

                        let { selectedContact } = this.state;
                        selectedContact.contactTypes = items.map(x => x.value);

                        
                        this.setState({ selectedContact: selectedContact });
                    }}                    
                    onCompanyChanged={(item) => {
                        let { selectedContact } = this.state;
                        let id = (item ?? {}).value ?? '';
                        selectedContact.companyId = id;
                        this.setState({ selectedContact });
                    }}
                    onSaveCallback={this.onOrganizationContactSaved}
                />

                <ContactActivityForm
                    onClose={this.onContactActivityFormClosed}
                    ref={this.contactActivityFormRef}
                    onSaveCallback={this.onContactActivityFormClosed}
                    onDeleteCallback={this.onContactActivityFormClosed}
                />
            </PageWrap>
        );
    }
}
export default withRouter(ContactIndex);
