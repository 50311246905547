export const PayrollStatus = {
    Pending: 1,
    Approved: 2,
    Rejected: 3,
    Exported: 4
};

export const PayrollFlags = {
    ChargeTypeThresholdExceeded: 'Charge type recommended hours exceeded',
    BreakThresholdExceeded: 'Break recommended hours exceeded',
    Overlap: 'Overlap',
    NoPayScheduleOnPWContract: 'No pay schedule on prevailing wage contract'
}