import React from 'react';
import { BaseFormViewModel } from '../common/ViewModel';
import { FormCheckbox, FormLabel } from '../common/forms/FormElements';
import CommonContext, { ApiRoutes } from '../Common';
import SlideForm from '../common/forms/SlideForm';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import { util } from '../Util';
import { faAddressCard } from '@fortawesome/free-solid-svg-icons';
import { FormGroup, Input } from 'reactstrap';

export default class OrganizationContactForm extends React.Component {
    static contextType = CommonContext;

    //Works in conjunction with ConstrainContactTypesOnJobEnabled tenant setting.
    //May need to update at some point.
    static filteredContactTypes = ["Foreman", "Office", "Supervisor"];

    constructor(props) {
        super(props);

        this.formRef = React.createRef();
        let stateBase = Object.assign(
            {
                contactTypes: [],
            },
            new BaseFormViewModel()
        );

        let state = stateBase;
        this.state = state;
        this.onClose = this.onClose.bind(this);
        this.resetForm = this.resetForm.bind(this);

    }

    componentDidMount = () => this.populateState();

    async populateState() {
        let [lcts] = await Promise.all([
            util.fetch.js(ApiRoutes.locationContactType.all()),
        ]);

        //Filter contact types unless explicitly set to allow all.
        //Slightly wasteful in that we pull all them and then immediately filter.
        //If we wanted to handle this server-side, modify the ApiRoute.
        
        //https://codesandbox.io/s/wdyiqv?module=/example.tsx&file=/docs/data.ts:176-847
        //isDisabled: true will allow the option to be in the list but not selectable.
        //Useful for when we want to show that an contact has a filtered type but not allow
        //a new contact to use one of the filtered values.

        //Disable a contact type if we aren't allowing all contact types and it's not one of
        //the contact types that "survive" the filtering process.
        const allowAll = !!this.props.allowAllContactTypes;
        lcts.forEach(lct => lct.isDisabled = !allowAll && !OrganizationContactForm.filteredContactTypes.some(fct => fct === lct.description));

        

        //We'll assume sorting is only necessary if we're not filtering contact types.
        //Note: true - false === 1
        if (!allowAll) lcts = lcts.sort((a, b) => a.isDisabled - b.isDisabled);
        

        this.setState((state) => {
            return {
                loading: false,
                contactTypes: lcts.map((x) => {
                    return { label: x.description, value: x.id, isDisabled: x.isDisabled };
                }),
            };
        });
    }

    resetForm() {
        this.setState({ formValidated: false });
    }

    onClose(response) {
        this.resetForm();
        this.context.setFormOpened(false);
        this.props.onClose(response);
    }

    onDelete = async (e) => {
        const { contact } = this.state;
        let response = await util.fetch.delete(
            ApiRoutes.contact.delete(contact.id)
        );
        if (response) this.onClose(response);
    };

    onSubmit = (e) => {
        this.context.setFormOpened(false);
        this.props.onSaveCallback();
    };

    onOpen = () => {
        this.resetForm();
    };

    render() {
        let { contact, companies, showCompany, readOnly, allowContactTypeEdit, show } = this.props;

        const isEdit = contact && contact.id;

        let {
            contactTypes,
            formValidated,
            validationMessage,
            errors,
            loading,
        } = this.state;

        
        if (!contact) {
            return '';
        }

        //Contact Type Edit only allowed if explicitly set and readOnly is false.
        const contactTypeReadOnly = readOnly || !allowContactTypeEdit;
        let contactTypeHelpMessage = null;

        if (contactTypeReadOnly) {

            const hasContactsAccess = this.context.checkUserHasPermission("location_contact.create") || this.context.checkUserHasPermission("location_contact.edit");
            contactTypeHelpMessage = hasContactsAccess ? "Updates to this field can be done on the Contacts page." : "Please contact a system administrator if you need to edit this.";
        }
        else if (!this.props.allowAllContactTypes)
        {
            contactTypeHelpMessage = "Some contact types are unavailable on this page. Please contact a system administrator if you need one that is not available here.";
        }

        const companySelectList =
            showCompany && companies && companies.length
                ? companies.map((c) => {
                      return {
                          label: c.companyName,
                          value: c.id,
                      };
                  })
                : [];

        return (
            <SlideForm
                loading={loading}
                show={show}
                id={'contactForm'}
                formIcon={faAddressCard}
                formTitle={
                    isEdit
                        ? 'Edit Contact'
                        : 'Add Contact'
                }
                ref={this.formRef}
                setIsValidated={(value) => {
                    this.setState({ formValidated: value });
                }}
                isValidated={formValidated}
                onSubmit={this.onSubmit}
                onClose={this.onClose}
                onDelete={this.onDelete}
                errors={errors}
                onClearErrors={this.onClearErrors}
                validationMessage={validationMessage}
                readOnly={readOnly}
            >
                <FormGroup>
                    <FormLabel
                        htmlFor="firstName"
                        text="First Name"
                        required={true}
                    />
                    <Input
                        id="firstName"
                        name="firstName"
                        required
                        placeholder="Enter First Name (max 100 characters)"
                        value={contact.firstName ?? ''}
                        onChange={this.props.onChange}
                        maxLength="100"
                        type="text"
                    />
                    <small className="invalid-feedback text-danger">
                        First Name is required and must be less than 100
                        characters.
                    </small>
                </FormGroup>
                <FormGroup>
                    <FormLabel
                        htmlFor="lastName"
                        text="Last Name"
                        required={true}
                    />
                    <Input
                        id="lastName"
                        name="lastName"
                        required
                        placeholder="Enter Last Name (max 100 characters)"
                        value={contact.lastName ?? ''}
                        onChange={this.props.onChange}
                        maxLength="100"
                        type="text"
                    />
                    <small className="invalid-feedback text-danger">
                        Last Name is required and must be less than 100
                        characters.
                    </small>
                </FormGroup>

                {showCompany && (
                    <FormGroup>
                        <FormLabel
                            htmlFor="companyId"
                            text="Company"
                            required={true}
                        />
                        <ValidatedSelect
                            id="companyId"
                            name="companyId"
                            options={companySelectList}
                            required
                            value={
                                (companySelectList ?? []).find(
                                    (x) => contact.companyId == x.value
                                ) ?? ''
                            }
                            onChange={this.props.onCompanyChanged}
                            validationMessage="Company selection is required."
                        />
                    </FormGroup>
                )}

                <FormGroup>
                    <FormLabel htmlFor="title" text="Title" required={false} />
                    <Input
                        id="title"
                        name="title"
                        placeholder="Enter Title (max 150 characters)"
                        value={contact.title ?? ''}
                        onChange={this.props.onChange}
                        maxLength="150"
                        type="text"
                    />
                    <small className="invalid-feedback text-danger">
                        Title is required and must be less than 150 characters.
                    </small>
                </FormGroup>
                <FormGroup>
                    <FormLabel htmlFor="email" text="Email" />
                    <input
                        id="email"
                        name="email"
                        autoComplete="off"
                        placeholder="ex. name@service.com"
                        type="email"
                        className="form-control"
                        value={contact.email ?? ''}
                        onChange={this.props.onChange}
                    />
                    <small className="invalid-feedback text-danger">
                        A valid email is required.
                    </small>
                </FormGroup>
                <FormGroup>
                    <FormLabel htmlFor="mobilePhone" text="Mobile Phone" />
                    <input
                        id="mobilePhone"
                        name="mobilePhone"
                        placeholder="ex. 555-555-5555"
                        autoComplete="off"
                        type="text"
                        pattern={util.validation.patterns.phone}
                        className="form-control"
                        value={contact.mobilePhone ?? ''}
                        onChange={this.props.onChange}
                    />
                    <small className="invalid-feedback text-danger">
                        Please ensure the phone number is valid (ex.
                        555-555-5555).
                    </small>
                    <FormCheckbox
                        small={true}
                        className="ml-3 mt-2"
                        id={'mobilePhoneIsPrimary'}
                        name={'mobilePhoneIsPrimary'}
                        checked={contact.mobilePhoneIsPrimary || false}
                        onChange={this.props.onCheckedChanged}
                        labelText={'Mobile is primary'}
                    />
                </FormGroup>

                <FormGroup>
                    <FormLabel htmlFor="officePhone" text="Office Phone" />
                    <input
                        id="officePhone"
                        name="officePhone"
                        placeholder="ex. 555-555-5555"
                        autoComplete="off"
                        type="text"
                        pattern={util.validation.patterns.phone}
                        className="form-control"
                        value={contact.officePhone ?? ''}
                        onChange={this.props.onChange}
                    />
                    <small className="invalid-feedback text-danger">
                        Please ensure the phone number is valid (ex.
                        555-555-5555).
                    </small>
                    <FormCheckbox
                        small={true}
                        className="ml-3 mt-2"
                        id={'officePhoneIsPrimary'}
                        name={'officePhoneIsPrimary'}
                        checked={contact.officePhoneIsPrimary || false}
                        onChange={this.props.onCheckedChanged}
                        labelText={'Office is primary'}
                    />
                </FormGroup>

                <FormGroup>
                    <FormLabel
                        htmlFor="locationContactTypeId"
                        text="Contact Type"
                        helpMessage={contactTypeHelpMessage}
                        required={true}
                    />
                    <ValidatedSelect
                        id="locationContactTypeId"
                        name="locationContactTypeId"
                        options={contactTypes}
                        isMulti
                        required
                        isDisabled={contactTypeReadOnly}
                        //contactTypes: label == description, value == id
                        value={(contactTypes ?? []).filter(x => (contact.contactTypes ?? []).includes(x.value) ?? '')}
                        onChange={this.props.onContactTypeChanged}
                        validationMessage="Contact Type selection is required."
                    />
                </FormGroup>

                <FormGroup>
                    <FormLabel htmlFor="isActive" text="Active?" />
                    <FormCheckbox
                        className="ml-3 mt-2"
                        id={'isActive'}
                        name={'isActive'}
                        checked={contact.isActive || false}
                        onChange={this.props.onCheckedChanged}
                        labelText={'Contact is active'}
                    />
                </FormGroup>
            </SlideForm>
        );
    }
}
