import React from 'react';
import * as EmailValidator from 'email-validator';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

export function getAddressesAndValidity(emailAddresses) {
    if (!emailAddresses) {
        return [];
    }

    // https://stackoverflow.com/a/37334192
    const addressList = emailAddresses
        .split(',')
        .map((e) => e.split(';'))
        .flat();

    let checkedAddresses = [];

    addressList.forEach((emailAddressProvided) => {
        if (!emailAddressProvided) {
            return;
        }

        const emailAddress = emailAddressProvided.trim();

        if (!emailAddress) {
            return;
        }

        const isValid = EmailValidator.validate(emailAddress);

        checkedAddresses.push({ emailAddress, isValid });
    });

    return checkedAddresses;
}

export class ValidatedEmailDistributionList extends React.Component {
    render() {
        const { emailAddresses } = this.props;

        if (!emailAddresses) {
            return null;
        }

        const checkedAddresses = getAddressesAndValidity(emailAddresses);

        if (!checkedAddresses || checkedAddresses.length === 0) {
            return <div>No addresses found.</div>;
        }

        return (
            <div>
                {checkedAddresses.map((checkedAddress, i) => {
                    if (checkedAddress.isValid) {
                        return (
                            <Button
                                className="mr-2"
                                color="success"
                                size="sm"
                                key={`checked_${i}`}
                                disabled
                            >
                                <FontAwesomeIcon
                                    icon={faCheck}
                                    className="mr-1"
                                />
                                {checkedAddress.emailAddress}
                            </Button>
                        );
                    }

                    return (
                        <Button
                            className="mr-2"
                            color="danger"
                            size="sm"
                            key={`checked_${i}`}
                            disabled
                        >
                            <FontAwesomeIcon
                                icon={faTimesCircle}
                                className="mr-1"
                            />
                            {checkedAddress.emailAddress}
                        </Button>
                    );
                })}
            </div>
        );
    }
}
