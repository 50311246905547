export const DispatchResourceTabNumbers = { Staff: 1, Equipment: 2, Crews: 3 };
export const DispatchJobTabNumbers = { Planning: 1, Ops: 2, Cancellations: 3 };
export const JobAssignmentCancellationType = {
    ClientCancel: 1,
    DispatchCancel: 2,
    CallOff: 3,
    NoShow: 4,
};

export const DispatchResourceTabs = [
    {
        number: DispatchResourceTabNumbers.Staff,
        name: 'Staff',
        icon: 'fa-user',
    },
    {
        number: DispatchResourceTabNumbers.Equipment,
        name: 'Equipment',
        icon: 'fa-toolbox',
    },
    {
        number: DispatchResourceTabNumbers.Crews,
        name: 'Crews',
        icon: 'fa-users',
    },
];

export const DispatchJobTabs = [
    { number: DispatchJobTabNumbers.Planning, name: 'Planning' },
    /*{ number: DispatchJobTabNumbers.Ops, name: 'Ops' },*/
    { number: DispatchJobTabNumbers.Cancellations, name: 'Cancellations' },
];

export const StaffSortTypes = [
    { id: 1, label: 'Hours - Ascending' },
    { id: 2, label: 'Hours - Descending' },
    { id: 3, label: 'Name - Ascending' },
    { id: 4, label: 'Name - Descending' },
];

export const DateTimeInputFormat = (date) =>
    new Date(date.getTime() + new Date().getTimezoneOffset() * -60 * 1000)
        .toISOString()
        .slice(0, 16);

export class DispatchBoardViewModel {
    activeJobTab = {
        ...DispatchJobTabs[0],
    };
    activeResourceTab = {
        ...DispatchResourceTabs[0],
    };
    allJobsCollapsed = false;
    allCancellationJobsCollapsed = false;
    crewsInterfaceOpen = false;
    dragging = false;
    equipment = [];
    isAuthenticated = false;
    jobsLoading = false;
    jobsOpened = [];
    jobSearchText = '';
    cancellationJobSearchText = '';
    jobWorkflowModalOpen = false;
    lastPushUpdate = '';
    loading = true;
    loadingEquipment = false;
    opsJobs = [];
    planningJobs = [];
    cancellationJobs = [];
    cancellationJobsOpened = [];
    cancellationJobsFilteredCount = 0;
    cancellationJobsMaxRows = 0;
    saving = false;
    resourcesLoading = false;
    resourceSearchText = '';
    selectedEmergencyAssignments = null;
    selectedWorkflowJob = null;
    selectedJobToCancel = null;
    selectedJobAssignmentToRemove = null;
    selectedJobDispatchLocation = null;
    selectedResourceDispatchLocation = null;
    selectedJobCancellationType = null;
    selectedJobCancellationNotes = '';
    selectedJobCancellationTime = DateTimeInputFormat(new Date());
    selectedJobAssignmentCancellationType = null;
    selectedJobAssignmentCancellationNotes = '';
    selectedJobAssignmentCancellationTime = DateTimeInputFormat(new Date());
    selectedStaffSort = StaffSortTypes[0];
    selectedWeek = null;
    selectedWeekRangeDays = [];
    showCancelAssignmentModal = false;
    staffSortDropdownOpen = false;
    staff = [];
    allStaffIds = [];
    staffLoading = false;
    weeks = [];
    noResponse = false;
    showModal = false;
    showEmergencyConfirmModal = false;
    longTermStatuses = [];
    employeeLongTermStatuses = [];
    employeeLongTermStatusesLoading = false;
    dailyAttendanceEvents = [];
    employeeDailyAttendanceEvents = [];
    employeeDailyAttendanceEventsLoading = false;
    areAttendanceEventsEnabled = false;
    hiddenEmployeeCount = 0;
    onlyShowUnassigned = false;
    onlyShowAttendance = false;
    tenantSettings = {};
    onlyShowUnassignedEquipment = false;
    jobAssignmentCancellationTypeIdsThatPreventReassignment = [];
    expandedCancellationReasonRequired = false;
    companyContacts = [];
    cancelJobDataLoading = false;
    cancelContactId = null;
}

export class DispatchResourceSearchParams {
    dates = [];
    searchText = '';
    dispatchLocation = null;
}

export class DispatchJobSearchParams {
    weekStartDate = '';
    weekEndDate = '';
    dispatchLocation = null;
}

export class JobAssignment {
    id = 0;
    resourceId = null;
    weekOfDate = '';
    assignmentDate = '';
    day = -1;
    assignedEmployeeId = null;
    assignedEmployeeName = '';
    assignedEquipmentId = null;
    assignedEquipmentName = '';
    equipmentEmployeeAssignmentId = null;
    equipmentEmployeeAssignmentName = '';
    confirmationStatusId = null;
    confirmationStatusName = '';
}

export class PlanningJob {
    id = null;
    name = '';
    number = '';
    address = '';
    foreman = '';
    assignments = [];
    //UI specific properties
    detailsOpen = false;
}

export const getJobAssignmentCancellationTypeIdsThatPreventReassignment = (
    jobAssignmentCancellationTypes
) => {
    if (
        !jobAssignmentCancellationTypes ||
        !jobAssignmentCancellationTypes.length
    ) {
        return [];
    }

    return jobAssignmentCancellationTypes
        .filter((jact) => jact.preventsReassignment)
        .map((jact) => jact.id);
};

export const isCancelAssignmentTreatedAsUnassigned = (
    assignment,
    jobAssignmentCancellationTypes
) => {
    if (!assignment || !assignment.jobAssignmentCancellationTypeId) {
        return false;
    }

    if (assignment.thresholdCancel) {
        return false;
    }

    const jobAssignmentCancellationType = jobAssignmentCancellationTypes.find(
        (jact) => jact.id == assignment.jobAssignmentCancellationTypeId
    );

    return (
        jobAssignmentCancellationType &&
        jobAssignmentCancellationType.treatAsUnassigned
    );
};

export const isCancelAssignmentTreatedAsCancelled = (
    assignment,
    jobAssignmentCancellationTypes
) => {
    if (!assignment || !assignment.jobAssignmentCancellationTypeId) {
        return false;
    }

    if (assignment.thresholdCancel) {
        return true;
    }

    const jobAssignmentCancellationType = jobAssignmentCancellationTypes.find(
        (jact) => jact.id == assignment.jobAssignmentCancellationTypeId
    );

    return (
        jobAssignmentCancellationType &&
        !jobAssignmentCancellationType.treatAsUnassigned
    );
};
