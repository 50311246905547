import React from 'react';
import {
    AppPageForm,
    FlexCenterRow,
    FormDivider,
    FormGroupColumn,
    GroupedRow,
    onFieldChange,
    onReactSelectChanged,
    SubHeading,
    ToastMessage,
    SimpleTable,
    FormCheckbox
} from '../common/forms/FormElements';
import { BaseFormViewModel } from '../common/ViewModel';
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import { faCheckCircle, faSave, faWarehouse, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FormLabel } from '../common/forms/FormElements';
import { ChargeTypeGroup } from './ChargeTypeGroup';
import {
    Button,
    Input,
    FormGroup,
    Row,
    Col
} from 'reactstrap';
import { util } from '../Util';
//import ValidatedSelect from '../common/forms/ValidatedSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router-dom';
import { handleFormSaveError } from '../common/forms/ValidationError';
import { toast } from 'react-toastify';
import NumericInput from '../common/forms/NumericInput';
import ChargeTypeSelect from '../chargeType/ChargeTypeSelect';
import { getTenantUserProfile } from '../common/TenantUserProfile';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import { NotAuthorizedPage } from '../status/StatusCodes';
import { LinearProgress } from '@material-ui/core';
import './ChargeTypeGroupForm.scss';

class ChargeTypeGroupForm extends React.Component {

    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.chargeTypeSelectorRef = React.createRef();

        let stateBase = Object.assign(
            {   
                group: new ChargeTypeGroup()  
            },
            new BaseFormViewModel()
        );

        this.state = stateBase;
        this.onSelectChanged = this.onSelectChanged.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onChange = this.onChange.bind(this);  
    }

    componentDidMount = () => this.populateState();
    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps && (this.props.match.params.id !== (prevProps.match.params ?? {}).id)) {
            this.populateState();
        }
    }

    async populateState() {
        const groupId = this.props.match.params.id;

        var [group] = await Promise.all([            
            !!groupId ? util.fetch.js(ApiRoutes.chargeTypeGroup.byId(groupId)) : new ChargeTypeGroup()
        ]);

        let originalData = util.object.clone(group);

        //Inventory items
        const { userTenant } = await getTenantUserProfile();
        const { tenantSettings } = { ...userTenant };

        var [inventoryItemsRaw] = await Promise.all([
            util.fetch.js(ApiRoutes.typeAheads.quickBooksInventoryItems())
        ]);

        let inventoryItems = [];

        if ((inventoryItemsRaw ?? []).length) {
            inventoryItems = inventoryItemsRaw.map(x => { return { label: x.name, value: x.id } });
        }
        //Inventory items

        this.setState(state => {
            return {
                originalData: originalData,
                group: group,
                loading: false,
                saving: false,
                associateInventoryItem: tenantSettings.useQuickBooksInvoicing,
                inventoryItems: inventoryItems,
                billingRateTypes: [{ label: 'OT', value: 1 }, { label: 'Regular', value: 2 }]
            }
        });
    }

    onChange = onFieldChange;
    onSelectChanged = onReactSelectChanged;
    handleSaveError = (err) => handleFormSaveError(this, err);
    onClearErrors = () => this.setState((state) => { return { errors: {} }; });
    setSaving = (b) => this.setState({ saving: b });

    onSubmit = async e => {
        //Clear any fluent api errors
        this.onClearErrors();
        this.setSaving(true);

        const { group } = this.state;

        var cloned = util.object.clone(group);

        this.props.location.pathname == AppNavPaths.ChargeTypeGroupNew ? this.createGroup(cloned) : this.updateGroup(cloned);
    }

    updateGroup = async (group) => { 

        let response = await util.fetch.put(ApiRoutes.chargeTypeGroup.byId(group.id), group)
            .catch(this.handleSaveError);

        if (response && parseInt(response.id ?? 0) >= 0) {            
            toast.success(<ToastMessage icon={faCheckCircle}
                header={'Save Successful'}
                message={`[${response.invoiceDescription}] saved.`}
            />);
            util.navigation.localRedirect(this, `${AppNavPaths.ChargeTypeGroup}/${response.id}`);
        } 

        this.setSaving(false);        
    }

    createGroup = async (group) => {

        let response = await util.fetch.post(ApiRoutes.chargeTypeGroup.create(), group)
            .catch(this.handleSaveError);

        if (response && !isNaN(response)) {

            toast.success(<ToastMessage icon={faCheckCircle}
                header={'Save Successful'}
                message={`Saved.`}
            />);
            util.navigation.localRedirect(this, `${AppNavPaths.ChargeTypeGroup}/${response}`);
        }

        this.setSaving(false);
    }

    resetForm = () => this.setState({ formValidated: false });

    onClose = (response) => {
        this.resetForm();
        this.context.setFormOpened(false);
        this.props.onClose(response);
    }

    onDelete = async e => {
        const { group } = this.state;
        let response = await util.fetch.delete(ApiRoutes.chargeTypeGroup.delete(group.id)).catch(this.onCatchFetchError);
        if (response)
            this.onClose(response);
    }

    //#region CHARGE TYPES
    onAddChargeTypes = () => {
        const { group } = { ...this.state };
        const { chargeTypes } = { ...group };
        const typesToOmit = (chargeTypes ?? []).map(c => c.chargeTypeId) ?? [];
        //pass in types already selected
        this.chargeTypeSelectorRef.current.open(null, [...typesToOmit]);
    }

    onAddChargeTypesCallback = (selections) => {
        let { group } = { ...this.state };
        let updated = [...group.chargeTypes] ?? [];

        //get selections from the popup
        let selectedChargeTypes = [...selections].map((x, index) => {
            return {
                chargeTypeGroupId: 0,
                chargeTypeId: x.id,                
                description: x.description,
                groups: x.appliesToGroup,
                employees: x.appliesToUsers,
                equipment: x.appliesToEquipment,
                quantity: 1,
                billingRateTypeId: 2
            }
        });

        //merge additions with current charges
        updated = updated.concat([...selectedChargeTypes]);
        
        group.chargeTypes = [...updated];

        this.setState({ group: { ...group } });
    }

    onRemoveChargeType = (chargeTypeIndex) => {
        let { group } = { ...this.state };
        let chargeTypes = [...group.chargeTypes]
        chargeTypes.splice(chargeTypeIndex, 1);
        group.chargeTypes = [...chargeTypes];
        this.setState({ group: { ...group } });
    }

    renderGroupChargeTypesRows = () => {
        let { billingRateTypes, group } = { ...this.state };
        let chargeTypes = group.chargeTypes;

        if (!chargeTypes) return '';

        return chargeTypes.map((chargeType, chargeTypeIndex) => {            
            return (
                <tr key={chargeTypeIndex} style={{maxHeight: '10px'}}>
                    <td style={{ verticalAlign: 'middle' }}>
                        {chargeType.description}                     
                    </td>
                    <td style={{ verticalAlign: 'middle' }}>{chargeType.equipment}</td>
                    <td className="text-center" width="100">
                        <div style={{ marginTop: '12px' }}>
                        <NumericInput
                            className="text-right"
                            name={`quantity${chargeTypeIndex}`}
                            required
                            value={chargeType.quantity ?? ''}
                            onChange={(event) => {
                                let val = event.target.value, { group } = { ...this.state };
                                group.chargeTypes[chargeTypeIndex].quantity = val;
                                this.setState({ group: group });
                            }}
                            type="number"
                            min="1"
                            step="1"
                            wholeNumber={true}
                            preventNegative={true}
                            />
                        </div>
                    </td>
                    <td style={{ verticalAlign: 'middle' }} width="150">
                        <div style={{ marginTop: '12px' }}>
                            <FormGroup>
                                <ValidatedSelect
                                    name={`billingRateTypeId${chargeTypeIndex}`}
                                    options={billingRateTypes}
                                    hideClear
                                    value={(billingRateTypes ?? []).find(s => s.value == chargeType.billingRateTypeId) ?? ''}
                                    onChange={(event) => {
                                        let val = event.value, { group } = { ...this.state };
                                        group.chargeTypes[chargeTypeIndex].billingRateTypeId = val;
                                        this.setState({ group: group });
                                    }}
                                    />
                            </FormGroup>
                        </div>
                    </td>
                    <td style={{ verticalAlign: 'middle' }} className="text-center">
                        <FontAwesomeIcon
                            icon={faTimes}
                            title={`Remove ${chargeType.description}`}
                            className="text-danger cursor-pointer"
                            onClick={() => this.onRemoveChargeType(chargeTypeIndex)}
                        />
                    </td>
                </tr>
            )
        });
    }

    render() {
       
        return (
            <CommonContext.Consumer>
                {value => {

                    const { tenantSettings } = (value ?? {}).tenant ?? {};

                    if (!tenantSettings) return (<LinearProgress variant="indeterminate" color="secondary" />);

                    const {
                        group,
                        originalData,
                        errors,
                        formValidated,
                        saving
                    } = this.state;
                    let inventoryItems = (this.state.inventoryItems ?? []);
                    let existing = !!group.id;


                    const invoicingEnabled = !!tenantSettings.invoicingEnabled;
                    const chargeTypeGroupsEnabled = !!tenantSettings.chargeTypeGroupsEnabled;

                    return (
                        <>
                            {
                                !invoicingEnabled || !chargeTypeGroupsEnabled ? <NotAuthorizedPage /> :
                                    <>
                                        <AppPageForm
                                            formShown={this.context.formIsOpen}
                                            formId={"groupForm"}
                                            formHeadingIcon={faWarehouse}
                                            formHeading={!group.id ? 'New Charge Type Group' : 'Edit Charge Type Group'}
                                            formName={"groupForm"}
                                            formRef={this.formRef}
                                            onSubmit={this.onSubmit}
                                            setIsValidated={(value) => { this.setState({ formValidated: value }) }}
                                            isValidated={formValidated}
                                            saving={saving}
                                            errors={errors}
                                            onClearErrors={this.onClearErrors}
                                            loading={this.state.loading}
                                        >
                                            <SubHeading>Details</SubHeading>
                                            <GroupedRow>
                                                <FormGroupColumn>
                                                    <FormGroup>
                                                        <FormLabel htmlFor="invoiceDescription"
                                                            text="Invoice Description"
                                                            required={true} />
                                                        <Input id="invoiceDescription"
                                                            name="group.invoiceDescription"
                                                            value={group.invoiceDescription ?? ''}
                                                            onChange={this.onChange}
                                                            placeholder="Enter Invoice Description (max 150 characters)"
                                                            maxLength="150"
                                                            pattern="[^()/><\][\\\x22,;|]+"
                                                            type="text"
                                                            required
                                                        />
                                                        <small className="invalid-feedback text-danger">Invoice Description is required and can only contain the following special characters: hyphens and periods.</small>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <FormLabel htmlFor="billRate"
                                                            text="Bill Rate"
                                                            required={true} />
                                                        <Input id="billRate"
                                                            name="group.billRate"
                                                            value={group.billRate ?? ''}
                                                            onChange={this.onChange}
                                                            placeholder="Enter Bill Rate"
                                                            type="number"
                                                            step=".5"
                                                            required
                                                        />
                                                        <small className="invalid-feedback text-danger">A flat charge is required.</small>
                                                    </FormGroup>
                                                    {/*<FormGroup>*/}
                                                    {/*    <FormLabel htmlFor="billRate"*/}
                                                    {/*        text="OT Bill Rate"*/}
                                                    {/*        required={true} />*/}
                                                    {/*    <Input id="billRate"*/}
                                                    {/*        name="group.billRate"*/}
                                                    {/*        value={group.billRate ?? ''}*/}
                                                    {/*        onChange={this.onChange}*/}
                                                    {/*        placeholder="Enter Bill Rate"*/}
                                                    {/*        type="number"*/}
                                                    {/*        step=".5"*/}
                                                    {/*        required*/}
                                                    {/*    />*/}
                                                    {/*    <small className="invalid-feedback text-danger">A flat charge is required.</small>*/}
                                                    {/*</FormGroup>*/}
                                                    {!group.summedBillQuantity &&
                                                        <FormGroup>
                                                            <FormLabel htmlFor="billQuantity"
                                                                text="Bill Quantity"
                                                                required={true} />
                                                            <Input id="billQuantity"
                                                                name="group.billQuantity"
                                                                value={group.billQuantity ?? ''}
                                                                onChange={this.onChange}
                                                                placeholder="Enter Hours"
                                                                type="number"
                                                                step=".25"
                                                                required
                                                            />
                                                            <small className="invalid-feedback text-danger">The quantity in hours is required.</small>
                                                        </FormGroup>
                                                    }

                                                    {this.state.associateInventoryItem && inventoryItems.length &&
                                                        <FormGroup>
                                                            <FormLabel
                                                                htmlFor="qbInventoryItemId"
                                                                text="QuickBooks Inventory Item"
                                                                required={false} />
                                                            <ValidatedSelect
                                                                id="quickBooksInventoryItemId"
                                                                name="group.quickBooksInventoryItemId"
                                                                options={inventoryItems}
                                                                value={(inventoryItems ?? []).find(s => s.value == group.quickBooksInventoryItemId) ?? ''}
                                                                onChange={this.onSelectChanged}
                                                            />
                                                        </FormGroup>
                                                    }
                                                    <FormGroup>
                                                        <FormCheckbox
                                                            id="summedBillQuantity"
                                                            checked={group.summedBillQuantity}
                                                            onChange={(e) => {
                                                                group.summedBillQuantity = e.target.checked;
                                                                this.setState({ group: group });
                                                            }}
                                                            labelText="Summed Bill Quantity"
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <FormCheckbox
                                                            id="autoCalculate"
                                                            checked={ group.autoCalculate }
                                                            onChange={(e) => {
                                                                group.autoCalculate = e.target.checked;
                                                                this.setState({ group: group });
                                                            }}
                                                            labelText="Auto Generate"
                                                            />
                                                    </FormGroup>
                                                </FormGroupColumn>
                                                
                                            </GroupedRow>
                                            <Row>
                                                <Col>
                                                    <SubHeading>Charge Types</SubHeading>
                                                    <SimpleTable
                                                        className="mb-0"
                                                        id={'chargeTypesTable'}
                                                        onAddFunction={this.onAddChargeTypes}
                                                        addButtonEnabled={true}
                                                        addLabelText={!!this.context.formIsOpen ? <span><i className="fa fa-spin fa-circle-notch mr-1"></i>Opening charge types form...</span> : "Add Charge Types"}
                                                        noTopBorder={true}
                                                        permission={'chargetype'}
                                                        tableHeaderLabels={[
                                                            { name: 'Description' },
                                                            { name: 'Equipment' },
                                                            { name: 'Quantity' },
                                                            { name: 'Rate Type' },
                                                            { name: '', class: 'text-center' }
                                                        ]}
                                                        editable={false}
                                                        entities={(this.state.group ?? {}).chargeTypes ?? []}
                                                        rowRenderer={this.renderGroupChargeTypesRows}
                                                        noDataText={'No charge types found.'}
                                                    />
                                                </Col>
                                            </Row>
                                            <FormDivider />

                                            <FlexCenterRow className="mb-3">
                                                <Button
                                                    disabled={!!this.state.saving}
                                                    size="sm"
                                                    type="submit"
                                                    color="primary"
                                                    name="groupForm">
                                                    <FontAwesomeIcon
                                                        className="mr-2"
                                                        icon={faSave} />
                                                    {!!this.state.saving
                                                        ? 'Saving...'
                                                        : (!existing ? 'Save New Charge Type Group' : 'Save')
                                                    }
                                                </Button>
                                            </FlexCenterRow>
                                        </AppPageForm>

                                        <ChargeTypeSelect
                                            ref={this.chargeTypeSelectorRef}
                                            id="chargeTypeSelector"
                                            onAddCallback={this.onAddChargeTypesCallback}
                                            billableOnly={true}
                                        />
                                    </>

                            }
                        </>)

                }}
            </CommonContext.Consumer>
        );
            

   

       
    }
}
export default withRouter(ChargeTypeGroupForm);