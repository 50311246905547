import React, { Fragment } from 'react';
import AddressInput from '../address/AddressInput';
import {
    AppPageForm,
    FlexCenterRow,
    GroupedRow,
    FlexColumnCenter,
    FormCheckbox,
    FormDivider,
    onFieldChange,
    onReactSelectChanged,
    SimpleTable,
    SubHeading,
    ToastMessage,
    YesNoBadge,
} from '../common/forms/FormElements';
import { BaseFormViewModel } from '../common/ViewModel';
import { Can } from '../Can';
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import {
    faCheckCircle,
    faSave,
    faWarehouse,
} from '@fortawesome/free-solid-svg-icons';
import { FormLabel } from '../common/forms/FormElements';
import {
    OrganizationTypes,
    Organization,
    OrganizationContact,
    CompanyChargeTypeBillingRate,
    MinimumWageRate,
    BillingOTDays,
} from './Organization';
import MinimumWageRateForm from '../organization/MinimumWageRateForm';
import { MinimumWageRateGrid } from '../organization/MinimumWageRateGrid';
import BillingRateForm from '../billing/BillingRateForm';
import BillingRateHistoryForm from '../billing/BillingRateHistoryForm';
import { BillingRateGrid } from '../billing/BillingRateGrid';
import OrganizationContactForm from './OrganizationContactForm';
import {
    Button,
    Input,
    FormGroup,
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';
import { util } from '../Util';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Prompt, withRouter } from 'react-router-dom';
import { handleFormSaveError } from '../common/forms/ValidationError';
import { toast } from 'react-toastify';
import { isEqual } from 'lodash-es';
import _ from 'lodash-es';
import { OrganizationChargeTypeGroupGrid } from './OrganizationChargeTypeGroupGrid';
import NumericInput from '../common/forms/NumericInput';
import TimeEntry from '../common/forms/TimeEntry';
import * as moment from 'moment';

class OrganizationForm extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.contactFormRef = React.createRef();
        this.rateFormRef = React.createRef();
        this.minWageRateFormRef = React.createRef();
        this.rateHistoryFormRef = React.createRef();
        this.billingRatesRef = React.createRef();

        let stateBase = Object.assign(
            {
                contactsFormValidated: false,
                customers: [],
                invoiceTerms: [],
                invoiceMappings: [],
                isCustomer: false,
                isOperationsCenter: false,
                isSubcontractor: false,
                isParentCustomer: false,
                isProspect: false,
                organization: new Organization(),
                types: [],
                selectedContact: null,
                showContactsForm: false,
                parentCompanies: [],
                subcontractorCustomersOptions: [],
                contactFormIsReadOnly: false,
                chargeTypeGroups: [],
                showCTGroupModal: false,
                selectedCTGroups: [],
                selectedMinWageRate: null,
                showMinWageRateForm: false,
                showMinWageRateFormDelete: true,
                useInvoicingRequired: false,
            },
            new BaseFormViewModel()
        );

        this.state = stateBase;
        this.onSubmit = this.onSubmit.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSelectChanged = this.onSelectChanged.bind(this);
        this.updateOrganization = this.updateOrganization.bind(this);
    }

    //#region INIT
    componentDidMount = () => {
        this.populateState();
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (
            prevProps &&
            this.props.match.params.id !== (prevProps.match.params ?? {}).id
        ) {
            this.populateState();
        }

        if (
            prevProps.location.hash == '#billingRates' &&
            this.billingRatesRef.current != undefined
        ) {
            this.billingRatesRef.current.scrollIntoView();
        }
    };

    async populateState() {
        const orgId = this.props.match.params.id;

        let [
            companyTypes,
            companyStatuses,
            organization,
            allCustomers,
            chargeTypes,
            invoiceTerms,
            invoiceMappings,
            chargeTypeGroups,
        ] = await Promise.all([
            util.fetch.js(ApiRoutes.typeAheads.companyTypes()),
            util.fetch.js(ApiRoutes.typeAheads.companyStatuses()),
            !!orgId
                ? util.fetch.js(ApiRoutes.company.byId(orgId))
                : new Organization(),
            util.fetch.js(ApiRoutes.typeAheads.subcontractingCustomers()),
            util.fetch.js(ApiRoutes.typeAheads.chargeTypesBillable()),
            util.fetch.js(ApiRoutes.typeAheads.invoiceTerms()),
            util.fetch.js(ApiRoutes.typeAheads.invoiceMapping()),
            util.fetch.js(ApiRoutes.typeAheads.chargeTypeGroups()),
        ]);

        let originalData = util.object.clone(organization);

        const [usStates] = await Promise.all([
            util.fetch.js(ApiRoutes.typeAheads.USStates()),
        ]);

        organization.billingOTShiftStart = !!organization.billingOTShiftStart
            ? moment(organization.billingOTShiftStart, 'YYYY-MM-DD hh:mm:ssA').format('HH:mm A')
            : '';
        organization.billingOTShiftEnd = !!organization.billingOTShiftEnd
            ? moment(organization.billingOTShiftEnd, 'YYYY-MM-DD hh:mm:ssA').format('HH:mm A')
            : '';

        organization.billingOTJobStart = !!organization.billingOTJobStart
            ? moment(organization.billingOTJobStart, 'YYYY-MM-DD hh:mm:ssA').format('HH:mm A')
            : '';
        organization.billingOTJobEnd = !!organization.billingOTJobEnd
            ? moment(organization.billingOTJobEnd, 'YYYY-MM-DD hh:mm:ssA').format('HH:mm A')
            : '';

        this.setState({
            originalData,
            organization,
            loading: false,
            companyTypes,
            companyStatuses,
            saving: false,
            subcontractorCustomersOptions: allCustomers,
            chargeTypes,
            invoiceTerms,
            invoiceMappings,
            chargeTypeGroups,
            allowContactTypeEdit: false,
            usStates,
            useInvoicingRequired: false,
        });

        this.onOrgTypeChanged();
    }

    onChange = onFieldChange;
    onSelectChanged = onReactSelectChanged;
    handleSaveError = (err) => handleFormSaveError(this, err);
    onClearErrors = () =>
        this.setState((state) => {
            return { errors: {} };
        });
    setSaving = (b) => this.setState({ saving: b });
    //#endregion

    //#region METHODS
    onSubmit = async (e) => {
        //Clear any fluent api errors
        this.onClearErrors();
        this.setSaving(true);

        const { organization } = { ...this.state };

        /** Revert temporary hashes used in row keys.  Need this to get around
            fluent API.
        */
        for (let contact of organization.contacts)
            contact.id = contact.id?.constructor === String ? null : contact.id;

        for (let rate of organization.billingRates) {
            rate.billingRateId =
                rate.billingRateId.constructor === String
                    ? null
                    : rate.billingRateId;

            rate.billingRate.id =
                rate.billingRate.id.constructor === String
                    ? null
                    : rate.billingRate.id;
        }

        let clonedGroups = util.object.clone([
            ...organization.companyChargeTypeGroups,
        ]);
        clonedGroups.map((x) => {
            return {
                companyId: organization.id,
                chargeTypeGroupId: x.id,
                invoiceDescription: x.invoiceDescrption,
                billRate: x.billRate,
                chargeTypeGroup: x.chargeTypeGroup,
            };
        });
        organization.chargeTypeGruops = { ...clonedGroups };

        this.props.location.pathname === AppNavPaths.OrganizationNew
            ? this.createOrganization(organization)
            : this.updateOrganization(organization);
    };

    updateOrganization = async (organization) => {
        let response = await util.fetch
            .put(ApiRoutes.company.byId(organization.id), organization)
            .catch(this.handleSaveError);

        response.billingOTShiftStart = !!organization.billingOTShiftStart
            ? moment(response.billingOTShiftStart, 'YYYY-MM-DD hh:mm:ssA').format('HH:mm A')
            : '';
            
        response.billingOTShiftEnd = !!organization.billingOTShiftEnd
            ? moment(response.billingOTShiftEnd, 'YYYY-MM-DD hh:mm:ssA').format('HH:mm A')
            : '';

        response.billingOTJobStart = !!organization.billingOTJobStart
            ? moment(response.billingOTJobStart, 'YYYY-MM-DD hh:mm:ssA').format('HH:mm A')
            : '';
        response.billingOTJobEnd = !!organization.billingOTJobEnd
            ? moment(response.billingOTJobEnd, 'YYYY-MM-DD hh:mm:ssA').format('HH:mm A')
            : '';

        if (response && parseInt(response.id ?? 0) >= 0) {
            this.setState({
                organization: response,
                originalData: util.object.clone(response),
                saving: false,
                formValidated: false,
            });

            toast.success(
                <ToastMessage
                    icon={faCheckCircle}
                    header={'Save Successful'}
                    message={`[${response.name}] saved.`}
                />
            );
        }

        this.setSaving(false);
    };

    createOrganization = async (organization) => {
        let response = await util.fetch
            .post(ApiRoutes.company.create(), organization)
            .catch(this.handleSaveError);

        if (response && !isNaN(response)) {
            toast.success(
                <ToastMessage
                    icon={faCheckCircle}
                    header={'Save Successful'}
                    message={`Saved.`}
                />
            );
            util.navigation.localRedirect(
                this,
                `${AppNavPaths.Organization}/${response}`
            );
        }

        this.setSaving(false);
    };

    resetForm = () => this.setState({ formValidated: false });

    onClose = (response) => {
        this.resetForm();
        this.context.setFormOpened(false);
        this.props.onClose(response);
    };

    onDelete = async (e) => {
        const { organization } = this.state;
        let response = await util.fetch
            .delete(ApiRoutes.company.delete(organization.id))
            .catch(this.onCatchFetchError);
        if (response) this.onClose(response);
    };

    onAddContact = () => {
        this.context.setFormOpened(true);
        this.contactFormRef.current.resetForm();
        this.setState({
            selectedContact: /*createFakeContact()*/ new OrganizationContact(),
            showContactsForm: true,
            allowContactTypeEdit: true,
        });
    };

    onEditContact = (contact) => {
        this.contactFormRef.current.resetForm();

        //Only allow contact type edit if it's a new contact that's being edited before being saved.
        //Can't edit contact types for an already saved contact, in other words.
        const allowCTEdit = !contact.id;

        this.setState({
            selectedContact: contact,
            showContactsForm: true,
            allowContactTypeEdit: allowCTEdit,
        });
    };

    onOrganizationContactSaved = async () => {
        await this.setState({ contactFormIsReadOnly: true });

        let { selectedContact, organization } = this.state;
        organization.contacts = util.array.insertIfNotExists(
            organization.contacts,
            selectedContact
        );
        this.contactFormRef.current.resetForm();
        this.setState({
            organization,
            showContactsForm: false,
            contactFormIsReadOnly: false,
        });
    };

    renderContacts = () => {
        let contacts = this.state.organization.contacts;
        if (!contacts) return '';

        return contacts.map((contact, i) => {
            let primaryPhone = '';

            if (!!contact.officePhoneIsPrimary)
                primaryPhone = contact.officePhone ?? contact.mobilePhone;
            else primaryPhone = contact.mobilePhone ?? contact.officePhone;

            return (
                <tr key={i}>
                    <td>{`${contact.lastName}, ${contact.firstName}`}</td>
                    <td>{contact.title}</td>
                    <td>
                        <a href={`mailto: ${contact.email}`}>{contact.email}</a>
                    </td>
                    <td>{primaryPhone}</td>
                    <td>{contact.locationContactTypeName}</td>
                    <td>{contact.isActive ? 'Yes' : 'No'}</td>
                    <Can I="edit" a="company">
                        <CommonContext.Consumer>
                            {(value) => {
                                return (
                                    <td>
                                        <Button
                                            disabled={
                                                !!value.formIsOpen ||
                                                this.state.organization
                                                    .companyStatusId != 1
                                            }
                                            type="button"
                                            color="outline-primary"
                                            onClick={() =>
                                                this.onEditContact(contact)
                                            }
                                            size="sm"
                                        >
                                            Edit
                                        </Button>
                                    </td>
                                );
                            }}
                        </CommonContext.Consumer>
                    </Can>
                </tr>
            );
        });
    };

    renderJobRows = () => {
        let jobs = this.state.organization.jobs;
        if (!jobs) return '';
        return jobs.map((job, i) => {
            return (
                <tr key={i}>
                    <td>{job.id}</td>
                    <td>{job.name}</td>
                    <td>
                        <YesNoBadge value={job.active} />
                    </td>
                </tr>
            );
        });
    };

    onRateFormClosed = () => {
        this.rateFormRef.current.resetForm(); //clear validation
        this.context.setFormOpened(false); //lock all other page fields while form is open
        this.setState({
            showRateForm: false,
            showRateFormDelete: false,
        });
    };

    onAddRate = () => {
        this.rateFormRef.current.resetForm(); //clear validation
        this.setState((state) => {
            state.selectedRate = { ...new CompanyChargeTypeBillingRate() };
            state.showRateForm = true;
            return state;
        });
        this.context.setFormOpened(true); //lock all other page fields while form is open
    };

    onEditRate = (rate) => {
        let cloned = util.object.clone(rate);
        this.rateFormRef.current.resetForm();
        this.setState({
            selectedRate: cloned,
            showRateForm: true,
            showRateFormDelete: true,
        });
        this.context.setFormOpened(true);
    };

    onRateSaved = () => {
        let { selectedRate, organization, chargeTypes } = this.state;
        //need to update the charge type name
        selectedRate.chargeTypeName = (chargeTypes ?? []).find(
            (x) => x.value === selectedRate.chargeTypeId
        ).label;
        //need to expire any or look for overlap
        //There should be only 1 current per charge type
        let rateWithChargeType = organization.billingRates.find(
            (x) =>
                x.chargeTypeId == selectedRate.chargeTypeId &&
                (!x.billingRate.expirationDate ||
                    x.billingRate.expirationDate >=
                        selectedRate.billingRate.effectiveDate)
        );
        if (!!rateWithChargeType) {
            //set the expiration date
            let effective = new Date(
                util.object.clone(selectedRate.billingRate.effectiveDate)
            );

            //see if any have same effective date
            if (selectedRate.billingRateId.constructor === String) {
                if (
                    new Date(
                        rateWithChargeType.billingRate.effectiveDate
                    ).getDate() == effective.getDate() &&
                    rateWithChargeType.billingRateId !==
                        selectedRate.billingRateId
                ) {
                    //pop notification?
                    this.rateFormRef.current.resetForm();
                    this.setState({
                        showRateForm: false,
                    });
                    return;
                } else {
                    //set it back one day.
                    effective.setDate(effective.getDate() - 1);
                    rateWithChargeType.billingRate.expirationDate = effective;
                }
            }
        }

        organization.billingRates = util.array.upsert(
            selectedRate,
            organization.billingRates ?? [],
            'billingRateId'
        );
        this.rateFormRef.current.resetForm();
        this.setState({
            organization,
            showRateForm: false,
        });
    };

    onRateRemoved = () => {
        let { selectedRate, organization } = this.state;

        organization.billingRates = organization.billingRates.filter(
            (x) => x.billingRateId !== selectedRate.billingRateId
        );
        this.rateFormRef.current.resetForm();
        this.setState({
            organization,
            showRateForm: false,
        });
    };

    onShowRateHistory = async (rate) => {
        let { organization } = { ...this.state };

        let model = {
            companyId: organization.id,
            chargeTypeId: rate.chargeTypeId,
        };
        let history = await util.fetch.post(
            ApiRoutes.billingRate.companyBillingRateHistory(),
            model
        );

        this.rateHistoryFormRef.current.resetForm();
        this.setState({
            selectedRateHistory: history,
            showRateHistoryForm: true,
        });
        this.context.setFormOpened(true); //lock all other page fields while form is open
    };

    onRateHistoryFormClosed = () => {
        this.rateFormRef.current.resetForm(); //clear validation
        this.context.setFormOpened(false); //lock all other page fields while form is open
        this.setState({
            showRateHistoryForm: false,
        });
    };

    onMinWageRateFormClosed = () => {
        this.minWageRateFormRef.current.resetForm(); //clear validation
        this.context.setFormOpened(false); //lock all other page fields while form is open
        this.setState({
            showMinWageRateForm: false,
            showMinWageRateFormDelete: false,
        });
    };

    onAddMinWageRate = () => {
        let new_rate = new MinimumWageRate();

        this.setState({
            selectedMinWageRate: new_rate,
            showMinWageRateForm: true,
            minWageRateFormValidated: false,
            showMinWageRateFormDelete: false,
        });
        this.context.setFormOpened(true);
    };

    onEditMinWageRate = (rate) => {
        this.minWageRateFormRef.current.resetForm();
        this.context.setFormOpened(true);
        this.setState({
            selectedMinWageRate: util.object.clone(rate),
            showMinWageRateForm: true,
            minWageRateFormValidated: false,
            showMinWageRateFormDelete: true,
        });
        this.context.setFormOpened(true);
    };

    onMinWageRateSaved = () => {
        let { selectedMinWageRate, organization } = this.state;

        let existing = organization.companyMinimumWageRates.find(
            (x) => x.id === selectedMinWageRate.id
        );

        //need to expire any or look for overlap
        //There should be only 1 current per charge type
        let previousRate = organization.companyMinimumWageRates.find(
            (x) => !x.endDate || x.endDate >= selectedMinWageRate.startDate
        );
        if (!!previousRate) {
            //set the expiration date
            let effective = new Date(
                util.object.clone(selectedMinWageRate.startDate)
            );

            //see if any have same effective date
            if (selectedMinWageRate.id === 0) {
                if (
                    new Date(previousRate.startDate).getDate() ==
                        effective.getDate() &&
                    previousRate.id !== selectedMinWageRate.id
                ) {
                    //pop notification?
                    this.minWageRateFormRef.current.resetForm();
                    this.setState({
                        showMinRateRateForm: false,
                    });
                    return;
                } else {
                    //set it back one day.
                    effective.setDate(effective.getDate() - 1);
                    previousRate.endDate = effective;
                }
            }
        }

        if (!!existing)
            organization.companyMinimumWageRates[
                organization.companyMinimumWageRates.indexOf(existing)
            ] = selectedMinWageRate;
        else
            organization.companyMinimumWageRates.push(
                util.object.clone(selectedMinWageRate)
            );

        this.setState({
            organization,
            showMinWageRateForm: false,
            selectedMinWageRate: null,
            minWageRateFormValidated: false,
        });

        this.context.setFormOpened(false);
    };

    onMinWageRateRemoved = () => {
        let { selectedMinWageRate, organization } = this.state;

        organization.companyMinimumWageRates =
            organization.companyMinimumWageRates.filter(
                (x) => x.id !== selectedMinWageRate.id
            );
        this.rateFormRef.current.resetForm();
        this.setState({
            organization,
            showMinWageRateForm: false,
        });
    };

    onOrgTypeSelectChanged = (item) => {
        let { organization } = { ...this.state };
        organization.types = (item ?? []).map((x) => x.value) ?? [];
        this.setState({ organization: organization });
        this.onOrgTypeChanged();
    };

    onOrgTypeChanged = async () => {
        let { organization } = { ...this.state };

        let isBilling = (organization.types ?? []).includes(
                OrganizationTypes.Billing
            ),
            isPayroll = (organization.types ?? []).includes(
                OrganizationTypes.Payroll
            ),
            isDispatch = (organization.types ?? []).includes(
                OrganizationTypes.Dispatch
            ),
            isCustomer = (organization.types ?? []).includes(
                OrganizationTypes.Customer
            ),
            isOperationsCenter = (organization.types ?? []).includes(
                OrganizationTypes.OperationsCenter
            ),
            isSubcontractor = (organization.types ?? []).includes(
                OrganizationTypes.CustomerSubcontractor
            ),
            isParentCustomer = (organization.types ?? []).includes(
                OrganizationTypes.ParentCustomer
            ),
            isProspect = (organization.types ?? []).includes(
                OrganizationTypes.Prospect
            ),
            customers = [],
            parentRequired = false,
            parentCustomers = [],
            parentCompanies = [],
            parentCompanyLabelText = '';

        if (isBilling) {
            //do nothing.  this is top tier hierarchy
        } else if (isPayroll && !isBilling) {
            //if not a billing type, the billing company must be provided.
            parentCompanies = await util.fetch.js(
                ApiRoutes.typeAheads.billingCompanies()
            );
            parentCompanyLabelText = 'Billing Company';
            parentRequired = true;
        } else if (isDispatch && !isPayroll && !isBilling) {
            //If not a payroll type and a dispatch location, need the payroll company.
            parentCompanies = await util.fetch.js(
                ApiRoutes.typeAheads.payrollCompanies()
            );
            parentCompanyLabelText = 'Payroll Company';
            parentRequired = true;
        }

        let useInvoicingRequired = false;
        if (isOperationsCenter) {
            customers = await util.fetch.js(ApiRoutes.typeAheads.customers());
            //Remove any self-reference as a protection.
            customers =
                (customers ?? []).filter((x) => x.value !== organization.id) ??
                [];

            if (organization.customerCompanyId !== null) {
                let opCenterParent = await util.fetch.js(
                    ApiRoutes.company.byId(organization.customerCompanyId)
                );
                useInvoicingRequired =
                    opCenterParent &&
                    opCenterParent.operationsCenterUseInvoicing;
            }
        }

        if (!isParentCustomer) {
            parentCustomers = await util.fetch.js(
                ApiRoutes.typeAheads.parentCustomers()
            );
        }

        //Remove self-reference as a protection.
        parentCompanies =
            (parentCompanies ?? []).filter(
                (x) => x.value !== organization.id
            ) ?? [];

        if (!!isBilling) organization.parentCompanyId = null;

        //If this org is not a contractor, clear out customers array.
        if (!isSubcontractor) organization.subcontractorCustomers = [];

        this.setState({
            customers: [...customers],
            isOperationsCenter,
            isCustomer,
            isSubcontractor,
            isParentCustomer,
            isProspect,
            parentCompanyLabelText,
            parentCompanies: [...parentCompanies],
            parentCustomers: [...parentCustomers],
            organization: { ...organization },
            parentRequired,
            useInvoicingRequired,
        });
    };

    //#endregion

    onChargeTypeGroupSelectChanged = (selections) => {
        this.setState({
            selectedCTGroups: selections,
        });
    };

    toggleCTGroupModal = async () => {
        let { showCTGroupModal } = this.state;

        this.setState({
            showCTGroupModal: !showCTGroupModal,
        });
    };

    onEditCTGroup = (group) => {
        let { organization } = { ...this.state };
        let { companyChargeTypeGroups } = { ...organization };

        let index = companyChargeTypeGroups.findIndex(
            (x) => x.chargeTypeGroupId === group.chargeTypeGroupId
        );
        companyChargeTypeGroups[index].editing = true;
        this.setState({ organization: { ...organization } });
    };

    onCTGroupChanged = (i, ev) => {
        let { organization } = { ...this.state },
            val = ev.target.value,
            name = ev.target.name;

        organization.companyChargeTypeGroups[i][name] = val;

        this.setState({ organization: { ...organization } });
    };

    onRemoveCTGroup = (group) => {
        let { organization } = { ...this.state };
        let { companyChargeTypeGroups } = { ...organization };

        let index = companyChargeTypeGroups.findIndex(
            (x) => x.chargeTypeGroupId === group.chargeTypeGroupId
        );
        companyChargeTypeGroups.splice(index, 1);
        this.setState({ organization: { ...organization } });
    };

    onAddCTGroup = () => {
        let { organization, selectedCTGroups } = { ...this.state };

        let updated = [...organization.companyChargeTypeGroups] ?? [];

        //get selections from the popup
        let selectedChargeTypes = selectedCTGroups.map((x, index) => {
            return {
                companyId: organization.id,
                chargeTypeGroupId: x.id,
                invoiceDescription: x.invoiceDescription,
                billRate: x.billRate,
                chargeTypeGroup: x,
            };
        });

        //merge additions with current charges
        updated = updated.concat([...selectedChargeTypes]);

        organization.companyChargeTypeGroups = [...updated];

        this.setState({
            organization: { ...organization },
            showCTGroupModal: false,
            selectedCTGroups: [],
        });
    };

    subcontractorCustomersChanged = (selection) => {
        const value = !!selection ? [...selection] : [];
        const customers = value.map((x) => x.value) ?? [];
        this.setState(
            (state) => (
                (state.organization.subcontractorCustomers = customers), state
            )
        );
    };

    onPhoneChanged = (event) => {
        const value = event.target.value;
        const { organization } = { ...this.state };
        organization.phone = value;
        this.setState({ organization: { ...organization } });
    };

    onOTDaysChanged = (event, otDay) => {
        const { organization } = { ...this.state };
        organization.billingOTDays = organization.billingOTDays ^ otDay;
        this.setState({ organization: { ...organization } });
    };

    onTimeEntryStartChanged = (ev) => {
        const { organization } = this.state;

        let val = ev.target.value;
        let timeValue = !!ev.target.value
            ? moment(val, ['h:mm A']).format('HH:mm')
            : '';

        organization.billingOTShiftStart = timeValue;
        this.setState({ organization: organization });
    };

    onTimeEntryEndChanged = (ev) => {
        const { organization } = this.state;

        let val = ev.target.value;
        let timeValue = !!ev.target.value
            ? moment(val, ['h:mm A']).format('HH:mm')
            : '';

        organization.billingOTShiftEnd = timeValue;
        this.setState({ organization: organization });
    };

    onJobStartChanged = (ev) => {
        const { organization } = this.state;

        let val = ev.target.value;
        let timeValue = !!ev.target.value
            ? moment(val, ['h:mm A']).format('HH:mm')
            : '';

        organization.billingOTJobStart = timeValue;
        this.setState({ organization: organization });
    };

    onJobEndChanged = (ev) => {
        const { organization } = this.state;

        let val = ev.target.value;
        let timeValue = !!ev.target.value
            ? moment(val, ['h:mm A']).format('HH:mm')
            : '';

        organization.billingOTJobEnd = timeValue;
        this.setState({ organization: organization });
    };

    render() {
        const {
            customers,
            subcontractorCustomersOptions,
            isCustomer,
            isOperationsCenter,
            isSubcontractor,
            isParentCustomer,
            isProspect,
            parentCustomers,
            parentCompanies,
            parentRequired,
            parentCompanyLabelText,
            organization,
            originalData,
            errors,
            formValidated,
            companyTypes,
            saving,
            showContactsForm,
            selectedContact,
            chargeTypes,
            showRateFormDelete,
            contactFormIsReadOnly,
            invoiceTerms,
            invoiceMappings,
            chargeTypeGroups,
            showCTGroupModal,
            selectedCTGroups,
            allowContactTypeEdit,
            showMinWageRateFormDelete,
            usStates,
            useInvoicingRequired,
            companyStatuses,
        } = this.state;

        if (!organization) return '';

        let invoiceCollationOptions = [
            {
                value: 0,
                label: 'No Collation (Individual invoice and timesheet files)',
            },
            {
                value: 1,
                label: 'Collated (Individual invoice files containing timesheets)',
            },
            {
                value: 2,
                label: 'Collated & Merged (invoices and timesheets merged into one file)',
            },
        ];

        let existing = !!organization.id;
        const selectedSubcontractorCustomers =
            [
                ...(subcontractorCustomersOptions ?? []).filter((x) =>
                    (organization?.subcontractorCustomers ?? []).includes(
                        x.value
                    )
                ),
            ] ?? '';
        const dispatchSelected = (organization.types ?? []).includes(
            OrganizationTypes.Dispatch
        );

        const payrollSelected = (organization.types ?? []).includes(
            OrganizationTypes.Payroll
        );

        const isBilling = (organization.types ?? []).includes(
            OrganizationTypes.Billing
        );

        const tenantSettings =
            ((this.context ?? {}).tenant ?? {}).tenantSettings ?? {};

        const isInvoicingAllowed = !!tenantSettings.invoicingEnabled;
        const isChargeTypeGroupsAllowed =
            !!tenantSettings.chargeTypeGroupsEnabled;
        const isBillingRatesAllowed = !!tenantSettings.billingRatesEnabled;
        const contractExpirationEmailEnabled = Boolean(
            tenantSettings.contractExpiryDistributionList
        );

        return (
            <Fragment>
                <Prompt
                    when={!saving && !isEqual(originalData, organization)}
                    message="You have unsaved changes, are you sure you want to leave?"
                />
                <AppPageForm
                    formShown={this.context.formIsOpen}
                    formId={'organizationForm'}
                    formHeadingIcon={faWarehouse}
                    formHeading={
                        !organization.id
                            ? 'New Organization'
                            : 'Edit Organization'
                    }
                    formName={'organizationForm'}
                    formRef={this.formRef}
                    onSubmit={this.onSubmit}
                    setIsValidated={(value) => {
                        this.setState({ formValidated: value });
                    }}
                    isValidated={formValidated}
                    saving={saving}
                    errors={errors}
                    onClearErrors={this.onClearErrors}
                    loading={this.state.loading}
                >
                    <Row>
                        <Col
                            xl={{ size: 6, offset: 3 }}
                            lg={{ size: 8, offset: 2 }}
                            md={{ size: 10, offset: 1 }}
                            sm={{ size: 12, offset: 0 }}
                        >
                            <SubHeading>Details</SubHeading>

                            <FormGroup>
                                <FormLabel
                                    htmlFor="type"
                                    text="Type(s)"
                                    required={true}
                                />
                                <ValidatedSelect
                                    id="types"
                                    name="organization.types"
                                    isMulti
                                    required
                                    options={companyTypes}
                                    value={
                                        (companyTypes ?? []).filter((x) =>
                                            (organization.types ?? []).includes(
                                                x.value
                                            )
                                        ) ?? ''
                                    }
                                    onChange={this.onOrgTypeSelectChanged}
                                    validationMessage="At least one organization type is required."
                                />
                            </FormGroup>

                            <FormGroup>
                                <FormLabel
                                    htmlFor="companyStatus"
                                    text="Status"
                                />
                                <ValidatedSelect
                                    id="companyStatusId"
                                    name="organization.companyStatusId"
                                    options={companyStatuses}
                                    value={
                                        companyStatuses?.find(
                                            (s) =>
                                                s.value ===
                                                organization.companyStatusId
                                        ) ?? ''
                                    }
                                    onChange={this.onSelectChanged}
                                />
                            </FormGroup>

                            <FormDivider />

                            <FormGroup>
                                <FormLabel
                                    htmlFor="organizationName"
                                    text="Name"
                                    required={true}
                                />
                                <Input
                                    id="organizationName"
                                    name="organization.name"
                                    value={organization.name ?? ''}
                                    onChange={this.onChange}
                                    placeholder="Enter Name (max 150 characters)"
                                    maxLength="150"
                                    pattern="[^()/><\][\\\x22;|]+"
                                    type="text"
                                    required
                                />
                                <small className="invalid-feedback text-danger">
                                    Name is required and can only contain the
                                    following special characters: hyphens and
                                    periods.
                                </small>
                            </FormGroup>
                            <AddressInput
                                className="org-address-input"
                                namePrefix={'organization'}
                                onChange={this.onChange}
                                address={organization}
                                onStateChanged={this.onSelectChanged}
                                single={true}
                            />
                            {(!!dispatchSelected || isBilling) && (
                                <FormGroup>
                                    <FormLabel
                                        htmlFor="phone"
                                        text="Phone"
                                        required={true}
                                    />
                                    <input
                                        id="phone"
                                        placeholder="ex: 555-555-5555"
                                        name="organization.phone"
                                        pattern={
                                            util.validation.patterns.htmlPhone
                                        }
                                        autoComplete="off"
                                        type="tel"
                                        className="form-control"
                                        onChange={this.onPhoneChanged}
                                        defaultValue={organization.phone}
                                        required={true}
                                    />
                                    <small className="invalid-feedback text-danger">
                                        {`A valid phone number is required. (555-555-5555)`}
                                    </small>
                                </FormGroup>
                            )}

                            {!!(tenantSettings ?? {}).enableAlternateBillTo && (
                                <>
                                    <FormCheckbox
                                        small={true}
                                        id={'useAsBillingAddress'}
                                        checked={
                                            organization.useAsBillingAddress
                                        }
                                        onChange={(e) => {
                                            let { organization } = this.state;
                                            organization.useAsBillingAddress =
                                                e.target.checked;
                                            this.setState({
                                                organization,
                                            });
                                        }}
                                        labelText={
                                            'Also Use For Billing Address'
                                        }
                                    />

                                    {!organization.useAsBillingAddress && (
                                        <>
                                            <FormGroup>
                                                <SubHeading className="mt-4">
                                                    {'Billing Address'}
                                                </SubHeading>
                                            </FormGroup>

                                            <FormGroup>
                                                <FormLabel
                                                    htmlFor="billingName"
                                                    text="Name"
                                                />
                                                <input
                                                    id="billingName"
                                                    name="organization.billingName"
                                                    autoComplete="off"
                                                    type="text"
                                                    className="form-control"
                                                    defaultValue={
                                                        organization.billingName
                                                    }
                                                    onChange={this.onChange}
                                                />
                                            </FormGroup>

                                            <FormGroup>
                                                <FormLabel
                                                    htmlFor="billingAddress1"
                                                    text="Address Line 1"
                                                />
                                                <input
                                                    id="billingAddress1"
                                                    name="organization.billingAddress1"
                                                    autoComplete="off"
                                                    type="text"
                                                    className="form-control"
                                                    defaultValue={
                                                        organization.billingAddress1
                                                    }
                                                    onChange={this.onChange}
                                                />
                                            </FormGroup>

                                            <FormGroup>
                                                <FormLabel
                                                    htmlFor="billingAddress2"
                                                    text="Address Line 2"
                                                />
                                                <input
                                                    id="billingAddress2"
                                                    name="organization.billingAddress2"
                                                    autoComplete="off"
                                                    type="text"
                                                    className="form-control"
                                                    defaultValue={
                                                        organization.billingAddress2
                                                    }
                                                    onChange={this.onChange}
                                                />
                                            </FormGroup>

                                            <FormGroup>
                                                <FormLabel
                                                    htmlFor="billingCity"
                                                    text="City"
                                                />
                                                <input
                                                    id="billingCity"
                                                    name="organization.billingCity"
                                                    autoComplete="off"
                                                    type="text"
                                                    className="form-control"
                                                    defaultValue={
                                                        organization.billingCity
                                                    }
                                                    onChange={this.onChange}
                                                />
                                            </FormGroup>

                                            <FormGroup>
                                                <FormLabel
                                                    htmlFor="billingState"
                                                    text="State"
                                                />
                                                <ValidatedSelect
                                                    id="billingState"
                                                    name="organization.billingState"
                                                    options={usStates}
                                                    value={
                                                        usStates?.find(
                                                            (s) =>
                                                                s.value ===
                                                                organization.billingState
                                                        ) ?? ''
                                                    }
                                                    onChange={
                                                        this.onSelectChanged
                                                    }
                                                />
                                            </FormGroup>

                                            <FormGroup>
                                                <FormLabel
                                                    htmlFor="billingZip"
                                                    text="Zip Code"
                                                />
                                                <input
                                                    id="billingZip"
                                                    name="organization.billingZip"
                                                    autoComplete="off"
                                                    type="text"
                                                    className="form-control"
                                                    defaultValue={
                                                        organization.billingZip
                                                    }
                                                    onChange={this.onChange}
                                                />
                                            </FormGroup>
                                        </>
                                    )}
                                </>
                            )}

                            <FormGroup
                                hidden={
                                    !isCustomer &&
                                    !dispatchSelected &&
                                    !payrollSelected
                                }
                            >
                                <SubHeading className="mt-4">
                                    {'Configurations'}
                                </SubHeading>
                            </FormGroup>

                            <Can I="edit" a="company_code">
                                <FormGroup hidden={!dispatchSelected}>
                                    <FormLabel
                                        htmlFor="qbClassMapping"
                                        text="QuickBooks Mapping"
                                    />
                                    <input
                                        id="qbClassMapping"
                                        name="organization.qbClassMapping"
                                        type="text"
                                        className="form-control"
                                        onChange={this.onChange}
                                        defaultValue={
                                            organization.qbClassMapping
                                        }
                                    />
                                </FormGroup>

                                <FormGroup hidden={!dispatchSelected && !payrollSelected}>
                                    <FormLabel
                                        htmlFor="ctrcode"
                                        text="CTR Code"
                                    />
                                    <input
                                        id="ctrcode"
                                        name="organization.ctrCode"
                                        type="text"
                                        className="form-control"
                                        onChange={this.onChange}
                                        defaultValue={
                                            organization.ctrCode
                                        }
                                    />
                                </FormGroup>
                            </Can>

                            <FormGroup
                                hidden={
                                    !isOperationsCenter ||
                                    !!isCustomer ||
                                    !!isParentCustomer ||
                                    !!isProspect
                                }
                            >
                                <FormLabel
                                    htmlFor="customerCompanyId"
                                    text="Customer"
                                    required={
                                        !!isOperationsCenter && !isCustomer
                                    }
                                />
                                <ValidatedSelect
                                    id="customerCompanyId"
                                    name="organization.customerCompanyId"
                                    required={
                                        !!isOperationsCenter && !isCustomer
                                    }
                                    options={customers}
                                    value={
                                        (customers ?? []).find(
                                            (x) =>
                                                organization.customerCompanyId ===
                                                x.value
                                        ) ?? ''
                                    }
                                    onChange={this.onSelectChanged}
                                    validationMessage="A customer selection is required when the organization types include [Operations Center]."
                                />
                            </FormGroup>

                            {(isCustomer ||
                                isParentCustomer ||
                                isOperationsCenter) && (
                                <FormGroup>
                                    {(companyTypes ?? []).filter(
                                        (x) =>
                                            x.value ==
                                            OrganizationTypes.Customer
                                    ).length > 0 &&
                                        !isProspect &&
                                        !isParentCustomer &&
                                        !isOperationsCenter && (
                                            <FormCheckbox
                                                small={true}
                                                className="ml-3 mt-2"
                                                id={'operationsCentersApply'}
                                                checked={
                                                    organization.operationsCentersApply
                                                }
                                                onChange={(e) => {
                                                    let { organization } =
                                                        this.state;
                                                    organization.operationsCentersApply =
                                                        e.target.checked;
                                                    this.setState({
                                                        organization:
                                                            organization,
                                                    });
                                                }}
                                                labelText={
                                                    'Assign Contracts to Operations Centers'
                                                }
                                            />
                                        )}
                                    {(companyTypes ?? []).filter(
                                        (x) =>
                                            x.value ==
                                            OrganizationTypes.CustomerSubcontractor
                                    ).length > 0 &&
                                        !isProspect &&
                                        !isParentCustomer &&
                                        !isOperationsCenter && (
                                            <FormCheckbox
                                                small={true}
                                                className="ml-3 mt-2"
                                                id={'subcontractorsApply'}
                                                checked={
                                                    organization.subcontractorsApply
                                                }
                                                onChange={(e) => {
                                                    let { organization } =
                                                        this.state;
                                                    organization.subcontractorsApply =
                                                        e.target.checked;
                                                    this.setState({
                                                        organization:
                                                            organization,
                                                    });
                                                }}
                                                labelText={
                                                    'Assign Jobs to Customer Subcontractors'
                                                }
                                            />
                                        )}
                                    {(parentCustomers ?? []).length > 0 &&
                                        !isOperationsCenter && (
                                            <FormCheckbox
                                                small={true}
                                                className="ml-3 mt-2"
                                                id={'parentCustomerApply'}
                                                checked={
                                                    organization.parentCustomerApply
                                                }
                                                onChange={(e) => {
                                                    let { organization } =
                                                        this.state;
                                                    organization.parentCustomerApply =
                                                        e.target.checked;

                                                    if (
                                                        !organization.parentCustomerApply
                                                    ) {
                                                        organization.parentCustomerId =
                                                            null;
                                                    }

                                                    this.setState({
                                                        organization:
                                                            organization,
                                                    });
                                                }}
                                                labelText={
                                                    'Assign Parent Customer'
                                                }
                                            />
                                        )}

                                    {isCustomer &&
                                        contractExpirationEmailEnabled && (
                                            <FormCheckbox
                                                small={true}
                                                className="ml-3 mt-2"
                                                id={
                                                    'exemptFromExpiringContractEmailNotifications'
                                                }
                                                checked={
                                                    organization.exemptFromExpiringContractEmailNotifications
                                                }
                                                onChange={(e) => {
                                                    let { organization } =
                                                        this.state;
                                                    organization.exemptFromExpiringContractEmailNotifications =
                                                        e.target.checked;
                                                    this.setState({
                                                        organization,
                                                    });
                                                }}
                                                labelText={
                                                    'Exempt From Expiring Contract Email Notifications'
                                                }
                                            />
                                        )}

                                    {isCustomer && (
                                        <FormCheckbox
                                            small={true}
                                            className="ml-3 mt-2"
                                            id={
                                                'chkExpandedCancellationReasonRequired'
                                            }
                                            checked={
                                                organization.expandedCancellationReasonRequired
                                            }
                                            onChange={(e) => {
                                                let { organization } =
                                                    this.state;
                                                organization.expandedCancellationReasonRequired =
                                                    e.target.checked;
                                                this.setState({
                                                    organization,
                                                });
                                            }}
                                            labelText={
                                                'Expanded Cancellation Reason Required'
                                            }
                                        />
                                    )}

                                    {!!(tenantSettings ?? {}).billingEnabled &&
                                        isInvoicingAllowed &&
                                        (!isOperationsCenter ||
                                            (isOperationsCenter &&
                                                useInvoicingRequired)) && (
                                            <FormCheckbox
                                                small={true}
                                                className="ml-3 mt-2"
                                                id={'useInvoicing'}
                                                checked={
                                                    organization.useInvoicing
                                                }
                                                onChange={(e) => {
                                                    let { organization } =
                                                        this.state;
                                                    organization.useInvoicing =
                                                        e.target.checked;
                                                    this.setState({
                                                        organization:
                                                            organization,
                                                    });
                                                }}
                                                labelText={'Use Invoicing'}
                                            />
                                        )}

                                    {!!(tenantSettings ?? {}).billingEnabled &&
                                        isInvoicingAllowed &&
                                        this.state.organization.useInvoicing &&
                                        !isOperationsCenter && (
                                            <>
                                                <FormCheckbox
                                                    small={true}
                                                    className="ml-5 mt-2"
                                                    id={
                                                        'operationsCenterUseInvoicing'
                                                    }
                                                    checked={
                                                        organization.operationsCenterUseInvoicing
                                                    }
                                                    onChange={(e) => {
                                                        let { organization } =
                                                            this.state;
                                                        organization.operationsCenterUseInvoicing =
                                                            e.target.checked;
                                                        this.setState({
                                                            organization:
                                                                organization,
                                                        });
                                                    }}
                                                    labelText={
                                                        'Require "Use Invoicing" Option on Operation Centers'
                                                    }
                                                />

                                                <FormCheckbox
                                                    small={true}
                                                    className="ml-5 mt-2"
                                                    id={'invoiceHasPO'}
                                                    checked={
                                                        organization.invoiceHasPO
                                                    }
                                                    onChange={(e) => {
                                                        let { organization } =
                                                            this.state;
                                                        organization.invoiceHasPO =
                                                            e.target.checked;
                                                        this.setState({
                                                            organization:
                                                                organization,
                                                        });
                                                    }}
                                                    labelText={
                                                        'Include PO On Invoice'
                                                    }
                                                />

                                                <FormCheckbox
                                                    small={true}
                                                    className="ml-5 mt-2"
                                                    id={
                                                        'invoiceTimesheetUsesWO'
                                                    }
                                                    checked={
                                                        organization.invoiceTimesheetUsesWO
                                                    }
                                                    onChange={(e) => {
                                                        let { organization } =
                                                            this.state;
                                                        organization.invoiceTimesheetUsesWO =
                                                            e.target.checked;
                                                        this.setState({
                                                            organization:
                                                                organization,
                                                        });
                                                    }}
                                                    labelText={
                                                        'Use TC# as timesheet file name'
                                                    }
                                                />

                                                <FormCheckbox
                                                    small={true}
                                                    className="ml-5 mt-2"
                                                    id={
                                                        'allowMultipleWONumbers'
                                                    }
                                                    checked={
                                                        organization.allowMultipleWONumbers
                                                    }
                                                    onChange={(e) => {
                                                        let { organization } =
                                                            this.state;
                                                        organization.allowMultipleWONumbers =
                                                            e.target.checked;
                                                        this.setState({
                                                            organization:
                                                                organization,
                                                        });
                                                    }}
                                                    labelText={
                                                        'Allow Multiple Workorders'
                                                    }
                                                />

                                                <FormCheckbox
                                                    small={true}
                                                    className="ml-5 mt-2"
                                                    id={'useProodOf40'}
                                                    checked={
                                                        organization.useProofOf40
                                                    }
                                                    onChange={(e) => {
                                                        let { organization } =
                                                            this.state;
                                                        organization.useProofOf40 =
                                                            e.target.checked;
                                                        this.setState({
                                                            organization:
                                                                organization,
                                                        });
                                                    }}
                                                    labelText={
                                                        'Use Proof Of 40'
                                                    }
                                                />

                                                <FormCheckbox
                                                    small={true}
                                                    className="ml-5 mt-2"
                                                    id={'billingUsesOT'}
                                                    checked={
                                                        organization.billingUsesOT
                                                    }
                                                    onChange={(e) => {
                                                        let { organization } =
                                                            this.state;
                                                        organization.billingUsesOT =
                                                            e.target.checked;
                                                        this.setState({
                                                            organization:
                                                                organization,
                                                        });
                                                    }}
                                                    labelText={
                                                        '40 Hours Overtime'
                                                    }
                                                />

                                                <FormCheckbox
                                                    small={true}
                                                    className="ml-5 mt-2"
                                                    id={'billingUseDailyOT'}
                                                    checked={
                                                        organization.billingUseDailyOT
                                                    }
                                                    onChange={(e) => {
                                                        let { organization } =
                                                            this.state;
                                                        organization.billingUseDailyOT =
                                                            e.target.checked;
                                                        this.setState({
                                                            organization:
                                                                organization,
                                                        });
                                                    }}
                                                    labelText={'Daily Overtime'}
                                                />
                                            </>
                                        )}
                                </FormGroup>
                            )}

                            {(organization.types ?? []).filter(
                                (x) => x == OrganizationTypes.Customer
                            ).length > 0 &&
                                isInvoicingAllowed &&
                                !isOperationsCenter && (
                                    <>
                                        {this.state.organization.useInvoicing &&
                                            organization.billingUseDailyOT && (
                                                <FormGroup className="ml-5 mt-2">
                                                    <FormLabel
                                                        htmlFor="billingDailyOTThreshold"
                                                        text="Daily Overtime Threshold"
                                                        required={true}
                                                    />
                                                    <NumericInput
                                                        id="billingDailyOTThreshold"
                                                        name="organization.billingDailyOTThreshold"
                                                        value={
                                                            organization.billingDailyOTThreshold ??
                                                            ''
                                                        }
                                                        onChange={this.onChange}
                                                        placeholder="Hours"
                                                        type="number"
                                                        required
                                                    />
                                                </FormGroup>
                                            )}

                                        {this.state.organization
                                            .useInvoicing && (
                                            <>
                                                <FormGroup className="ml-5 mt-2">
                                                    <FormLabel
                                                        htmlFor="invoiceMappingId"
                                                        text="Invoice Charge Mapping"
                                                        required={
                                                            !!(
                                                                organization ??
                                                                {}
                                                            ).useInvoicing
                                                        }
                                                    />
                                                    <ValidatedSelect
                                                        id="invoiceMappingId"
                                                        name="organization.invoiceMappingId"
                                                        options={
                                                            invoiceMappings
                                                        }
                                                        value={
                                                            (
                                                                invoiceMappings ??
                                                                []
                                                            ).find(
                                                                (x) =>
                                                                    organization.invoiceMappingId ===
                                                                    x.value
                                                            ) ?? ''
                                                        }
                                                        onChange={
                                                            this.onSelectChanged
                                                        }
                                                        required={false}
                                                    />
                                                </FormGroup>

                                                <FormGroup className="ml-5 mt-2">
                                                    <FormLabel
                                                        htmlFor="invoiceCollationId"
                                                        text="Invoice Collation"
                                                        required={
                                                            !!(
                                                                organization ??
                                                                {}
                                                            ).useInvoicing
                                                        }
                                                    />
                                                    <ValidatedSelect
                                                        id="invoiceCollationId"
                                                        name="organization.invoiceCollationId"
                                                        options={
                                                            invoiceCollationOptions
                                                        }
                                                        value={
                                                            (
                                                                invoiceCollationOptions ??
                                                                []
                                                            ).find(
                                                                (x) =>
                                                                    organization.invoiceCollationId ===
                                                                    x.value
                                                            ) ?? ''
                                                        }
                                                        onChange={
                                                            this.onSelectChanged
                                                        }
                                                        required={false}
                                                    />
                                                </FormGroup>

                                                <FormGroup className="ml-5 mt-2">
                                                    <FormLabel
                                                        htmlFor="invoiceTermsId"
                                                        text="Payment Terms"
                                                        required={
                                                            !!(
                                                                tenantSettings ??
                                                                {}
                                                            ).billingEnabled ||
                                                            !!(
                                                                organization ??
                                                                {}
                                                            ).useInvoicing
                                                        }
                                                    />
                                                    <ValidatedSelect
                                                        id="invoiceTermsId"
                                                        name="organization.invoiceTermsId"
                                                        options={invoiceTerms}
                                                        value={
                                                            (
                                                                invoiceTerms ??
                                                                []
                                                            ).find(
                                                                (x) =>
                                                                    organization.invoiceTermsId ===
                                                                    x.value
                                                            ) ?? ''
                                                        }
                                                        onChange={
                                                            this.onSelectChanged
                                                        }
                                                        required={
                                                            !!(
                                                                tenantSettings ??
                                                                {}
                                                            ).billingEnabled ||
                                                            !!(
                                                                organization ??
                                                                {}
                                                            ).useInvoicing
                                                        }
                                                    />
                                                    <small className="invalid-feedback text-danger">
                                                        Payment Terms are
                                                        required for billing
                                                        enabled organizations.
                                                    </small>
                                                </FormGroup>

                                                <FormGroup
                                                    hidden={
                                                        !isCustomer &&
                                                        !dispatchSelected &&
                                                        !payrollSelected
                                                    }
                                                >
                                                    <SubHeading className="mt-4">
                                                        {
                                                            'Overtime Configuration'
                                                        }
                                                    </SubHeading>
                                                </FormGroup>

                                                <FormGroup
                                                    className="ml-5 mt-2"
                                                    style={{
                                                        marginBottom: '0px',
                                                    }}
                                                >
                                                    <FormLabel
                                                        htmlFor="invoiceTermsId"
                                                        text="Billing Overtime Range"
                                                    />

                                                    <Row noGutters={true}>
                                                        <Col>
                                                            <FormGroup
                                                                className="ml-1 mt-1"
                                                                style={{
                                                                    width: '80px',
                                                                }}
                                                            >
                                                                <TimeEntry
                                                                    name="organization.billingOTShiftStart"
                                                                    increment={
                                                                        0.25
                                                                    }
                                                                    value={
                                                                        organization.billingOTShiftStart
                                                                    }
                                                                    onChange={(
                                                                        ev
                                                                    ) =>
                                                                        this.onTimeEntryStartChanged(
                                                                            ev
                                                                        )
                                                                    }
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col>
                                                            <FormGroup className="ml-1 mt-1">
                                                                <div
                                                                    style={{
                                                                        marginLeft:
                                                                            '12px',
                                                                        marginTop:
                                                                            '5px',
                                                                        fontSize:
                                                                            '12px',
                                                                        color: '#606060',
                                                                    }}
                                                                >
                                                                    <b>to</b>
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col>
                                                            <FormGroup
                                                                className="ml-1 mt-1"
                                                                style={{
                                                                    width: '80px',
                                                                }}
                                                            >
                                                                <TimeEntry
                                                                    name="organization.billingOTShiftEnd"
                                                                    increment={
                                                                        0.25
                                                                    }
                                                                    value={
                                                                        organization.billingOTShiftEnd
                                                                    }
                                                                    onChange={(
                                                                        ev
                                                                    ) =>
                                                                        this.onTimeEntryEndChanged(
                                                                            ev
                                                                        )
                                                                    }
                                                                />
                                                            </FormGroup>
                                                        </Col>

                                                        <Col>&nbsp;</Col>
                                                        <Col>&nbsp;</Col>
                                                        <Col>&nbsp;</Col>
                                                        <Col>&nbsp;</Col>
                                                        <Col>&nbsp;</Col>
                                                        <Col>&nbsp;</Col>
                                                        <Col>&nbsp;</Col>
                                                    </Row>
                                                </FormGroup>
                                                <FormGroup
                                                    className="ml-5 mt-2"
                                                    style={{
                                                        marginBottom: '0px',
                                                    }}
                                                >
                                                    <FormLabel
                                                        htmlFor="invoiceTermsId"
                                                        text="Billing Overtime Job Start Range"
                                                    />

                                                    <Row noGutters={true}>
                                                        <Col>
                                                            <FormGroup
                                                                className="ml-1 mt-1"
                                                                style={{
                                                                    width: '80px',
                                                                }}
                                                            >
                                                                <TimeEntry
                                                                    name="organization.billingOTJobStart"
                                                                    increment={
                                                                        0.25
                                                                    }
                                                                    value={
                                                                        organization.billingOTJobStart
                                                                    }
                                                                    onChange={(
                                                                        ev
                                                                    ) =>
                                                                        this.onJobStartChanged(
                                                                            ev
                                                                        )
                                                                    }
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col>
                                                            <FormGroup className="ml-1 mt-1">
                                                                <div
                                                                    style={{
                                                                        marginLeft:
                                                                            '12px',
                                                                        marginTop:
                                                                            '5px',
                                                                        fontSize:
                                                                            '12px',
                                                                        color: '#606060',
                                                                    }}
                                                                >
                                                                    <b>to</b>
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col>
                                                            <FormGroup
                                                                className="ml-1 mt-1"
                                                                style={{
                                                                    width: '80px',
                                                                }}
                                                            >
                                                                <TimeEntry
                                                                    name="organization.billingOTJobEnd"
                                                                    increment={
                                                                        0.25
                                                                    }
                                                                    value={
                                                                        organization.billingOTJobEnd
                                                                    }
                                                                    onChange={(
                                                                        ev
                                                                    ) =>
                                                                        this.onJobEndChanged(
                                                                            ev
                                                                        )
                                                                    }
                                                                />
                                                            </FormGroup>
                                                        </Col>

                                                        <Col>&nbsp;</Col>
                                                        <Col>&nbsp;</Col>
                                                        <Col>&nbsp;</Col>
                                                        <Col>&nbsp;</Col>
                                                        <Col>&nbsp;</Col>
                                                        <Col>&nbsp;</Col>
                                                        <Col>&nbsp;</Col>
                                                    </Row>
                                                </FormGroup>

                                                <FormGroup className="ml-5">
                                                    <FormLabel
                                                        htmlFor="billingOTDays"
                                                        text="Billing Overtime Days"
                                                    />

                                                    <table class="billing-ot-days">
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    paddingRight:
                                                                        '15px',
                                                                }}
                                                            >
                                                                <FormCheckbox
                                                                    small={true}
                                                                    id="sundayOT"
                                                                    checked={
                                                                        true
                                                                    }
                                                                    labelText="Sunday"
                                                                />
                                                            </td>
                                                            <td
                                                                style={{
                                                                    paddingRight:
                                                                        '15px',
                                                                }}
                                                            >
                                                                <FormCheckbox
                                                                    small={true}
                                                                    id="mondayOT"
                                                                    checked={
                                                                        organization.billingOTDays &
                                                                        BillingOTDays.Monday
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        this.onOTDaysChanged(
                                                                            e,
                                                                            BillingOTDays.Monday
                                                                        );
                                                                    }}
                                                                    labelText="Monday"
                                                                />
                                                            </td>
                                                            <td
                                                                style={{
                                                                    paddingRight:
                                                                        '15px',
                                                                }}
                                                            >
                                                                <FormCheckbox
                                                                    small={true}
                                                                    id="tuesdayOT"
                                                                    checked={
                                                                        organization.billingOTDays &
                                                                        BillingOTDays.Tuesday
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        this.onOTDaysChanged(
                                                                            e,
                                                                            BillingOTDays.Tuesday
                                                                        );
                                                                    }}
                                                                    labelText="Tuesday"
                                                                />
                                                            </td>
                                                            <td
                                                                style={{
                                                                    paddingRight:
                                                                        '15px',
                                                                }}
                                                            >
                                                                <FormCheckbox
                                                                    small={true}
                                                                    id="wednesdayOT"
                                                                    checked={
                                                                        organization.billingOTDays &
                                                                        BillingOTDays.Wednesday
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        this.onOTDaysChanged(
                                                                            e,
                                                                            BillingOTDays.Wednesday
                                                                        );
                                                                    }}
                                                                    labelText="Wednesday"
                                                                />
                                                            </td>
                                                            <td
                                                                style={{
                                                                    paddingRight:
                                                                        '15px',
                                                                }}
                                                            >
                                                                <FormCheckbox
                                                                    small={true}
                                                                    id="thursdayOT"
                                                                    checked={
                                                                        organization.billingOTDays &
                                                                        BillingOTDays.Thursday
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        this.onOTDaysChanged(
                                                                            e,
                                                                            BillingOTDays.Thursday
                                                                        );
                                                                    }}
                                                                    labelText="Thursday"
                                                                />
                                                            </td>
                                                            <td
                                                                style={{
                                                                    paddingRight:
                                                                        '15px',
                                                                }}
                                                            >
                                                                <FormCheckbox
                                                                    small={true}
                                                                    id="fridayOT"
                                                                    checked={
                                                                        organization.billingOTDays &
                                                                        BillingOTDays.Friday
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        this.onOTDaysChanged(
                                                                            e,
                                                                            BillingOTDays.Friday
                                                                        );
                                                                    }}
                                                                    labelText="Friday"
                                                                />
                                                            </td>
                                                            <td>
                                                                <FormCheckbox
                                                                    small={true}
                                                                    id="saturdayOT"
                                                                    checked={
                                                                        organization.billingOTDays &
                                                                        BillingOTDays.Saturday
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        this.onOTDaysChanged(
                                                                            e,
                                                                            BillingOTDays.Saturday
                                                                        );
                                                                    }}
                                                                    labelText="Saturday"
                                                                />
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </FormGroup>
                                            </>
                                        )}
                                    </>
                                )}

                            {isCustomer &&
                                organization.parentCustomerApply &&
                                parentCustomers.length > 0 && (
                                    <FormGroup>
                                        <FormLabel
                                            htmlFor="type"
                                            text={'Parent Customer'}
                                        />
                                        <ValidatedSelect
                                            id="types"
                                            name="organization.parentCustomerId"
                                            required={false}
                                            options={parentCustomers}
                                            value={
                                                (parentCustomers ?? []).find(
                                                    (x) =>
                                                        organization.parentCustomerId ===
                                                        x.value
                                                ) ?? ''
                                            }
                                            onChange={this.onSelectChanged}
                                        />
                                    </FormGroup>
                                )}
                            <FormGroup hidden={!parentRequired}>
                                <FormLabel
                                    htmlFor="type"
                                    text={parentCompanyLabelText}
                                    required={parentRequired}
                                />
                                <ValidatedSelect
                                    id="types"
                                    name="organization.parentCompanyId"
                                    required={parentRequired}
                                    options={parentCompanies}
                                    value={
                                        (parentCompanies ?? []).find(
                                            (x) =>
                                                organization.parentCompanyId ===
                                                x.value
                                        ) ?? ''
                                    }
                                    onChange={this.onSelectChanged}
                                    validationMessage="A billing organization is required."
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    {(!!isCustomer || !!isParentCustomer || !!isProspect) && (
                        <Row>
                            <Col>
                                <SubHeading className="mt-4">
                                    {'Contacts'}
                                </SubHeading>

                                {organization.companyStatusId == 1 && (
                                    <SimpleTable
                                        permission={'company'}
                                        onAddFunction={this.onAddContact}
                                        addLabelText="Add Contact"
                                        tableHeaderLabels={[
                                            { name: 'Name' },
                                            { name: 'Title' },
                                            { name: 'Email' },
                                            { name: 'Phone', width: '100px' },
                                            { name: 'Type' },
                                            { name: 'Is Active?' },
                                        ]}
                                        editable={true}
                                        entities={organization.contacts}
                                        rowRenderer={this.renderContacts}
                                        noDataText={'No contacts found.'}
                                    />
                                )}

                                {organization.companyStatusId != 1 && (
                                    <SimpleTable
                                        permission={'company'}
                                        tableHeaderLabels={[
                                            { name: 'Name' },
                                            { name: 'Title' },
                                            { name: 'Email' },
                                            { name: 'Phone', width: '100px' },
                                            { name: 'Type' },
                                            { name: 'Is Active?' },
                                        ]}
                                        editable={false}
                                        entities={organization.contacts}
                                        rowRenderer={this.renderContacts}
                                        noDataText={'No contacts found.'}
                                    />
                                )}
                            </Col>
                        </Row>
                    )}

                    {!!isCustomer && !!existing && (
                        <>
                            <Row>
                                <Col>
                                    <SubHeading className="mt-4">
                                        Jobs
                                    </SubHeading>
                                    <small
                                        className="mt-2 mb-2"
                                        hidden={
                                            !((organization ?? {}).jobs ?? [])
                                                .length
                                        }
                                    >
                                        Displaying the 5 most recent jobs.
                                    </small>
                                    <SimpleTable
                                        permission={'company'}
                                        tableHeaderLabels={[
                                            { name: 'Job #' },
                                            { name: 'Job Name' },
                                            { name: 'Active?', width: '80px' },
                                        ]}
                                        editable={true}
                                        entities={this.state.organization.jobs}
                                        rowRenderer={this.renderJobRows}
                                        noDataText={'No jobs found.'}
                                    />
                                </Col>
                            </Row>
                            {!!(tenantSettings ?? {}).billingEnabled &&
                                isBillingRatesAllowed && (
                                    <Row className="mt-3">
                                        <Col>
                                            <SubHeading>
                                                Billing Rates
                                            </SubHeading>
                                            <GroupedRow>
                                                <BillingRateGrid
                                                    showChargeType={true}
                                                    rates={_.orderBy(
                                                        organization.billingRates,
                                                        (x) => [
                                                            x.chargeTypeName,
                                                            x.billingRate
                                                                .effectiveDate,
                                                        ]
                                                    )}
                                                    onAddRate={this.onAddRate}
                                                    onEditRate={this.onEditRate}
                                                    onShowRateHistory={
                                                        this.onShowRateHistory
                                                    }
                                                />
                                            </GroupedRow>
                                        </Col>
                                    </Row>
                                )}
                        </>
                    )}

                    {isInvoicingAllowed &&
                        isChargeTypeGroupsAllowed &&
                        (organization.types ?? []).filter(
                            (x) => x == OrganizationTypes.Payroll
                        ).length > 0 && (
                            <>
                                {!!(tenantSettings ?? {}).useStateMinimum && (
                                    <Row className="mt-3">
                                        <Col>
                                            <SubHeading>
                                                State Minimum Wage
                                            </SubHeading>
                                            <GroupedRow>
                                                <MinimumWageRateGrid
                                                    onAddMinWageRate={
                                                        this.onAddMinWageRate
                                                    }
                                                    onEditMinWageRate={
                                                        this.onEditMinWageRate
                                                    }
                                                    minWageRates={_.orderBy(
                                                        this.state.organization
                                                            .companyMinimumWageRates,
                                                        (x) => [x.startDate],
                                                        'desc'
                                                    )}
                                                />
                                            </GroupedRow>
                                        </Col>
                                    </Row>
                                )}
                            </>
                        )}

                    {(organization.types ?? []).filter(
                        (x) => x == OrganizationTypes.Customer
                    ).length > 0 && (
                        <>
                            <Row className="mt-3">
                                <Col>
                                    <SubHeading>Charge Type Groups</SubHeading>
                                    <GroupedRow>
                                        <OrganizationChargeTypeGroupGrid
                                            groups={
                                                (organization ?? [])
                                                    .companyChargeTypeGroups
                                            }
                                            onAddCTGroup={
                                                this.toggleCTGroupModal
                                            }
                                            onEditCTGroup={this.onEditCTGroup}
                                            onRemoveCTGroup={
                                                this.onRemoveCTGroup
                                            }
                                            onCTGroupChanged={
                                                this.onCTGroupChanged
                                            }
                                        />
                                    </GroupedRow>
                                </Col>
                            </Row>
                        </>
                    )}
                    {!!isSubcontractor && !!existing && (
                        <FlexColumnCenter className="mb-3">
                            <SubHeading className="mt-4">
                                Subcontractor For
                            </SubHeading>
                            <FormGroup className="mt-3 subcontractor-form-group">
                                <FormLabel
                                    htmlFor="subcontractorCustomers"
                                    text="Customer(s)"
                                    required={true}
                                />
                                <ValidatedSelect
                                    id="subcontractorCustomers"
                                    name="organization.subcontractorCustomers"
                                    isMulti
                                    options={subcontractorCustomersOptions}
                                    required
                                    value={selectedSubcontractorCustomers}
                                    onChange={
                                        this.subcontractorCustomersChanged
                                    }
                                    validationMessage="A customer selection is required."
                                />
                            </FormGroup>
                        </FlexColumnCenter>
                    )}

                    <FlexCenterRow className="mb-3">
                        <Button
                            disabled={!!this.state.saving}
                            size="sm"
                            type="submit"
                            color="primary"
                            name="organizationForm"
                        >
                            <FontAwesomeIcon className="mr-2" icon={faSave} />
                            {!!this.state.saving
                                ? 'Saving...'
                                : !existing
                                ? 'Save New Organization'
                                : 'Save'}
                        </Button>
                    </FlexCenterRow>

                    <div ref={this.billingRatesRef}>&nbsp;</div>

                    <Modal
                        isOpen={showCTGroupModal}
                        toggle={() => this.toggleCTGroupModal()}
                    >
                        <ModalHeader toggle={() => this.toggleCTGroupModal()}>
                            Charge Type Groups
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <FormLabel
                                    htmlFor="chargeTypeGroups"
                                    text="Charge Type Group(s)"
                                />
                                <ValidatedSelect
                                    id="chargeTypeGroups"
                                    name="organization.companyChargeTypeGroups"
                                    isMulti
                                    options={chargeTypeGroups.filter(
                                        (x) =>
                                            !(
                                                organization.companyChargeTypeGroups ??
                                                []
                                            )
                                                .map((x) => x.chargeTypeGroupId)
                                                .includes(x.id)
                                    )}
                                    getOptionLabel={(o) => o.invoiceDescription}
                                    getOptionValue={(o) => o.id}
                                    onChange={
                                        this.onChargeTypeGroupSelectChanged
                                    }
                                />
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="primary"
                                onClick={() => this.onAddCTGroup()}
                            >
                                Ok
                            </Button>{' '}
                            <Button
                                color="secondary"
                                onClick={() =>
                                    this.setState({ showCTGroupModal: false })
                                }
                            >
                                Cancel
                            </Button>
                        </ModalFooter>
                    </Modal>
                </AppPageForm>
                {!!isCustomer || isParentCustomer || !!isProspect ? (
                    <>
                        <OrganizationContactForm
                            ref={this.contactFormRef}
                            show={showContactsForm}
                            contact={selectedContact}
                            readOnly={contactFormIsReadOnly}
                            allowContactTypeEdit={allowContactTypeEdit}
                            allowAllContactTypes={true}
                            onClose={() => {
                                this.setState({ showContactsForm: false });
                            }}
                            onChange={(e) => {
                                let { selectedContact } = this.state;
                                selectedContact[e.target.name] = e.target.value;
                                this.setState({
                                    selectedContact: selectedContact,
                                });
                            }}
                            onCheckedChanged={(e) => {
                                let { selectedContact } = this.state;
                                selectedContact[e.target.name] =
                                    e.target.checked;
                                this.setState({
                                    selectedContact: selectedContact,
                                });
                            }}
                            onContactTypeChanged={(items) => {
                                let { selectedContact } = this.state;
                                selectedContact.contactTypes = items.map(
                                    (x) => x.value
                                );
                                this.setState({
                                    newContact: { ...selectedContact },
                                });
                            }}
                            onSaveCallback={this.onOrganizationContactSaved}
                        />

                        {!!isBillingRatesAllowed && (
                            <>
                                <BillingRateForm
                                    ref={this.rateFormRef}
                                    id="billingRateForm"
                                    entity={this.state.selectedRate}
                                    entityName={'selectedRate'}
                                    show={this.state.showRateForm}
                                    onClose={this.onRateFormClosed}
                                    onSaveCallback={this.onRateSaved}
                                    onRemoveCallback={this.onRateRemoved}
                                    onChange={this.onChange}
                                    onSelectChange={this.onSelectChanged}
                                    chargeTypes={chargeTypes}
                                    showChargeType={true}
                                    showRemove={showRateFormDelete}
                                />
                                <BillingRateHistoryForm
                                    ref={this.rateHistoryFormRef}
                                    id="billingRateHistoryForm"
                                    entity={this.state.selectedRateHistory}
                                    show={this.state.showRateHistoryForm}
                                    onClose={this.onRateHistoryFormClosed}
                                />
                            </>
                        )}
                    </>
                ) : (
                    ''
                )}

                <MinimumWageRateForm
                    ref={this.minWageRateFormRef}
                    id="minWageRateForm"
                    entity={this.state.selectedMinWageRate}
                    entityName={'selectedMinWageRate'}
                    show={this.state.showMinWageRateForm}
                    onClose={this.onMinWageRateFormClosed}
                    onSaveCallback={this.onMinWageRateSaved}
                    onRemoveCallback={this.onMinWageRateRemoved}
                    onChange={this.onChange}
                    showRemove={showMinWageRateFormDelete}
                />
            </Fragment>
        );
    }
}

export default withRouter(OrganizationForm);
