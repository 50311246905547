import React from 'react';
import { withRouter } from 'react-router-dom';
import PayrollIndex from '../payroll/PayrollIndex'

//2022-10-10 - M. Nicol.
//I'm virtually certain there's a better way to do this, but with the Can component, there does not seem
//to be a way to define multiple acceptable actions for a given route.
//
//This describes ways to have conditions with function usage.
//There's probably a way to refactor our permission checks selectively using this.
//  https://casl.js.org/v5/en/guide/conditions-in-depth
//
//This discusses a way to require ALL in a given array of actions:
//  https://casl.js.org/v5/en/guide/define-aliases
class PayrollIndividualIndex extends React.Component {

    render() {

        return (
            <PayrollIndex></PayrollIndex>
            );

    }

}

export default withRouter(PayrollIndividualIndex);