export class ContactActivity {
    id = 0;
    locationContactId = 0;

    createdByUserName = '';

    activityType = ''; // 'Task' | 'Notes'

    // properties for notes
    title = '';
    notes = '';
    noteDate = new Date().toLocaleDateString();

    // properties for tasks
    taskDueDate = '';
    activityTaskStatusId = 0;
    activityTaskTypeId = 0;
    taskInteractionTypeId = 0;
    description = '';
    details = '';
    assignedTo = '';
    assignedToUser = {};
}

export const KnownActivityTypes = { Note: 'Note', Task: 'Task' };

export const ActivityTypeOptions = [
    { label: 'Not Selected', value: '' },
    { label: KnownActivityTypes.Note, value: KnownActivityTypes.Note },
    { label: KnownActivityTypes.Task, value: KnownActivityTypes.Task },
];
