import { LinearProgress } from '@material-ui/core';
import React from 'react';
import { withRouter } from 'react-router-dom';
import CommonContext, { ApiRoutes, AppNavPaths, MomentDateFormat } from "../Common";
import {
    createDataSource,
    createGridOptions,
    DataGrid,
    DateFilterDefaults,
    getComponents,
} from '../common/dataGrid/DataGrid';
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';
import { PageWrap, FlexEndRow, SmallButton, FlexColumnStart, FlexBetweenRow } from '../common/forms/FormElements';
import authService from '../api-authorization/AuthorizeService';
import { renderToString } from 'react-dom/server';
import './MyTimesheets.scss';
import { Col, Input, Row } from 'reactstrap';
import { getTenantUserProfile } from '../common/TenantUserProfile';
import * as moment from 'moment';
import { TimesheetStatus } from './Timesheet';
import { util } from '../Util';

export class MyTimesheetData {
    constructor(obj) {
        obj && Object.assign(this, obj);
    }
    billingStatus = null;
    billingStatusId = null;
    contractId = null;
    employeeFlatValue = null;
    employeeHoursValue = null;
    employeeHoursDuration = null;
    employeeId = null;
    employeeName = null;
    equipment = null;
    equipmentFlatValue = null;
    equipmentHoursValue = null;
    isSelfDispatching = null;
    jobDate = null;
    jobNumber = null;
    payrollStatus = null;
    payrollStatusId = null;
    timesheetId = null;
    timesheetNumber = null;
    timesheetStatus = null;
    timesheetStatusId = null;
    getFormattedDate = () => !!this.jobDate ? moment(this.jobDate).format("dddd, M/D/YYYY") : null;
}

class MyTimesheets extends React.Component {

    static contextType = CommonContext;

    //#region SETUP
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            rowData: [],
            selectedMonth: moment().format("YYYY-MM")
        };
    }

    componentDidMount = () => {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
        this.setState = (state, callback) => { return };
    }

    populateState = async () => {
        const isAuthenticated = await authService.isAuthenticated();

        if (!!isAuthenticated) {

            const tenantUserProfile = await getTenantUserProfile();
            const { currentUser, userTenant } = tenantUserProfile;

            //Check tenant settings and user permissions for access to either self-dispatch ts or
            //non-flag ts.
            const access = await util.fetch.js(ApiRoutes.contract.userHasSelfDispatching());

            var selfDispatchTimesheetCount = await util.fetch.js(ApiRoutes.selfDispatchTimesheet.count()) ?? 0;

            const hasAccess =
                //Does the user have any preentered timesheets?
                Boolean(selfDispatchTimesheetCount > 0)
                ||
                //Are there any self dispatching contracts for the user's dispatching center?
                //Does the user have the permission?
                Boolean(!!access.hasSelfDispatchingAccess && !!currentUser.canCreateSelfDispatchTimesheet)
                ||
                //Are there any non-flagging contracts for the user's dispatching center?
                //Does the user have the permission?
                Boolean(!!access.hasNonFlaggingAccess && !!currentUser.canCreateNonFlagTimesheet);

            if (!userTenant.tenantSettings.enableSelfDispatchTimesheets || !hasAccess) {
                this.props.history.push(AppNavPaths.NotFound);
                return false;
            }

            const gridSetup = await this.setupGrid();

            //Save our grid options and dataSource to the state, so we can utilize it.
            this.setState({
                loading: false,
                gridOptions: gridSetup.gridOptions,
                dataSource: gridSetup.dataSource,
                canCreateSelfDispatchTimesheet: Boolean(currentUser.canCreateSelfDispatchTimesheet),
                canCreateNonFlagTimesheet: Boolean(currentUser.canCreateNonFlagTimesheet),
                hasSelfDispatchingAccess: access.hasSelfDispatchingAccess,
                hasNonFlaggingAccess: access.hasNonFlaggingAccess
            });
        }
    }
    //#endregion

    //Overrides the default loading overlay.
    loadingOverlayTemplate = () => renderToString(
        <h4 className="d-flex flex-row align-items-center gridLoadingOverlay">
            <i className="fa fa-spin text-danger fa-lg fa-circle-notch mr-2"></i>
            Getting timesheets...
        </h4>
    );

    getStatusClassContext = statusId => {
        var classes = "";
        switch (statusId) {
            case TimesheetStatus.Submitted:
                classes = "secondary";
                break;
            case TimesheetStatus.Approved:
                classes = "success";
                break;
            case TimesheetStatus.Rejected:
                classes = "danger";
                break;
            //DS/NF timesheets can be rejected by bill/payroll, but the emp
            //does not need to be informed of that status.
            case TimesheetStatus.BillingPayrollRejected:
                classes = "success";
                break;
            default:
                break;                
        }
        return classes;
    }

    //For a mobile centric view, we render templated rows.
    fullWidthCellRenderer = (params) => {
        const data = params?.node?.data;
        if (data) {
            const timesheet = new MyTimesheetData(data);
            return (
                <FlexColumnStart className="w-100 my-timesheet-row-content" id={`mytimesheet_${timesheet?.id}`}>
                    <FlexBetweenRow className="flex-fill w-100">
                        <span className="timesheet-date site-link cursor-pointer">
                            {timesheet.getFormattedDate()}
                        </span>
                        <FlexEndRow>                            
                            <span className="ml-2 timesheet-duration font-weight-bold">
                                {timesheet.employeeHoursDuration}
                            </span>
                        </FlexEndRow>
                    </FlexBetweenRow>
                    <FlexBetweenRow>
                        <small className="timesheet-number">{timesheet.timesheetNumber}</small>
                        <span className={`timesheet-status text-${this.getStatusClassContext(timesheet.timesheetStatusId)}`}>
                            {
                                timesheet.timesheetStatusId === TimesheetStatus.Rejected &&
                                <span className="fa fa-exclamation-triangle mr-2"></span>
                            }
                            {timesheet.timesheetStatus}
                        </span>
                    </FlexBetweenRow>
                </FlexColumnStart>
            );
        }
        return null;
    };

    //Sets the date filter from the dropdown selection, overriding the built-in filters.
    setDateRange = dateRangeFilterComponent => {
        const { selectedMonth } = { ...this.state };
        const mSelectedMonth = moment(selectedMonth, "YYYY-MM");
        //Sets the range to be the range of the month selection.
        const startOfMonth = mSelectedMonth.startOf('month').format(MomentDateFormat.DateOnlyUTCHyphenated);
        const endOfMonth = mSelectedMonth.endOf('month').format(MomentDateFormat.DateOnlyUTCHyphenated);

        dateRangeFilterComponent.setModel({
            filterType: "date",
            type: "inRange",
            dateFrom: startOfMonth,
            dateTo: endOfMonth
        });
    }

    //When the user navigates to a timesheet, check the timesheet details to 
    //see if self dispatch//non flagging, and navigate appropriately.
    onRowClicked = params => {
        if (params?.node.data) {
            const timesheet = new MyTimesheetData(params.node.data);
            if (!!timesheet.isSelfDispatching) {
                this.props.history.push(
                    `${(!!timesheet.isNonFlagging ? AppNavPaths.NonFlaggingTimesheet : AppNavPaths.SelfDispatchTimesheet)}/${timesheet.timesheetId}`
                );
            } else {
                this.props.history.push(
                    `${AppNavPaths.Timesheet}/${timesheet.id}`
                );
            }
        }
    }

    setupGrid = async () => {
        let context = this;
        const { currentUser } = await getTenantUserProfile();

        const gridOptions = {
            ...createGridOptions(this),
            components: {
                ...getComponents()
            },
            columnDefs: [{
                colId: 'Date',
                headerName: 'Timesheets',
                field: 'date',
                sortable: false,
                filter: 'agDateColumnFilter',
                filterParams: {
                    ...DateFilterDefaults,
                    inRangeInclusive: true,
                    closeOnApply: true,
                    browserDatePicker: true,
                    suppressFilterButton: true
                },
                floatingFilter: false,
                sort: { direction: 'desc', priority: 0 },
                cellRenderer: this.fullWidthCellRenderer,
                suppressMenu: true
            }],
            customParametersFunction: () => ({ employeeId: currentUser.employeeId }),
            getRowClass: params => {
                if (!!params?.node?.data?.timesheetStatusId) {
                    return [
                        'timesheet-status-' + params.node.data.timesheetStatus.toLowerCase()
                    ];
                }
            },
        };

        //Overrides of the default grid options 
        gridOptions.rowHeight = 60;
        gridOptions.defaultColDef.floatingFilter = false; //Hide the floating filter bar.
        gridOptions.overlayLoadingTemplate = this.loadingOverlayTemplate(); //Apply our manual loading overlay.
        gridOptions.overlayNoRowsTemplate = `No timesheets found.`;
        gridOptions.showLoadingOverlay = true; //enable overlays.

        //On the grid's first load, we want to apply the filters immediately from the state population,
        //before the API call is made.
        gridOptions.onGridReady = (params) => {
            const dateRangeFilterComponent = params.api.getFilterInstance('Date');
            this.setDateRange(dateRangeFilterComponent);
            params.api.onFilterChanged();
            gridOptions.api = params.api;
            params.api.setDatasource(context.state.dataSource, gridOptions);
        };

        gridOptions.onRowClicked = this.onRowClicked

        //Apply the created server datasource to the grid.
        const dataSource = createDataSource(ApiRoutes.timesheet.byEmployeeSearch(), gridOptions);
        return { gridOptions, dataSource };
    }

    onAddNonFlagTimesheet = () => this.props.history.push(AppNavPaths.NonFlaggingTimesheetNew);
    onAddSelfDispatchTimesheet = () => this.props.history.push(AppNavPaths.SelfDispatchTimesheetNew);

    onMonthSelectionChanged = async (event) => {
        const newMonth = event.target.value;
        await this.setState({ selectedMonth: newMonth });
        this.getTimesheets();
    }

    getTimesheets = () => {
        const dateFilterComponent = this.state.gridOptions.api.getFilterInstance('Date');
        this.setDateRange(dateFilterComponent);
        this.state.gridOptions.api.onFilterChanged();
    }

    render() {

        if (this.state.loading) return (<LinearProgress variant="indeterminate" color="secondary" />)

        const { rowData, gridOptions, dataSource, canCreateNonFlagTimesheet,
            canCreateSelfDispatchTimesheet, hasSelfDispatchingAccess, hasNonFlaggingAccess } = { ...this.state };

        return (
            <>
                <PageWrap id="myTimesheetsContent">
                    <Row className="flex-fill d-flex flex-column flex-nowrap">
                        <Col xl="6" lg="8" md="10" sm="12" className="ml-auto mr-auto pt-2 flex-fill d-flex flex-column flex-nowrap">
                            <DataGridToolbar
                                entity="timesheet"
                                gridApi={gridOptions.api}
                                dataSource={dataSource}
                                hideAdd={true}
                                hideExcelButton={true}
                                hideClearFilterButton={true}
                                gridOptions={gridOptions}
                            >
                                {
                                    //TODO: Check for NF timesheet or SD timesheet perm, display proper button.
                                    <FlexEndRow>
                                        <Input
                                            type="month"
                                            id="myTimesheetsMonthSelector"
                                            className="form-control form-control-sm"
                                            value={this.state.selectedMonth ?? ''}
                                            onChange={this.onMonthSelectionChanged}
                                        />
                                        {
                                            !!hasNonFlaggingAccess  && !!canCreateNonFlagTimesheet &&
                                            <SmallButton
                                                disabled={!!this.state.saving}
                                                onClick={this.onAddNonFlagTimesheet}
                                            >
                                                <i className="fa fa-plus" />
                                                <span className="small-viewport-hide ml-2">New Non-Flagging Timesheet</span>
                                            </SmallButton>
                                        }
                                        {
                                            !!hasSelfDispatchingAccess && !!canCreateSelfDispatchTimesheet &&
                                            <SmallButton
                                                disabled={!!this.state.saving}
                                                onClick={this.onAddSelfDispatchTimesheet}
                                            >
                                                <i className="fa fa-plus" />
                                                <span className="small-viewport-hide ml-2">New Self-Dispatch Timesheet</span>
                                            </SmallButton>
                                        }
                                    </FlexEndRow>
                                }
                            </DataGridToolbar>
                            <div className="p-1"></div>
                            <DataGrid                                
                                domLayout={"normal"}
                                rowData={rowData}
                                gridOptions={gridOptions}
                                gridStatus={this.state.gridStatus}
                            />
                        </Col>
                    </Row>
                </PageWrap>
            </>
        );
    }
}

export default withRouter(MyTimesheets);