import { faTasks } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LinearProgress } from '@material-ui/core';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, FormText } from 'reactstrap';
import CommonContext, { ApiRoutes } from "../Common";
import { createDataSource,
    createGridOptions,
    DataGrid,
    IconCellRenderer,
    LinkCellRenderer,
    TextFilterDefaults,
    DateFilterDefaults,
    VariableLinkCellRenderer,
    TimesheetNumberCellRenderer,
    FlagIconCellRenderer,
} from '../common/dataGrid/DataGrid';
import Select from 'react-select';
import { CompactSelectStyles } from '../common/forms/ValidatedSelect';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';
import { faCircleNotch } from '@fortawesome/fontawesome-free-solid';
import { FlexCenterRow, onFieldChange, PageHeading, PageWrap, toasty, FormLabel } from '../common/forms/FormElements';
import { util } from '../Util';
import PayrollCalculationSlideout from '../payrollManagement/PayrollCalculationSlideout';
import { NotAuthorizedPage } from '../status/StatusCodes';
import { handleFormSaveError } from '../common/forms/ValidationError';
import { PayrollFlags } from '../payroll/Payroll';
import { ChargeTypeUnits } from '../chargeType/ChargeType';
import TimeEntry from '../common/forms/TimeEntry';
import * as moment from 'moment';

class PayrollManagementIndex extends React.Component {

    static contextType = CommonContext;

    constructor(props) {

        super(props);
        this.payrollCalculationRef = React.createRef();

        this.state = {
            loading: true,
            rowData: [],
            rowsSelected: [],
            selectedDispatchLocation: null,
            selectedWeekOf: '',
            showProcessModal: false,
            isProcessing: false,
            isDownloading: false,
            showDownloadModal: false,
            selectedExportId: null,
            showRejectPayrollModal: false,
            isRejectingPayroll: false,
            adminRejecting: false,
            selectedRowRejectionNotes: '',
            selectedDispatchLocation: null,
            selectedWeekOf: '',
            showAddChargeModal: false,
            isAddingCharge: false
        };

        this.onRowSelected = this.onRowSelected.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount = () => this.populateState();
    componentWillUnmount = () => this.setState = (state, callback) => { return };

    onChange = onFieldChange;

    populateState = async () => {

        const dispatchLocations = await util.fetch.js(ApiRoutes.typeAheads.dispatchLocations());
        const payrollAllStatuses = await util.fetch.js(ApiRoutes.typeAheads.payrollStatuses());
        const billingStatuses = await util.fetch.js(ApiRoutes.typeAheads.billingStatuses());
        const flags = await util.fetch.js(ApiRoutes.typeAheads.payrollChargeFlags());
        const chargeTypeUnits = await util.fetch.js(ApiRoutes.typeAheads.chargeTypeUnits());
        const pendingTimesheets = await util.fetch.js(ApiRoutes.typeAheads.pendingPayrollTimesheets());

        const payrollStatuses = payrollAllStatuses.filter(x => x.value == 1 || x.value == 3 || x.value == 5);

        const yesNo = [
            { label: "Yes", value: 'true' },
            { label: "No", value: 'false'}
        ];

        await this.setState({ dispatchLocations, payrollStatuses, billingStatuses, flags, yesNo });

        const gridOptions = createGridOptions(this);
        let context = this;

        gridOptions.components = {
            selectFilter: DataGridSelectFilter,
            selectFloatingFilter: DataGridSelectFloatingFilter,
            nameRenderer: LinkCellRenderer,
            iconRenderer: IconCellRenderer,
            variableLinkRenderer: VariableLinkCellRenderer,
            timesheetNumberRenderer: TimesheetNumberCellRenderer,
            flagIconRenderer: FlagIconCellRenderer,
        };

        //https://www.ag-grid.com/documentation/javascript/row-selection/#checkbox-selection
        //One column must have "checkboxSelection" set to true.
        //Note: headerCheckboxSelection not available with infinite scroll.
        gridOptions.rowSelection = "multiple";

        gridOptions.postProcessData = this.transformRowData;
        gridOptions.onRowSelected = this.onRowSelected;

        gridOptions.columnDefs = this.getColumnDefs();

        //this allows row colors to be set
        gridOptions.rowClassRules = {
            'ag-row-danger': (params) => {
                return (params.data ?? {}).isEmergency === true;
            },
            'ag-row-warning': (params) => {
                return ((params.data ?? {}).overLaps === 'Yes' || (params.data ?? {}).timeError === true) && (params.data ?? {}).isEmergency === false;
            },
            'ag-row-info': (params) => {
                return (params.data ?? {}).isClientCancelled === true && (params.data ?? {}).isEmergency === false;
            },
            'ag-row-success': (params) => {
                var matches = ((params.data ?? {}).flag ?? []).filter((f) => {
                    return f === PayrollFlags.ChargeTypeThresholdExceeded || f === PayrollFlags.BreakThresholdExceeded;
                });

                return matches.length > 0;
            },
            'ag-row-nopsschedule': (params) => {
                var matches = ((params.data ?? {}).flag ?? []).filter((f) => {
                    return f === PayrollFlags.NoPayScheduleOnPWContract;
                });

                return matches.length > 0;
            }
        };

        //Loading Spinner
        gridOptions.useLoading = true;
        gridOptions.loadingTemplate = '<span className="ag-overlay-loading-center "><i class="fa-spin fa fa-md fa-circle-notch"></i> Loading ...</span>';

        gridOptions.onGridReady = (params) => {
            let start = moment(moment().subtract(30, 'days')).format(
                'YYYY-MM-DD'
            );
            let end = moment(moment().subtract(1, 'days')).format(
                'YYYY-MM-DD'
            );

            const filterInstance =
                gridOptions.api.getFilterInstance('JobDate');
            filterInstance.setModel({
                type: 'inRange',
                dateFrom: start,
                dateTo: end,
            });
            gridOptions.api.onFilterChanged();

            params.api.setDatasource(context.state.dataSource);
            context.setState({ gridApi: params.api });
        };

        const dataSource = createDataSource(ApiRoutes.payrollManagement.search(), gridOptions);

        this.setState({
            loading: false,
            gridOptions: gridOptions,
            dataSource: dataSource,
            isRejectingPayroll: false,
            billingChargeResources: [],
            selectedTimesheet: null,
            selectedChargeType: null,
            pendingTimesheets: pendingTimesheets,
            paymentChargeTypes: []
        });

    }

    transformRowData(data) {

        data.rows.forEach(row => {

            //Placeholder column so we can have it be the last checkable thing on the right.
            //Otherwise, we could set checkboxSelection = true for an existing column.
            row.selectionPlaceholder = "";
        });
        return { ...data };
    }

    getColumnDefs() {

        //https://www.ag-grid.com/documentation/javascript/column-definitions/

        let dispatchFilterParams = {
            colDef: 'Payroll.DispatchLocationName',
            column: 'Payroll.DispatchLocationName',
            suppressFilterButton: true,
            labelText: 'Filter by Dispatch',
            options: this.state.dispatchLocations,
            optionsLabel: 'label',
            optionsValue: 'value',
        },
            payrollStatusFilterParams = {
                suppressFilterButton: true,
                options: this.state.payrollStatuses,
                optionsLabel: 'label',
                optionsValue: 'value'
            },
            billingStatusFilterParams = {
                suppressFilterButton: true,
                options: this.state.billingStatuses,
                optionsLabel: 'label',
                optionsValue: 'value'
            },
            flagsFilterParams = {
                suppressFilterButton: true,
                options: this.state.flags,
                optionsLabel: 'label',
                optionsValue: 'value'
            },
            yesNoFilterParams = {
                suppressFilterButton: true,
                options: this.state.yesNo,
                optionsLabel: 'label',
                optionsValue: 'value'
            };

        const defs = [
            {
                headerName: "",
                valueGetter: "node.id",
                sortable: false,
                hide: true,
                flex: 1,
                maxWidth: 35,
                cellRenderer: this.indexCellRenderer
            },
            {
                colId: 'TimesheetNumber',
                headerName: 'Timesheet #',
                field: 'timesheetNumber',
                sortable: true,
                flex: 2,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
                cellRenderer: 'timesheetNumberRenderer'
            },
            {
                colId: 'JobDate',
                headerName: 'Date',
                field: 'date',
                sortable: true,
                minWidth: 120,
                maxWidth: 120,
                sort: { direction: 'asc', priority: 0 },
                filter: 'agDateColumnFilter',
                filterParams: DateFilterDefaults
            },
            {
                colId: 'Start',
                headerName: 'Start',
                field: 'start',
                sortable: false,
                minWidth: 120,
                maxWidth: 120,
                //filter: 'agTextColumnFilter',
                //filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                }
            },
            {
                colId: 'End',
                headerName: 'End',
                field: 'end',
                sortable: false,
                minWidth: 120,
                maxWidth: 120,
                //filter: 'agTextColumnFilter',
                //filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                }
            },
            {
                colId: 'ChargeType',
                headerName: 'Charge Type',
                field: 'chargeType',
                sortable: true,
                flex: 1,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                }
            },
            {
                colId: 'Charge',
                headerName: 'Charge',
                field: 'charge',
                type: "rightAligned",
                sortable: false,
                minWidth: 120,
                maxWidth: 120,
                cellStyle: (q) => {
                    if ((q.data ?? {}).isBreak) {
                        if (q.data.isPaidBreak) {
                            return { color: 'blue' };
                        }
                        else {
                            return { color: 'red' };
                        }
                    }
                    return {};
                },
                cellClass: (q) => {
                    if ((q.data ?? {}).charge) {
                        if (parseFloat(q.data.charge) > 16.0) {
                            return ['ag-cell-danger'];
                        }
                    }

                    return '';
                },
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                }
            },
            {
                colId: 'EmployeeName',
                headerName: 'Emp. Name',
                field: 'employeeName',
                sortable: true,
                flex: 1.5,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
                cellStyle: (q) => {
                    if ((q.data ?? {}).onBoarding) {
                        if (q.data.onBoarding) {
                            return { color: 'red', fontWeight: 'bold' };
                        }
                    }

                    return {};
                },
            },
            {
                colId: 'DispatchLocation',
                headerName: 'Dispatching',
                sortable: true,
                flex: 1.5,
                field: 'dispatchLocation',
                tooltipField: 'dispatchOfficeName',
                filter: 'selectFilter',
                filterParams: dispatchFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: dispatchFilterParams
            },
            {
                colId: 'CustomerName',
                headerName: 'Customer Name',
                field: 'customerName',
                sortable: true,
                flex: 1.5,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
                valueGetter: (row) => {
                    if (!!row.data) {
                        let name = `${row.data.customerName}${(!!row.data.subcontractorName ? ` - ${row.data.subcontractorName}` : '')}`;
                        return name;
                    }

                    return '';
                }
            },
            {
                colId: 'Billable',
                headerName: 'Billable',
                field: 'billable',
                sortable: false,
                minWidth: 120,
                maxWidth: 120,
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: yesNoFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: yesNoFilterParams
            },
            {
                colId: 'Overlaps',
                headerName: 'Overlaps',
                field: 'overlaps',
                sortable: false,
                minWidth: 120,
                maxWidth: 120,
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: yesNoFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: yesNoFilterParams,
                valueGetter: (row) => {
                    if (!!row.data) {
                        let val = row.data.overLaps;
                        return val;
                    }

                    return '';
                }
            },
            {
                colId: 'Status',
                headerName: 'Payroll Status',
                field: 'status',
                sortable: true,
                minWidth: 140,
                tooltipField: 'status',
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: payrollStatusFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: payrollStatusFilterParams,
                valueGetter: (row) => {
                    if (!!row.data) {
                        return row.data.status;
                    }

                    return '';
                }
            },
            {
                colId: 'BillingStatus',
                headerName: 'Billing Status',
                field: 'billingStatus',
                sortable: true,
                minWidth: 140,
                tooltipField: 'status',
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: billingStatusFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: billingStatusFilterParams,
                valueGetter: (row) => {
                    if (!!row.data) {
                        let isAddStatus =  !!row.data.isEmergency || !!row.data.isClientCancelled;
                        let addStatus = '';
                        if (!!row.data.isEmergency) addStatus += ' - Emergency';
                        if (!!row.data.isClientCancelled) addStatus += ' - Client Cancel';

                        let status = `${row.data.billingStatus}${(isAddStatus ? addStatus : '')}`;
                        return status;
                    }

                    return '';
                }
            },
            {
                colId: 'Flag',
                headerName: "Flag",
                field: 'flag',
                flex: 1,
                minWidth: 65,
                sortable: false,
                tooltipField: 'flag',
                tooltipComponentParams: { color: '#ececec' },
                cellRenderer: 'flagIconRenderer',
                cellRendererParams: {
                    bodyField: 'flag'
                },
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: flagsFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: flagsFilterParams
            },
            {
                colId: 'id',
                flex: 0,
                minWidth: 75,
                maxWidth: 75,
                headerName: '',
                sortable: false,
                cellRenderer: 'iconRenderer',
                cellRendererParams: {
                    clicked: this.openPayrollCalculation,
                    idField: 'id',
                    iconClass: 'fa-edit'
                }
            },
            {
                colId: 'SelectionPlaceholder',
                headerName: '',
                field: 'selectionPlaceholder',
                sortable: false,
                maxWidth: 75,
                checkboxSelection: true
            }
        ];

        return defs;
    }

    handleSaveError = (err) => handleFormSaveError(this, err);

    //https://stackoverflow.com/questions/44263350/count-number-of-selected-rows-in-ag-grid
    //Possibly use lodash dequeue?
    onRowSelected(e) {

        const rs = e.api.getSelectedRows();

        this.setState({
            rowsSelected: rs
        });
    }

    async toggleProcessModal() {
        const { showProcessModal } = this.state;
        this.setState({
            showProcessModal: !showProcessModal,
            selectedDispatchLocation: null,
            selectedWeekOf: '' });
    }

    toggleDownloadModal = async(id) => {
        const { showDownloadModal } = this.state;
        this.setState({
            selectedExportId: id,
            showDownloadModal: !showDownloadModal
        });
    }

    onSelectedDispatchLocationChanged = async (selection) => {
        const loc = { ...selection };
        this.setState({ selectedDispatchLocation: loc.value });
    }

    async onProcessClick() {
        const { selectedWeekOf, selectedDispatchLocation } = this.state;

        if (!selectedWeekOf || !selectedDispatchLocation)
            return;

        this.setState({ isProcessing: true });

        let model = {
            weekOf: new Date(selectedWeekOf),
            dispatchLocationId: selectedDispatchLocation
        };

        try {
            let response = await util.fetch.post(ApiRoutes.payrollManagement.process(), model);
            if (response) {
                toasty.error(response);
            }
            else {
                toasty.success("Processing complete");
            }
        }
        catch {
            toasty.error(`Error processing payroll records`)
        }


        this.state.gridOptions.refresh();

        this.setState({ rowsSelected: [], isProcessing: false, showProcessModal: false });
    }

    onApproveButtonClick = async () => {
        this.setState({ isApproving: true });

        let rowsSelected = this.state.rowsSelected;

        //call accept
        if (!!rowsSelected) {
            let model = rowsSelected.map((x) => x.id);
            let response = await util.fetch.post(ApiRoutes.payrollManagement.approve(), model)

            if (response) {
                toasty.error(`Error approving payroll records: ${response}`);
            } else {
                toasty.success('Payroll records approved');
            }
        }

        this.state.gridOptions.refresh();

        this.setState({ rowsSelected: [], isApproving: false });
    };

    onRejectPayroll = async () => {
        toasty.error('Not Implemented');
    }

    toggleRejectPayrollModal = async () => {

        let { showRejectPayrollModal, rowsSelected } = this.state;

        var adminRequired = await util.fetch.get(ApiRoutes.payrollManagement.needsAdminReject(rowsSelected[0].id));

        //if admin not needed just show regular
        if (adminRequired == false) {
            this.setState({ showRejectPayrollModal: !showRejectPayrollModal });
            return;
        }

        //if admin needed and user is not admin
        if (adminRequired == true && !this.context?.user?.isAdmin) {
            toasty.info("An admin needs to reject this timesheet due to it being processed already");
            return;
        }

        //if admin needed and is an admin
        if (adminRequired == true && this.context?.user?.isAdmin) {
            this.setState({
                showRejectPayrollModal: !showRejectPayrollModal,
                adminRejecting: true
            });

            return;
        }
    }

    rejectPayroll = async () => {
        let { rowsSelected, selectedRowRejectionNotes, adminRejecting } = this.state;

        if (selectedRowRejectionNotes.length === 0 || !selectedRowRejectionNotes.trim())
            return;
        this.setState({ isRejectingPayroll: true });

        //call reject
        if (!!rowsSelected) {
            let model = {
                payrollId: rowsSelected[0].id,
                note: selectedRowRejectionNotes
            };

            var endPoint = adminRejecting == true ? ApiRoutes.payrollManagement.adminReject() : ApiRoutes.payrollManagement.reject();
            let response = await util.fetch.post(endPoint, model)
                .catch(this.handleSaveError);

            if (response) {
                toasty.success('Payroll: Timesheet Rejected');
            }
        }

        this.state.gridOptions.refresh();

        this.setState({
            selectedRow: null,
            showRejectPayrollModal: false,
            selectedRowRejectionNotes: '',
            isRejectingPayroll: false,
            adminRejecting: false
        });
    }

    openPayrollCalculation = async (id) => {
        if (!!id) {
            await this.context.setFormOpened(true);
            this.payrollCalculationRef.current.open(id);
        }
    }

    onDownloadExportClick = async () => {
        const { selectedExportId } = this.state;
        this.setState({ isDownloading: true });
        if (!!selectedExportId) {
            /*var model = { id: id };*/
            await util.fetch.downloadFile(ApiRoutes.payroll.download(selectedExportId), null, "Export.xlsx")
                .catch(this.handleSaveError);
        }
        this.setState({ selectedExportId: null, isDownloading: false, showDownloadModal: false });
    }

    onDownloadWeeklyClick = async () => {
        const { selectedExportId } = this.state;
        this.setState({ isDownloading: true });
        if (!!selectedExportId) {
            /*var model = { id: id };*/
            await util.fetch.downloadFile(ApiRoutes.payroll.downloadWeekly(selectedExportId), null, "WeeklyEmployee.xlsx")
                .catch(this.handleSaveError);
        }
        this.setState({ selectedExportId: null, isDownloading: false, showDownloadModal: false });
    }

    printTimesheet = async (id) => {
        if (!!id) {
            window.open(ApiRoutes.report.timesheetPayroll(id), "_self");
        }
    }

    onPayrollCalculationClosed = async () => {
        await this.context.setFormOpened(false);
        this.setState({ selectedRow: null });
        this.state.gridOptions.refresh();
    }

    toggleAddChargeModal = async () => {
        const { showAddChargeModal } = this.state;

        this.setState({
            showAddChargeModal: !showAddChargeModal,
            billingChargeResources: [],
            selectedTimesheet: null,
            selectedChargeType: null,
            selectedResource: null,
            chargeStartTime: 0,
            chargeEndTime: 0,
            totalChargeTime: 0,
            chargeNotes: ''
        });
    }

    onSelectedTimesheetChanged = async (selection) => {
        const paymentChargeTypes = await util.fetch.js(ApiRoutes.typeAheads.payrollChargeTypes(selection.value));

        this.setState({
            selectedTimesheet: selection,
            paymentChargeTypes: paymentChargeTypes,
            selectedChargeType: null,
            selectedResource: null,
            billingChargeResources: []
        });
    }

    onSelectedChargeTypeChanged = async (selection) => {
        let { selectedTimesheet } = this.state;
        this.loadChargeResourcesList(selectedTimesheet, selection);
    }

    onSelectedResourceChanged = async (selection) => {
        this.setState({ selectedResource: selection });
    }

    loadChargeResourcesList = async (selectedTimesheet, selectedChargeType) => {

        var billingChargeResources = [];

        if (selectedTimesheet && selectedChargeType && selectedTimesheet.dispatchLocationId > 0) {
            if (selectedChargeType.type == ChargeTypeUnits.Hours) {
                billingChargeResources = await util.fetch.js(ApiRoutes.invoice.slideoutStaff(selectedTimesheet.dispatchLocationId));
                billingChargeResources = billingChargeResources.map((x) => {
                    return { label: x.name, value: x.id };
                });
            }
            else {
                billingChargeResources = await util.fetch.js(ApiRoutes.invoice.slideoutEquipment(selectedTimesheet.dispatchLocationId));
                billingChargeResources = billingChargeResources.map((x) => {
                    return { label: x.description, value: x.id };
                });
            }
        }

        this.setState({ chargeStartTime: '', chargeEndTime: '', chargeNotes: '', totalChargeTime: 0, selectedResource: null, selectedTimesheet: selectedTimesheet, selectedChargeType: selectedChargeType, billingChargeResources: billingChargeResources });
    }

    onTimeEntryStartChanged = (ev) => {
        let val = ev.target.value;
        let timeValue = !!ev.target.value ? moment(val, ['h:mm A']).format('HH:mm') : "";

        let { selectedTimesheet, chargeEndTime } = { ...this.state };
        let total = this.getTotalHours(selectedTimesheet, timeValue, chargeEndTime);

        this.setState({ totalChargeTime: total, chargeStartTime: timeValue });
    }

    onTimeEntryEndChanged = (ev) => {
        let val = ev.target.value;
        let timeValue = !!ev.target.value ? moment(val, ['h:mm A']).format('HH:mm') : "";

        let { selectedTimesheet, chargeStartTime } = { ...this.state };
        let total = this.getTotalHours(selectedTimesheet, chargeStartTime, timeValue);

        this.setState({ totalChargeTime: total, chargeEndTime: timeValue });
    }

    onChargeValueChanged = (ev) => {
        let val = ev.target.value;
        this.setState({ totalChargeTime: val });
    }

    getTotalHours = function (timesheet, start, end) {
        //Calculate the duration between Start and End.  Factor in if this enters into the next day.
        //Use the job start date/time as opposed to moment defaulting to the current day when getting duration.
        if (!!start && !!end) {
            const time_format = 'HH:mm';

            let mStartTime = moment(start, time_format), mEndTime = moment(end, time_format);
            if (mEndTime.isBefore(mStartTime)) {
                mEndTime.add(1, 'day');
            }

            const dur = moment.duration(mEndTime.diff(mStartTime))
            let value = dur.asHours().toFixed(2);

            return value;
        }
    }

    onChargeNotesChanged = (ev) => {
        let val = ev.target.value;
        this.setState({ chargeNotes: val });
    }

    onAddCharge = async () => {
        const { selectedTimesheet,
            selectedChargeType,
            selectedResource,
            chargeStartTime,
            chargeEndTime,
            totalChargeTime,
            chargeNotes } = this.state;

        if (selectedTimesheet == null
            || selectedChargeType == null
            || selectedResource == null
            || totalChargeTime == 0
            || !chargeNotes || chargeNotes.length == 0) {
            toasty.error("Error: All fields must have a value");
            return;
        }

        this.setState({ isAddingCharge: true });

        try {
            let data = {
                timesheetId: selectedTimesheet.value,
                chargeTypeId: selectedChargeType.value,
                chargeTypeUnitsId: selectedChargeType.type,
                resourceId: selectedResource.value,
                start: chargeStartTime,
                end: chargeEndTime,
                value: totalChargeTime,
                notes: chargeNotes
            };

            await util.fetch.post(ApiRoutes.payrollManagement.add(), data);

            toasty.success("Charge Added.");
            this.state.gridOptions.refresh();
        } catch {
            toasty.error("There was an issue adding the charge record.  Please try your request again or contact support for assistance.");
        } finally {
            this.setState({ isAddingCharge: false, showAddChargeModal: false });
        }

    }

    render() {
        const {
            rowData,
            gridOptions,
            rowsSelected,
            dispatchLocations,
            selectedDispatchLocation,
            selectedWeekOf,
            isProcessing,
            showProcessModal,
            showDownloadModal,
            isDownloading,
            showRejectPayrollModal,
            isRejectingPayroll,
            selectedRowRejectionNotes,
            adminRejecting,
            showAddChargeModal,
            isAddingCharge,
            pendingTimesheets,
            paymentChargeTypes,
            billingChargeResources,
            selectedResource,
            selectedChargeType,
            chargeStartTime,
            chargeEndTime,
            totalChargeTime,
            chargeNotes,
            isApproving
        } = this.state;

        //TODO: Eliminate inline styles.

        return (
            <CommonContext.Consumer>
                {
                    value => {
                        const { tenantSettings } = ((value ?? {}).tenant ?? {});

                        if (this.state.loading || !tenantSettings) return (<LinearProgress variant="indeterminate" color="secondary" />);

                        if (!tenantSettings.payrollEnabled)
                            return <NotAuthorizedPage />;

                        return (
                            <PageWrap>
                                <PageHeading>
                                    <FontAwesomeIcon icon={faTasks} className="mr-2 text-muted" />
                                    <span>Payroll: Charges</span>
                                    <span style={{ float: "right", position: "relative", top: "-5px" }}>

                                        {rowsSelected.length == 1 && (
                                            <>
                                                <Button
                                                    size="sm"
                                                    color="success"
                                                    style={{ marginRight: '10px' }}
                                                    onClick={() =>
                                                        this.onApproveButtonClick()
                                                    }
                                                    disabled={
                                                        rowsSelected.length === 0 ||
                                                        isApproving
                                                    }
                                                >
                                                    Approve Selected
                                                </Button>

                                                <Button
                                                    size="sm"
                                                    color="danger"
                                                    style={{ marginRight: '40px' }}
                                                    onClick={() => this.toggleRejectPayrollModal()}
                                                    disabled={rowsSelected.length !== 1}
                                                >
                                                    Reject Selected
                                                </Button>
                                            </>
                                        )}

                                        {rowsSelected.length > 1 && (
                                            <Button
                                                size="sm"
                                                color="success"
                                                style={{ marginRight: '40px' }}
                                                onClick={() =>
                                                    this.onApproveButtonClick()
                                                }
                                                disabled={
                                                    rowsSelected.length === 0 ||
                                                    isApproving
                                                }
                                            >
                                                Approve Selected
                                            </Button>
                                        )}

                                        <Button
                                            size="sm"
                                            color="success"
                                            style={{ marginRight: "10px" }}
                                            onClick={() => this.toggleAddChargeModal()}
                                            disabled={isProcessing}
                                        >
                                            Add Charge
                                        </Button>

                                        <Button
                                            size="sm"
                                            style={{ marginRight: "10px" }}
                                            color="success"
                                            onClick={() => this.toggleProcessModal()}
                                        >
                                            Process
                                        </Button>


                                    {/*    <Button*/}
                                    {/*        size="sm"*/}
                                    {/*        style={{ marginRight: "10px" }}*/}
                                    {/*        color="danger"*/}
                                    {/*        onClick={() => this.onRejectButtonClick()}*/}
                                    {/*        disabled={rowsSelected.length === 0}*/}
                                    {/*    >*/}
                                    {/*        Reject Selected*/}
                                    {/*</Button>*/}

                                    {/*    <Button*/}
                                    {/*        size="sm"*/}
                                    {/*        color="success"*/}
                                    {/*        onClick={() => this.onAcceptButtonClick()}*/}
                                    {/*        disabled={rowsSelected.length === 0}*/}
                                    {/*    >*/}
                                    {/*        Approve Selected*/}
                                    {/*</Button>*/}
                                    </span>
                                </PageHeading>
                                <DataGridToolbar
                                    entity="Payroll"
                                    gridApi={this.state.gridApi}
                                    dataSource={this.state.dataSource}
                                    hideAdd={true}
                                    hideExcelButton={true}
                                    gridOptions={this.state.gridOptions}
                                    serverExport={{ apiPath: ApiRoutes.payrollManagement.excelExport(), filePrefix: 'PayrollChargesSearch' }}
                                />
                                <DataGrid
                                    domLayout={"normal"}
                                    rowData={rowData}
                                    gridOptions={gridOptions}
                                    gridStatus={this.state.gridStatus}
                                />
                                <PayrollCalculationSlideout
                                    ref={this.payrollCalculationRef}
                                    show={this.state.payrollCalculationOpen}
                                    toggleShow={(open) => this.setState({ payrollCalculationOpen: open })}
                                    onClose={this.onPayrollCalculationClosed}
                                />

                                <Modal isOpen={showRejectPayrollModal} toggle={this.toggleRejectPayrollModal}>
                                    <ModalHeader toggle={this.toggleRejectPayrollModal}>Payroll: Reject Timesheet</ModalHeader>
                                    <ModalBody>
                                        {(adminRejecting ?

                                            <small style={{ marginBottom: "20px" }} className='form-text text-danger'>
                                                This administrative action will delete any processed payroll or billing records, and reset the timesheet.  Only administrators will be allowed to adjust and re-process this timesheet.  Please take note of Payroll and Billing implications.
                                            </small>
                                            :

                                            <FormText style={{ marginBottom: "20px" }}>
                                                This action will reject all charges on the associated timesheet from payroll review and remove the timesheet from billing review.
                                            </FormText>
                                        )}
                                        <FormGroup>
                                            <FormLabel required={true} text='Notes'></FormLabel>
                                            <textarea
                                                id="selectedRowRejectionNotes"
                                                name="selectedRowRejectionNotes"
                                                className="form-control"
                                                defaultValue={selectedRowRejectionNotes ?? ''}
                                                onChange={this.onChange}
                                                required
                                                placeholder="Enter notes regarding the rejection."
                                                type="text"
                                                maxLength="500"
                                                rows="5"
                                            />
                                            <small className="text-danger">Notes are required.</small>
                                        </FormGroup>
                                    </ModalBody>
                                    <ModalFooter>
                                        {
                                            isRejectingPayroll &&
                                            <FontAwesomeIcon icon={faCircleNotch} className="fa-spin mr-2" size="sm" />
                                        }
                                        <Button color="primary" disabled={isRejectingPayroll} onClick={this.rejectPayroll}>Ok</Button>{' '}
                                    </ModalFooter>
                                </Modal>

                                <Modal isOpen={showProcessModal} toggle={() => this.toggleProcessModal()}>
                                    <ModalHeader toggle={() => this.toggleProcessModal()}>Process Payroll</ModalHeader>
                                    <ModalBody>
                                        <FormGroup>
                                            <label>Dispatching</label>
                                            <Select
                                                placeholder={'Select Dispatching'}
                                                id="selectedDispatchLocation"
                                                name="selectedDispatchLocation"
                                                styles={CompactSelectStyles}
                                                isClearable={false}
                                                className="react-select"
                                                options={dispatchLocations}
                                                value={(dispatchLocations ?? []).find(x => x.value === selectedDispatchLocation) ?? ''}
                                                onChange={this.onSelectedDispatchLocationChanged}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <label>Week Of</label>
                                            <input
                                                id="weekOf"
                                                name="selectedWeekOf"
                                                className="form-control"
                                                defaultValue={selectedWeekOf ?? ''}
                                                onChange={this.onChange}
                                                type="date"
                                            />
                                        </FormGroup>
                                    </ModalBody>
                                    <ModalFooter>
                                        {
                                            isProcessing &&
                                            <FontAwesomeIcon icon={faCircleNotch} className="fa-spin mr-2" size="sm" />
                                        }
                                        <Button color="primary" disabled={isProcessing} onClick={() => this.onProcessClick()}>Ok</Button>{' '}
                                        <Button color="secondary" onClick={() => this.setState({ showProcessModal: false })}>Cancel</Button>
                                    </ModalFooter>
                                </Modal>

                                <Modal isOpen={showAddChargeModal} toggle={() => this.toggleAddChargeModal()}>
                                    <ModalHeader toggle={() => this.toggleAddChargeModal()}>Add Charge</ModalHeader>
                                    <ModalBody>
                                        <FormGroup>
                                            <label>Timesheet</label>
                                            <Select
                                                placeholder={'Select Timesheet'}
                                                id="selectedChargeTimesheet"
                                                name="selectedChargeTimesheet"
                                                styles={CompactSelectStyles}
                                                isClearable={false}
                                                className="react-select"
                                                options={pendingTimesheets}
                                                onChange={this.onSelectedTimesheetChanged}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <label>Charge Type</label>
                                            <Select
                                                placeholder={'Select Charge Type'}
                                                id="selectedChargeType"
                                                name="selectedChargeType"
                                                styles={CompactSelectStyles}
                                                isClearable={false}
                                                className="react-select"
                                                value={(paymentChargeTypes ?? []).find(x => x.value === selectedChargeType?.value) ?? ''}
                                                options={paymentChargeTypes}
                                                onChange={this.onSelectedChargeTypeChanged}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            {!!selectedChargeType && selectedChargeType.type == ChargeTypeUnits.Hours &&
                                                <label>Employee</label>
                                            }
                                            {!!selectedChargeType && selectedChargeType.type == ChargeTypeUnits.Flat &&
                                                <label>Equipment</label>
                                            }
                                            <Select
                                                placeholder={'Select Resource'}
                                                id="selectedChargeResource"
                                                name="selectedChargeResource"
                                                styles={CompactSelectStyles}
                                                isClearable={false}
                                                className="react-select"
                                                value={(billingChargeResources ?? []).find(x => x.value === selectedResource?.value) ?? ''}
                                                options={billingChargeResources}
                                                onChange={this.onSelectedResourceChanged}
                                            />
                                        </FormGroup>
                                        {!!selectedChargeType && selectedChargeType.type == ChargeTypeUnits.Hours &&
                                            <>
                                                <FormGroup className="payrollstart" style={{ width: '60px' }}>
                                                    <label>Start</label>
                                                    <TimeEntry
                                                        required
                                                        name="chargeStartTime"
                                                        increment={selectedChargeType.increment ?? 0.25}
                                                        value={chargeStartTime}
                                                        onChange={(ev) => this.onTimeEntryStartChanged(ev)}
                                                    />
                                                </FormGroup>
                                                <FormGroup className="payrollend" style={{ width: '60px' }}>
                                                    <label>End</label>
                                                    <TimeEntry
                                                        required
                                                        name="chargeEndTime"
                                                        increment={selectedChargeType.increment ?? 0.25}
                                                        value={chargeEndTime}
                                                        onChange={(ev) => this.onTimeEntryEndChanged(ev)}
                                                    />
                                                </FormGroup>
                                            </>
                                        }

                                        <FormGroup>
                                            <label>Charge</label>
                                            <input
                                                id="chargeValue"
                                                name="chargeValue"
                                                value={totalChargeTime}
                                                disabled={!!selectedChargeType && selectedChargeType.type == ChargeTypeUnits.Hours}
                                                className="form-control"
                                                onChange={(ev) => this.onChargeValueChanged(ev)}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <FormLabel text='Notes'></FormLabel>
                                            <textarea
                                                id="chargeNotes"
                                                name="chargeNotes"
                                                className="form-control"
                                                value={chargeNotes}
                                                onChange={(ev) => this.onChargeNotesChanged(ev)}
                                                placeholder="Enter notes"
                                                type="text"
                                                maxLength="500"
                                                rows="5"
                                            />
                                        </FormGroup>
                                    </ModalBody>
                                    <ModalFooter>
                                        {
                                            isAddingCharge &&
                                            <FontAwesomeIcon icon={faCircleNotch} className="fa-spin mr-2" size="sm" />
                                        }
                                        <Button color="primary" disabled={isAddingCharge} onClick={() => this.onAddCharge()}>Add Charge</Button>{' '}
                                        <Button color="secondary" onClick={() => this.setState({ showAddChargeModal: false })}>Cancel</Button>
                                    </ModalFooter>
                                </Modal>

                                <Modal isOpen={showDownloadModal} toggle={() => this.toggleDownloadModal()}>
                                    <ModalHeader toggle={() => this.toggleDownloadModal()}>Download Payroll</ModalHeader>
                                    <ModalBody>
                                        <FlexCenterRow>
                                            <Button color="success" style={{ marginRight: "10px" }} disabled={isDownloading} onClick={() => this.onDownloadExportClick()}>Export</Button>{' '}
                                            <Button color="warning" disabled={isDownloading} onClick={() => this.onDownloadWeeklyClick()}>Weekly Employee</Button>{' '}
                                        </FlexCenterRow>
                                    </ModalBody>
                                    <ModalFooter>
                                        {
                                            isDownloading &&
                                            <FontAwesomeIcon icon={faCircleNotch} className="fa-spin mr-2" size="sm" />
                                        }
                                        <Button color="secondary" onClick={() => this.setState({ showDownloadModal: false })}>Cancel</Button>
                                    </ModalFooter>
                                </Modal>
                            </PageWrap>
                        );
                    }
                }
            </CommonContext.Consumer>
        );
    }
}

export default withRouter(PayrollManagementIndex);
