export class WorkOrder {
    id = null;
    name = '';
    number = '';
}

export const WorkOrderTypes = {
    Required: 1,
    Optional: 2,
    NotApplicable: 3
}