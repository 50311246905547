import { Address } from '../address/Address';

export class LocationType {
    id = null;
    description = '';
}

export class Location {
    id = null;
    locationName = '';
    description = '';
    code = '';
    website = '';
    types = [];
    typesFlat = '';
    address = new Address();
}

export class LocationContact {
    id = null;
    name = '';
    title = '';
    email = '';
    mobilePhone = '';
    primaryPhone = '';
    jobs = [];
    contacts = [];
    isActive = true;
}

export class LocationContactType {
    id = null;
    description = '';
}
