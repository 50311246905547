import React, { Fragment } from 'react';
import { AppPageForm, FlexCenterRow, /*FlexEndRow,*/ FormDivider, onFieldChange, SubHeading, toasty } from '../common/forms/FormElements';
import { BaseFormViewModel } from '../common/ViewModel';
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import { faSave, faWarehouse } from '@fortawesome/free-solid-svg-icons'
import { FormLabel } from '../common/forms/FormElements';
import { CompanyBillingGroup } from './CompanyBillingGroup';
import { Button, Input, FormGroup, Row, Col } from 'reactstrap';
import { util } from '../Util';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Prompt, withRouter } from 'react-router-dom';
import { handleFormSaveError } from '../common/forms/ValidationError';
import { isEqual } from 'lodash-es';

class CompanyBillingGroupForm extends React.Component {

    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();

        let stateBase = Object.assign(
            {   
                companyBillingGroup: new CompanyBillingGroup(),
                selectedCompanyIds: [],
                companies: []
            },
            new BaseFormViewModel()
        );

        this.state = stateBase;
        this.onSelectChanged = this.onSelectChanged.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);        
    }

    componentDidMount = () => this.populateState();
    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps && (this.props.match.params.id !== (prevProps.match.params ?? {}).id)) {
            this.populateState();
        }            
    }

    async populateState() {
        const companyBillingGroupId = this.props.match.params.id;

        var companyBillingGroup = !!companyBillingGroupId ? await util.fetch.js(ApiRoutes.companyBillingGroups.byId(companyBillingGroupId)) : new CompanyBillingGroup();
        var companies = await util.fetch.js(ApiRoutes.company.invoicing())
        let originalData = util.object.clone(companyBillingGroup);

        this.setState(state => {
            return {
                originalData: originalData,
                companyBillingGroup: companyBillingGroup,
                companies: companies,
                loading: false,
                saving: false
            }
        });
    }

    onSelectChanged = (selection) => {
        let { companyBillingGroup } = this.state;

        companyBillingGroup.companyBillingGroupsIds = selection?.map(x => x.value);

        this.setState({
            companyBillingGroup: companyBillingGroup
        });
    }

    onChange = onFieldChange;
    handleSaveError = (err) => handleFormSaveError(this, err);
    onClearErrors = () => this.setState((state) => { return { errors: {} }; });
    setSaving = (b) => this.setState({ saving: b });

    onSubmit = async e => {
        //Clear any fluent api errors
        this.onClearErrors();
        this.setSaving(true);

        this.props.location.pathname == AppNavPaths.CompanyBillingGroupNew ? this.create() : this.update();
    }

    update = async () => { 

        let { companyBillingGroup } = this.state;

        var resp = await util.fetch.put(ApiRoutes.companyBillingGroups.update(companyBillingGroup.id), companyBillingGroup);

        if (!!resp) {
            this.props.history.push(AppNavPaths.CompanyBillingGroup)
        } else {
            toasty.error('Error saving billing group');
            this.setState({ saving: false });
        }
    }

    create = async () => {

        let { companyBillingGroup } = this.state;
        companyBillingGroup.id = 0;

        var resp = await util.fetch.put(ApiRoutes.companyBillingGroups.update(0), companyBillingGroup);

        if (!!resp) {
            this.props.history.push(AppNavPaths.CompanyBillingGroup)
        } else {
            toasty.error('Error adding billing group');
            this.setState({ saving: false });
        }
    }

    resetForm = () => this.setState({ formValidated: false });

    render() {
        const {
            companyBillingGroup,
            companies,
            originalData,
            errors,
            formValidated,
            saving
        } = this.state;

        let existing = !!companyBillingGroup.id;

        return (
            <Fragment>
                <Prompt
                    when={!saving && !isEqual(originalData, companyBillingGroup)}
                    message='You have unsaved changes, are you sure you want to leave?'
                />
                <AppPageForm
                    formShown={this.context.formIsOpen}
                    formId={"groupForm"}
                    formHeadingIcon={faWarehouse}
                    formHeading={!companyBillingGroup.id ? 'New Company Billing Group' : 'Edit Company Billing Group'}
                    formName={"groupForm"}
                    formRef={this.formRef}
                    onSubmit={this.onSubmit}
                    setIsValidated={(value) => { this.setState({ formValidated: value }) }}
                    isValidated={formValidated}
                    saving={saving}
                    errors={errors}
                    onClearErrors={this.onClearErrors}
                    loading={this.state.loading}
                >
                    <br />
                    <SubHeading>Details</SubHeading>
                    <Row className="pt-3">
                        <Col xl="6" lg="8" md="10" sm="12" className="ml-auto mr-auto">
                            <FormGroup>
                                <FormLabel htmlFor="groupName"
                                    text="Name"
                                    required={true} />
                                <Input id="description"
                                    name="companyBillingGroup.description"
                                    value={companyBillingGroup.description ?? ''}
                                    onChange={this.onChange}
                                    placeholder="Enter Name (max 150 characters)"
                                    maxLength="150"
                                    pattern="[^()/><\][\\\x22,;|]+"
                                    type="text"
                                    required
                                />
                                <small className="invalid-feedback text-danger">Name is required and can only contain the following special characters: hyphens and periods.</small>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel htmlFor="type" text={"Companies"} required />
                                <ValidatedSelect
                                    id="companyBillingGroupsIds"
                                    name="companyBillingGroup.companyBillingGroups"
                                    required
                                    isMulti
                                    options={companies}
                                    value={(companies ?? []).filter(x => (companyBillingGroup.companyBillingGroupsIds ?? []).includes(x.value)) ?? ''} 
                                    onChange={this.onSelectChanged}
                                    validationMessage="A company is required."
                                />
                            </FormGroup>
                        </Col>             
                    </Row>
                    <FormDivider /> 
                                       
                    <FlexCenterRow className="mb-3">
                        <Button
                            disabled={!!this.state.saving}
                            size="sm"
                            type="submit"
                            color="primary"
                            name="groupForm">
                            <FontAwesomeIcon
                                className="mr-2"
                                icon={faSave} />
                            {!!this.state.saving
                                ? 'Saving...'
                                : (!existing ? 'Save New group' : 'Save')
                            }
                        </Button>
                        <Button
                            disabled={!!this.state.saving}
                            size="sm"
                            type="button"
                            color="secondary"
                            className="ml-3"
                            onClick={() => this.props.history.push(AppNavPaths.CompanyBillingGroup)}
                        >
                            <span className="mr-2 fa fa-long-arrow-alt-left"></span>
                            Back to Groups
                        </Button>
                    </FlexCenterRow>

                </AppPageForm>               
            </Fragment>
        );

    }
}
export default withRouter(CompanyBillingGroupForm);