import { faWaveSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LinearProgress } from '@material-ui/core';
import React from 'react';
import { withRouter } from 'react-router-dom';
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import { util } from '../Util';
import authService from '../api-authorization/AuthorizeService';
import { PageHeading, PageWrap,  onReactSelectChanged, toasty } from '../common/forms/FormElements';
import { Container, Row, Col, InputGroup, InputGroupText, Input, Button, CardGroup } from 'reactstrap';
import TimesheetDashboardTile from './TimesheetDashboardTile';
import Select from 'react-select'
import * as moment from 'moment';
import { NotAuthorizedPage } from '../status/StatusCodes';

class TimesheetDashboardIndex extends React.Component {

    static contextType = CommonContext;

    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            dispatchLocations: [],
            dispatchFilter: 0,
            FromDate: '',
            ToDate: '',
            dashboardData: {
                TimesheetManagementNotSubmitted: 0,
                TimesheetManagementRejected: 0,
                TimesheetManagementBillPayRejected: 0,
                BillingChargesPending: 0,
                BillingChargesCategoriesPending: 0,
                InvoiceNew: 0,
                InvoiceDraft: 0,
                InvoiceApproved: 0,
                LegacyBillingPending: 0,
                LegacyBillingApproved: 0
            }
        };

        this.onChange = this.onChange.bind(this);
        this.onSelectChanged = this.onSelectChanged.bind(this);
    }

    onChange = function(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    onSelectChanged = onReactSelectChanged;

    componentDidMount = () => {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
        this.setState = (state, callback) => { return };
    }

    populateState = async () => {

        const isAuthenticated = await authService.isAuthenticated();

        if (!!isAuthenticated) {

            let start = moment(moment().subtract(61, 'days')).format('YYYY-MM-DD');
            let end = moment(moment().subtract(1, 'days')).format('YYYY-MM-DD');

            var [dispatchLocations, dashData] = await Promise.all([
                util.fetch.js(ApiRoutes.typeAheads.dispatchLocations())
                , util.fetch.post(
                    ApiRoutes.timesheet.timesheetDashboard()
                    , {
                        DispatchLocationId: 0,
                        Date: {
                            DateFrom: start,
                            DateTo: end,
                            FilterType: 'date',
                            Type: 'inRange'
                        }
                    }
                    , util.fetch.format.json
                )
            ]);

            await this.setState({
                loading: false,
                dispatchLocations: dispatchLocations,
                dashboardData: dashData,
                ToDate: end,
                FromDate: start
            });
        }
    }

    onDashboardSearch = async () => {
        await this.setState({ loading: true });

        let { dispatchFilter, FromDate, ToDate } = { ...this.state };

        try {
            let [dashdata] = await Promise.all([
                util.fetch.post(
                    ApiRoutes.timesheet.timesheetDashboard()
                    , {
                        DispatchLocationId: dispatchFilter,
                        Date: {
                            DateFrom: FromDate,
                            DateTo: ToDate,
                            FilterType: 'date',
                            Type: 'inRange'
                        }
                    }
                    , util.fetch.format.json
                )
            ]);
            await this.setState({ dashboardData: dashdata});
        } catch (ex) {
            toasty.error('Error exporting data: ' + ex);
        } finally {
            await this.setState({ loading: false });
        }
    };

    render() {

        if (this.state.loading) return <LinearProgress variant="indeterminate" color="secondary" />

        const { tenantSettings } = ((this.context ?? {}).tenant ?? {});

        if (!tenantSettings || !tenantSettings.timesheetDashboardEnabled) return <NotAuthorizedPage />

        let { dashboardData, dispatchLocations } = { ...this.state };
        
        let timesheetManagementCounts =
        {
            Workflow: "Timesheet Management",
            url: AppNavPaths.TimesheetManagement,
            Counts: [
                {
                    className: "col-12 mr-1 badge " + (dashboardData.timesheetManagementNotSubmitted === 0 ? "badge-success p-2" : "badge-warning p-2"),
                    name: "Not Submitted",
                    count: dashboardData.timesheetManagementNotSubmitted
                },
                {
                    className: "col-12 mr-1 badge " + (dashboardData.timesheetManagementRejected === 0 ? "badge-success p-2" : "badge-danger p-2"),
                    name: "Rejected",
                    count: dashboardData.timesheetManagementRejected
                },
                {
                    className: "col-12 mr-1 badge " + (dashboardData.timesheetManagementSubmitted === 0 ? "badge-success p-2" : "badge-warning p-2"),
                    name: "Submitted",
                    count: dashboardData.timesheetManagementSubmitted
                },
                {
                    className: "col-12 mr-1 badge " + (dashboardData.timesheetManagementBillPayRejected === 0 ? "badge-success p-2" : "badge-danger p-2"),
                    name: "Bill Pay Rejected",
                    count: dashboardData.timesheetManagementBillPayRejected
                },
            ]
        };
        let completeBillingChargesCounts = {
            Workflow: "Billing: Charges",
            url: AppNavPaths.BillingCharge,
            Counts: [
                {
                    className: "col-12 mr-1 badge " + (dashboardData.billingChargesPending === 0 ? "badge-success p-2" : "badge-warning p-2"),
                    name: "Pending",
                    count: dashboardData.billingChargesPending
                }
            ]
        };
        let completeBillingCategoriesCounts = {
            Workflow: "Billing: Charge Categories",
            url: AppNavPaths.BillingChargeCategory,
            Counts: [
                {
                    className: "col-12 mr-1 badge " + (dashboardData.billingChargesCategoriesPending === 0 ? "badge-success p-2" : "badge-warning p-2"),
                    name: "Pending",
                    count: dashboardData.billingChargesCategoriesPending
                }
            ]
        };
        let invoicingCounts = {
            Workflow: "Invoice",
            url: AppNavPaths.InvoiceGeneration,
            Counts: [
                {
                    className: "col-12 mr-1 badge " + (dashboardData.invoiceNew === 0 ? "badge-success p-2" : "badge-warning p-2"),
                    name: "New",
                    count: dashboardData.invoiceNew
                },
                {
                    className: "col-12 mr-1 badge " + (dashboardData.invoiceDraft === 0 ? "badge-success p-2" : "badge-warning p-2"),
                    name: "Draft",
                    count: dashboardData.invoiceDraft
                },
                {
                    className: "col-12 mr-1 badge badge " + (dashboardData.invoiceApproved === 0 ? "badge-success p-2" : "badge-warning p-2"),
                    name: "Approved",
                    count: dashboardData.invoiceApproved
                }
            ]
        };
        let exportOnlyBillingCounts = {
            Workflow: "Billing",
            url: AppNavPaths.Billing,
            Counts: [
                {
                    className: "col-12 mr-1 badge " + (dashboardData.legacyBillingPending === 0 ? "badge-success p-2" : "badge-warning p-2"),
                    name: "Pending",
                    count: dashboardData.legacyBillingPending
                },
                {
                    className: "col-12 mr-1 badge " + (dashboardData.legacyBillingApproved === 0 ? "badge-success p-2" : "badge-warning p-2"),
                    name: "Approved",
                    count: dashboardData.legacyBillingApproved
                }
            ]
        };

        return (
            <>
                <PageWrap>
                    <PageHeading>
                        <FontAwesomeIcon icon={faWaveSquare} className="mr-2 text-muted" />
                        <span>Timesheet Dashboard</span>
                    </PageHeading>

                    <Container id="workflowStatuses">
                        <Row>
                            <Col>
                                <Container>
                                    <Row>
                                        <InputGroup className="mb-3">
                                            <InputGroupText className="col-2" id="fromdate-addon1">From</InputGroupText>
                                            <Input aria-describedby="fromdate-addon1"
                                                type="date"
                                                name="FromDate"
                                                value={this.state.FromDate}
                                                onChange={this.onChange}
                                            />
                                        </InputGroup>
                                    </Row>
                                    <Row>
                                        <InputGroup className="mb-3">
                                            <InputGroupText className="col-2" id="todate-addon1">To</InputGroupText>
                                            <Input aria-describedby="todate-addon1"
                                                type="date"
                                                name="ToDate"
                                                value={this.state.ToDate}
                                                onChange={this.onChange}
                                            />
                                        </InputGroup>
                                    </Row>
                                    <Row>
                                        <InputGroup className="mb-3">
                                            <InputGroupText className="col-2" id="dispatch-addon1">Dispatch Location</InputGroupText>
                                            <div className="react-select form-control p-0">
                                                <Select aria-describedby="dispatch-addon1"
                                                    name="dispatchFilter"
                                                    onChange={this.onSelectChanged}
                                                    options={dispatchLocations}
                                                    isClearable={true}
                                                />
                                            </div>
                                        </InputGroup>
                                    </Row>
                                    <Row>
                                        <Button onClick={this.onDashboardSearch}>Refresh</Button>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                        <CardGroup >
                            <TimesheetDashboardTile
                                url={timesheetManagementCounts.url}
                                Workflow={timesheetManagementCounts.Workflow}
                                Counts={timesheetManagementCounts.Counts}
                            ></TimesheetDashboardTile>
                            <TimesheetDashboardTile
                                url={exportOnlyBillingCounts.url}
                                Workflow={exportOnlyBillingCounts.Workflow}
                                Counts={exportOnlyBillingCounts.Counts}
                            ></TimesheetDashboardTile>
                            <TimesheetDashboardTile
                                url={completeBillingChargesCounts.url}
                                Workflow={completeBillingChargesCounts.Workflow}
                                Counts={completeBillingChargesCounts.Counts}
                            ></TimesheetDashboardTile>
                            <TimesheetDashboardTile
                                url={completeBillingCategoriesCounts.url}
                                Workflow={completeBillingCategoriesCounts.Workflow}
                                Counts={completeBillingCategoriesCounts.Counts}
                            ></TimesheetDashboardTile>
                            <TimesheetDashboardTile
                                url={invoicingCounts.url}
                                Workflow={invoicingCounts.Workflow}
                                Counts={invoicingCounts.Counts}
                            ></TimesheetDashboardTile>
                        </CardGroup>
                    </Container>
                </PageWrap>
            </>
        );

    }
}

export default withRouter(TimesheetDashboardIndex);