import { faUserClock } from '@fortawesome/fontawesome-free-solid';
import React from 'react';
import CommonContext, { ApiRoutes } from '../Common';
import {
    Button,
    FormGroup
} from 'reactstrap';
import {
    FormLabel,
    FlexCenterRow,
    toasty,
    onReactSelectChanged,
    FlexStartRow,
    FormCheckbox,
} from '../common/forms/FormElements';
import SlideForm from '../common/forms/SlideForm';
import { BaseFormViewModel } from '../common/ViewModel';
import { util } from '../Util';
import { handleFormSaveError } from '../common/forms/ValidationError';
import { SignatureReviewTable } from './SignatureReviewTable';
import './ClientReview.scss';
import EmailRecipients from '../common/forms/EmailRecipients';
import { ClientTimesheetSignature } from './ClientTimesheetSignature';

export const TimesheetSignatureType = {
    ClientForeman: 1,
    ClientOtherAuthorized: 2,
    ClientNoSignature: 3,
    Employee: 4,
    EmployeeNoSignature: 5
};

export default class ClientReview extends React.Component {
    static contextType = CommonContext;
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.signatureRef = React.createRef();
        let stateBase = Object.assign(
            {
                review: {},
                formValidated: false,
                isOpen: false,
                signatureTypes: [],
                currentEmailAddress: '',
                specificEmailAdds: [],
                sigIsComplete: false
            },
            new BaseFormViewModel()
        );
        this.baseState = stateBase;
        this.state = stateBase;
        this.resetForm = this.resetForm.bind(this);
        this.handleSaveError = this.handleSaveError.bind(this);
    }

    componentDidUpdate = (e) => {
    }

    async populateState() {
        var [signatureTypes, missingSigReasons] = await Promise.all([
            util.fetch.js(ApiRoutes.typeAheads.clientSignatureTypes()),
            util.fetch.js(ApiRoutes.timesheetReview.missingSignatureReasons())
        ]);

        this.setState({
            signatureTypes: signatureTypes,
            missingSignatureReasons: missingSigReasons
        });
    }

    onSelectChanged = onReactSelectChanged;

    onChangeNotes = (e) => {
        let { review } = { ...this.state };
        review.notes = e.target.value;
        this.setState({ review: review })
    }

    onSignatureTypeChanged = (signatureType) => {
        let { review, sigIsComplete } = { ...this.state };

        review.signatureTypeId = signatureType.value;

        if (review.signatureTypeId === TimesheetSignatureType.ClientNoSignature) {
            review.reviewSignature.signatureData = null;
            sigIsComplete = false;
        }

        this.setState({ review: review, sigIsComplete: sigIsComplete });
    }

    onMissingSigReasonChanged = reason => {
        let { review } = { ...this.state };
        review.missingSigReasonId = reason.value;
        this.setState({ review: review });
    }

    handleSaveError = (err) => handleFormSaveError(this, err);

    onClose = () => {
        this.resetForm();
        this.props.toggleShow(false);
        this.context.setFormOpened(false);
        this.props.onClose();
    }

    onDelete = async e => { }

    resetForm = () => this.setState({
        formValidated: false
    });

    onSubmit = async () => {

        let { review } = { ...this.state };
        const { tenantSettings } = { ...this.props };

        if (review.signatureTypeId !== TimesheetSignatureType.ClientNoSignature) {
            if (!
                //Make sure there is sig data.
                ((!!review.reviewSignature.signatureData
                    //Make sure it's not just the mime type prefix.
                    && review.reviewSignature.signatureData !== "data:image/png;base64,") && (review.newClientSignature || review.reuseClientSignature))
            ) {
                toasty.error('A signature is required.');
                this.setState({ formValidated: true });
                return;
            }
        } else {
            //no signature notes or reason is required. 
            if (!tenantSettings.enablePredefinedTimesheetMissingSigReason && !review.notes) {
                toasty.error('Provide a reason there was no signature.');
                this.setState({ formValidated: true });
                return;
            }
            if (!!tenantSettings.enablePredefinedTimesheetMissingSigReason && !review.missingSigReasonId) {
                toasty.error('Reason for no signature - a selection is required in the drop down.');
                this.setState({ formValidated: true });
                return;
            }
        }

        try {
            await util.fetch.post(ApiRoutes.timesheetReview.update(), review);
            toasty.success('Saved');
            this.onClose();
        } catch (err) {
            toasty.error(err.handledError.message)
        }
    }

    setRef = (ref) => {
        // keep a reference to the dom ref as an instance property
        this.ref = ref;
        // give the dom ref to react-beautiful-dnd
        //this.props.innerRef(ref);
    };

    open = async (timesheetId) => {
        this.setState(this.baseState);
        await this.populateState();

        let review = null;

        this.resetForm();

        await this.setState({ loading: true });

        if (!!timesheetId) {
            review = await util.fetch.js(ApiRoutes.timesheetReview.clientByTimesheetId(timesheetId));
        } else {
            review = null;//
        }

        this.props.toggleShow(true);
        this.context.setFormOpened(true);

        await this.setState({ review: review, loading: false });
    }

    onClose = () => {
        this.resetForm();
        this.props.toggleShow(false);
        this.context.setFormOpened(false);
        this.props.onClose();
    }

    onDelete = async e => {

    }

    resetForm = () => {
        this.setState({
            formValidated: false
        });
    }

    onEmailChanged = e => {
        let val = e.currentTarget.value;
        this.setState({ currentEmailAddress: val })
    }

    onEmailAdded = e => {
        const { currentEmailAddress, review } = { ...this.state };
        if (!!currentEmailAddress) {
            let emailAddress = currentEmailAddress.trim();
            var match = util.validation.email(emailAddress);
            if (!!match) {
                if (!(review.emailAddresses ?? []).length)
                    review.emailAddresses = [];
                if (!review.emailAddresses.includes(emailAddress))
                    review.emailAddresses.push(emailAddress);
                this.setState({ review: { ...review }, currentEmailAddress: null });
            } else {
                toasty.error('Please enter a single valid email address.')
            }
        }
    }

    onEmailRemoved = (selected) => {
        let { review } = { ...this.state };
        const inx = review.emailAddresses.findIndex(ea => ea === selected);
        review.emailAddresses.splice(inx, 1);
        this.setState({ review: review });
    }

    onEmailForemanChanged = e => {
        let include = e.target.checked;
        let { review } = { ...this.state };
        review.emailForeman = include;
        this.setState({ review: review });
    }

    onReuseSignatureChanged = e => {
        let include = e.target.checked;
        let { review } = { ...this.state };
        review.reuseClientSignature = include;
        this.setState({ review: review})
    }

    onSigSavedCallback = sigData => {
        let { review } = { ...this.state };
        review.reviewSignature.signatureData = sigData;
        review.newClientSignature = true;
        this.setState({ review: review, sigIsComplete: true });
    }
    render = () => {
        const {
            review,
            formValidated,
            validationMessage,
            currentEmailAddress,
            sigIsComplete,
        } = this.state;

        const {
            tenantSettings
        } = { ...this.props };

        if (!review)
            return null;

        const allEmail = [...new Set([].concat(...[review.emailAddresses ?? [], review.contractEmailAddresses ?? []]))]

        return (
            <>
                <SlideForm
                    size="col-xl-6 col-lg-6 col-md-12 col-sm-12"
                    loading={this.state.loading}
                    show={this.props.show}
                    id={"timesheetReview"}
                    formIcon={faUserClock}
                    formTitle="Client Review / Signature"
                    ref={this.formRef}
                    setIsValidated={(value) => { this.setState({ formValidated: value }) }}
                    isValidated={formValidated}
                    onClose={this.onClose}
                    onDelete={this.onDelete}
                    errors={this.state.errors}
                    onClearErrors={this.onClearErrors}
                    validationMessage={validationMessage}
                >
                    <FormGroup>
                        <span className="h5 font-weight-bold text-center text-dark mb-0 pb-0">Summary of Billable Charges</span>
                    </FormGroup>

                    <FormGroup>
                        <SignatureReviewTable timeCharges={(review ?? {}).reviewTable} />
                    </FormGroup>
                    {
                        !!(review ?? {}).workOrderNumber &&
                        <FormGroup>
                            <FormLabel text={`Work Order`} />
                            <span className="ml-3 text-danger font-weight-bold">{`[${review.workOrderNumber}]`}</span>
                        </FormGroup>
                    }
                    {
                        !!tenantSettings?.timesheetEmailEnabled &&
                        <FormGroup>
                            <FormLabel text={`Email Timesheet`} />
                            <small className="pl-2 text-success">
                                {
                                    !!this.props.readOnly
                                        ? `Enter addresses at which you would like to receive a copy of this timesheet below.`
                                        : `The below email addresses will receive a copy of the time sheet:`
                                }
                            </small>
                            <FlexStartRow className="pb-1">
                                <FormCheckbox
                                    labelClass="text-danger text-small"
                                    className="mt-2 pl-2 mb-1"
                                    id={"emailForeman"}
                                    checked={review.emailForeman ?? false}
                                    disabled={!!review.isComplete}
                                    readOnly={false}
                                    onChange={this.onEmailForemanChanged}
                                    labelText={"Include Foreman"}
                                />
                            </FlexStartRow>
                            <EmailRecipients
                                showForemanCheckbox={true}
                                currentEmailAddress={currentEmailAddress}
                                onEmailAdded={this.onEmailAdded}
                                onEmailChanged={this.onEmailChanged}
                                onEmailRemoved={this.onEmailRemoved}
                                emailAddresses={allEmail}
                                readOnlyAddresses={review.contractEmailAddresses}
                                readOnly={!!review.isComplete}
                            />
                        </FormGroup>
                    }

                    {this.context?.user.isImpersonating && !!review.reviewSignature && !!review.reviewSignature.signatureData && !review.isComplete && (
                        <FormCheckbox
                            labelClass="text-danger text-small"
                            className="mt-2 pl-2 mb-3"
                            id={"reuseClientSignature"}
                            readOnly={false}
                            onChange={this.onReuseSignatureChanged}
                            labelText={"[Admin] Re-Use Signature"}
                        />
                    )}
                    {!(review.reuseClientSignature) &&
                        <ClientTimesheetSignature
                            review={review}
                            onSignatureTypeChanged={this.onSignatureTypeChanged}
                            onMissingSigReasonChanged={this.onMissingSigReasonChanged}
                            onChangeNotes={this.onChangeNotes}
                            onSigSavedCallback={this.onSigSavedCallback}
                            signatureRef={this.signatureRef}
                            tenantSettings={tenantSettings}
                            sigIsComplete={sigIsComplete}
                        />
                    }
                    {
                        !review.isComplete &&
                        <FlexCenterRow className="mt-2">
                            <Button className="btn mr-2" color="primary" onClick={this.onSubmit}>Save</Button>
                        </FlexCenterRow>
                    }
                </SlideForm>
            </>
        );
    }
}