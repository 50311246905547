export class QuickBooksInventoryItemDTO {
    id = 0;
    name = "";
    salesAccountId = 0;
    salesAccountName = "";
    companyChargeTypeBillingRates = [];
    contractChargeTypeBillingRates = [];
    chargeTypeBillingRates = [];
}

export class QuickbooksInvnetoryItemSalesAccountDTO {
    tenantId = 0;
    id = 0;
    name = "";
}

export class QuickBooksInventoryItemTableDTO {
    id = 0;
    name = "";
    //Comma delimited list of unique chargeTypes item is associated with
    chargeTypes = "";
    //Comma delimited list of unique Companies the item is assoicated with
    companies = "";
    //Comma delimited list of unique Contract numbers the item is associated with
    contracts = "";
}

export class QBCompanyChargeTypeRateDTO {
    id = 0;
    company = "";
    chargeType = "";
    rate = 0.0;
    overtimeRate = 0.0;
    alternativeRate = 0.0;
    effectiveDate = null;
    expirationDate = null;
    isActive = false;
}

export class QBContractChargeTypeRateDTO {
    id = 0;
    billingCompany = "";
    company = "";
    operationsCenterCompany = "";
    contractNumber = "";
    chargeType = "";
    rate = 0.0;
    overtimeRate = 0.0;
    alternativeRate = 0.0;
    effectiveDate = null;
    expirationDate = null;
    isActive = false;
}

export class QBChargeTypeRateDTO {
    id = 0;
    chargeType = "";
    rate = 0.0;
    overtimeRate = 0.0;
    alternativeRate = 0.0;
    effectiveDate = null;
    expirationDate = null;
    isActive = false;
}