import { faTasks } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments } from '@fortawesome/free-solid-svg-icons';
import './InvoiceGenerationIndex.scss';
import { LinearProgress } from '@material-ui/core';
import React from 'react';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import { CompactSelectStyles } from '../common/forms/ValidatedSelect';
import SlideForm from '../common/forms/SlideForm';
import BillingIssuesWidget from '../widgets/BillingIssuesWidget';
import CommonContext, { ApiRoutes, ApplicationPermissions } from '../Common';
import {
    getFilterModel,
    createDataSource,
    createGridOptions,
    DataGrid,
    indexCellRenderer,
    LinkCellRenderer,
    IconCellRenderer,
    VariableLinkCellRenderer,
    TextFilterDefaults,
    DateFilterDefaults,
} from '../common/dataGrid/DataGrid';
import {
    Button,
    FormGroup,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';
import {
    SimpleTable,
    FormCheckbox,
    SmallButton,
    PageHeading,
    PageWrap,
    toasty,
    FormLabel,
    onFieldChange,
    FlexCenterRow,
} from '../common/forms/FormElements';
import TimesheetDetails from '../timesheet/TimesheetDetails';
import InvoiceSlideout from '../invoice/InvoiceSlideout';
import { faCircleNotch } from '@fortawesome/fontawesome-free-solid';
import { handleFormSaveError } from '../common/forms/ValidationError';
import { util } from '../Util';
import CustomCircularProgress from '../common/CustomCircularProgress';
import { NotAuthorizedPage } from '../status/StatusCodes';
import { BillingStatus } from '../billing/Billing';
import { getTenantUserProfile } from '../common/TenantUserProfile';

class InvoiceGenerationIndex extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.timesheetDetailsRef = React.createRef();
        this.invoiceSlideoutRef = React.createRef();
        this.billingIssuesSlideoutRef = React.createRef();

        const yesNo = [
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
        ];

        this.state = {
            loading: true,
            rowData: [],
            rowsSelected: [],
            timesheetDetailsOpen: false,
            invoiceSlideoutOpen: false,
            selectedDetails: [],
            showRejectTimesheetModal: false,
            showRejectBillingTimesheetModal: false,
            showMoveTimesheetModal: false,
            isMovingTimesheet: false,
            isRejectingTimesheet: false,
            isRejectingBillingTimesheet: false,
            adminRejecting: false,
            selectedRowRejectionNotes: '',
            loadingReport: false,
            showExportModal: false,
            isExporting: false,
            yesNo: yesNo,
            isApproving: false,
            isInvoicing: false,
            createJob: false,
            showBillingIssuesSlideout: false,
            billingIssueMessages: [],
            filterModel: null,
            invoicedTimesheetsSelected: false,
        };

        this.onRowClicked = this.onRowClicked.bind(this);
        this.onCellClicked = this.onCellClicked.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onRowSelected = this.onRowSelected.bind(this);
    }

    componentDidMount = () => this.populateState();
    componentWillUnmount = () =>
        (this.setState = (state, callback) => {
            return;
        });

    populateState = async () => {
        let [tenantUserProfile, dispatchLocations, billingStatuses] = await Promise.all([
            getTenantUserProfile(),
            util.fetch.js(ApiRoutes.typeAheads.dispatchLocations()),
            util.fetch.js(ApiRoutes.typeAheads.billingStatuses()),
        ]);

        const { userPermissions } = { ...tenantUserProfile };
        const canMoveTimesheetContract = userPermissions.includes(ApplicationPermissions.billing_tscontract_update);

        let initialListFilters = {
            initialDispatchId: '',
            initialCustomerCompanyId: 0,
            initialOperationsCenterId: 0,
            initialBillingStatusId: 6,
            initialPayrollStatusId: 0,
            initialHasOverride: null,
        };

        billingStatuses = billingStatuses.filter(
            (x) =>
                x.value == 6 ||
                x.value == 4 ||
                x.value == 8 ||
                x.value == 10 ||
                x.value == 9
        );
        initialListFilters =
            this.getInitialListFiltersFromCache(initialListFilters);

        const gridOptions = createGridOptions(this);
        let context = this;

        //https://www.ag-grid.com/documentation/javascript/row-selections#checkbox-selection
        //One column must have "checkboxSelection" set to true.
        //Note: headerCheckboxSelection not available with infinite scroll.
        gridOptions.rowSelection = 'multiple';

        gridOptions.postProcessData = this.transformRowData;
        gridOptions.onRowSelected = this.onRowSelected;

        gridOptions.rowClassRules = {
            'ag-row-warning': (params) => {
                return (params.data ?? {}).hasOverride === 'Yes';
            },
            'ag-row-danger': (params) => {
                return (params.data ?? {}).isEmergency === true;
            },
        };

        gridOptions.components = {
            selectFilter: DataGridSelectFilter,
            selectFloatingFilter: DataGridSelectFloatingFilter,
            nameRenderer: LinkCellRenderer,
            iconRenderer: IconCellRenderer,
            variableLinkRenderer: VariableLinkCellRenderer,
        };
        gridOptions.onRowClicked = this.onRowClicked;

        let dispatchFilterParams = {
                suppressFilterButton: true,
                options: dispatchLocations,
                optionsLabel: 'label',
                optionsValue: 'value',
                initialFilterValue: initialListFilters.dispatchId,
            },
            billingStatusFilterParams = {
                suppressFilterButton: true,
                options: billingStatuses,
                optionsLabel: 'label',
                optionsValue: 'value',
                initialFilterValue: initialListFilters.initialBillingStatusId,
            },
            yesNoFilterParams = {
                suppressFilterButton: true,
                options: this.state.yesNo,
                optionsLabel: 'label',
                optionsValue: 'value',
                initialFilterValue: initialListFilters.hasOverride,
            };

        gridOptions.columnDefs = [
            {
                headerName: '',
                valueGetter: 'node.id',
                sortable: false,
                hide: true,
                flex: 1,
                maxWidth: 35,
                cellRenderer: indexCellRenderer,
            },
            {
                colId: 'JobDate',
                headerName: 'Date',
                field: 'date',
                sortable: true,
                flex: 1.5,
                sort: { direction: 'asc', priority: 0 },
                filter: 'agDateColumnFilter',
                filterParams: DateFilterDefaults,
                //floatingFilterComponentParams: {
                //    suppressFilterButton: true,
                //}
            },
            {
                colId: 'customerName',
                headerName: 'Customer Name',
                field: 'customerName',
                sortable: true,
                flex: 2,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'ContractNumber', //leave this, it doesnt map to an ef prop but is triggered for the sort on the dynamic field.
                headerName: 'Contract #',
                field: 'contractNumber',
                sortable: true,
                flex: 1.5,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'DispatchLocationId',
                headerName: 'Dispatching',
                field: 'dispatchOfficeName',
                sortable: true,
                flex: 2,
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: dispatchFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: dispatchFilterParams,
            },
            {
                colId: 'Timesheet.TimesheetNumber',
                headerName: 'Timesheet #',
                field: 'timesheetNumber',
                sortable: true,
                flex: 2,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'Timesheet.WorkOrderNumber',
                headerName: 'Work Order',
                field: 'workOrderNumber',
                sortable: true,
                flex: 1.5,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'JobLocationAddress',
                headerName: 'Job Address',
                field: 'jobAddress',
                sortable: false,
                flex: 2,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                headerName: 'Flags',
                valueGetter: 'node.id',
                sortable: false,
                hide: true,
                flex: 1,
                maxWidth: 35,
                cellRenderer: indexCellRenderer,
            },
            {
                colId: 'Foreman',
                headerName: 'Foreman',
                field: 'foreman',
                sortable: true,
                flex: 2,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'startTime',
                headerName: 'Start Time',
                field: 'startTime',
                sortable: false,
                flex: 1,
            },
            {
                colId: 'Invoice.InvoiceNumber',
                headerName: 'Invoice #',
                field: 'invoiceNumber',
                sortable: true,
                flex: 2,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'BillingStatusId',
                headerName: 'Status',
                field: 'billingStatus',
                sortable: true,
                flex: 1,
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: billingStatusFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: billingStatusFilterParams,
            },
            {
                colId: 'HasOverride',
                headerName: 'Billing Override',
                field: 'hasOverride',
                sortable: true,
                flex: 1.5,
                tooltipField: 'status',
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: yesNoFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: yesNoFilterParams,
            },
            {
                colId: 'Id',
                flex: 0,
                maxWidth: 75,
                headerName: 'Billing Issue',
                sortable: false,
                cellRenderer: (params) => {
                    if (params.data) {
                        return (
                            <SmallButton
                                title="Reject"
                                type="button"
                                onClick={() => {
                                    this.onBillingIssuesSlideout(
                                        params.data.timesheetId
                                    );
                                }}
                            >
                                <i className={`fa fa-comments fa-md mr-2`} />
                            </SmallButton>
                        );
                    }

                    return null;
                },
            },
            {
                colId: 'Id',
                flex: 0,
                maxWidth: 75,
                headerName: 'Hold',
                sortable: false,
                cellRenderer: (params) => {
                    if (
                        params.data &&
                        params.data.billingStatusId !==
                            BillingStatus.IssueReject &&
                        params.data.billingStatusId !==
                            BillingStatus.PendingReview
                    ) {
                        if (params.data.billingStatusId === 8) {
                            return (
                                <SmallButton
                                    title="Hold"
                                    type="button"
                                    onClick={() => {
                                        this.removeOnHold(params.data.id);
                                    }}
                                >
                                    <i className={`fa fa-unlock fa-md mr-2`} />
                                </SmallButton>
                            );
                        } else {
                            return (
                                <SmallButton
                                    title="Hold"
                                    type="button"
                                    onClick={() => {
                                        this.setOnHold(params.data.id);
                                    }}
                                >
                                    <i className={`fa fa-lock fa-md mr-2`} />
                                </SmallButton>
                            );
                        }
                    }

                    return null;
                },
            },
            {
                colId: 'Id',
                flex: 0,
                maxWidth: 75,
                headerName: 'View',
                sortable: false,
                cellRenderer: 'iconRenderer',
                cellRendererParams: {
                    clicked: this.openTimesheetDetails,
                    idField: 'timesheetId',
                    iconClass: 'fa-eye',
                },
            },
            {
                colId: 'Id',
                flex: 0,
                maxWidth: 75,
                headerName: 'Print',
                sortable: false,
                cellRenderer: 'iconRenderer',
                cellRendererParams: {
                    clicked: this.printTimesheet,
                    idField: 'timesheetId',
                    iconClass: 'fa-print',
                },
            },
            {
                colId: 'SelectionPlaceholder',
                headerName: '',
                field: 'selectionPlaceholder',
                sortable: false,
                maxWidth: 75,
                checkboxSelection: function (params) {
                    if (params.data) {
                        return params.data.billingStatus === 'Ready To Invoice';
                    }

                    return null;
                },
            },
        ];

        gridOptions.onFilterChanged = (params) => {
            let filterModel = gridOptions.api.getFilterModel();
            context.setState({ filterModel: filterModel });
        };

        gridOptions.onSortChanged = (params) => {};

        gridOptions.onFirstDataRendered = (params) => {};

        gridOptions.postProcessData = (data) => {
            context.setState({
                rowData: data,
                scoreboardData: { ...data.counts } ?? null,
            });

            return { ...data.result };
        };

        gridOptions.onGridReady = (params) => {
            params.api.setDatasource(context.state.dataSource);
            context.setState({ gridApi: params.api });
        };

        const dataSource = createDataSource(
            ApiRoutes.invoiceGeneration.search(),
            gridOptions
        );

        this.setState({
            loading: false,
            gridOptions: gridOptions,
            dataSource: dataSource,
            isRejectingTimesheet: false,
            canMoveTimesheetContract: canMoveTimesheetContract
        });
    };

    // Used to load from cache for the list types.
    getInitialListFiltersFromCache(initialFilters) {
        let json = sessionStorage.getItem('billing_gridFilterSettings');

        if (!!json) {
            let obj = JSON.parse(json);

            initialFilters.dispatchId = !!obj.DispatchLocationId
                ? obj.DispatchLocationId.value
                : '';
            initialFilters.hasOverride = !!obj.HasOverride
                ? obj.HasOverride.value
                : '';
            initialFilters.companyId = !!obj.CustomerCompanyId
                ? parseInt(obj.CustomerCompanyId.value)
                : 0;
            initialFilters.operationsCenterId = !!obj.OperationsCenterId
                ? parseInt(obj.OperationsCenterId.value)
                : 0;
            initialFilters.billingStatusId = !!obj.BillingStatusId
                ? parseInt(obj.BillingStatusId.value)
                : 0;
            initialFilters.payrollStatusId = !!obj[
                'Timesheet.PayrollStatus.Description'
            ]
                ? parseInt(obj['Timesheet.PayrollStatus.Description'].value)
                : 0;
        }

        return initialFilters;
    }

    //https://stackoverflow.com/questions/44263350/count-number-of-selected-rows-in-ag-grid
    //Possibly use lodash dequeue?
    onRowSelected(e) {
        let { invoicedTimesheetsSelected } = this.state;

        const rs = e.api.getSelectedRows();

        var invoicedItems = rs.filter((x) => x.billingStatus == "Invoiced");
        invoicedTimesheetsSelected = invoicedItems.length > 0;

        this.setState({
            rowsSelected: rs,
            invoicedTimesheetsSelected: invoicedTimesheetsSelected
        });
    }

    onChange = onFieldChange;
    handleSaveError = (err) => handleFormSaveError(this, err);

    async toggleExportModal() {
        const { showExportModal } = this.state;
        this.setState({ showExportModal: !showExportModal });
    }

    printTimesheet = async (id) => {
        if (!!id) {
            window.open(ApiRoutes.report.timesheetBilling(id), '_self');
        }
    };

    async onExportClick() {
        //this.setState({ loadingReport: true });
        this.setState({ isExporting: true });
        let { gridOptions } = this.state;
        let model = {};
        if (gridOptions.api.isAnyFilterPresent())
            model = getFilterModel(gridOptions.api.getFilterModel());
        try {
            let response = await util.fetch.post(
                ApiRoutes.invoiceGeneration.export(),
                model
            );
            if (response) {
                toasty.error(response);
            } else {
                toasty.success('Invoice Report complete');
            }
        } catch {
            toasty.error('Error exporting invoice');
        }
        //this.setState({ loadingReport: false });
        this.state.gridOptions.refresh();
        this.setState({ isExporting: false, showExportModal: false });
    }

    onCellClicked = (data) => {
        //test
        alert(data);
    };

    onRowClicked = (event) => {
        let selection = event.api.getSelectedRows();
        let row = !!selection.length ? selection[0] : null;

        if (row) {
            this.setState({ selectedRow: row });
            //this.openTimesheetDetails();
        }
    };

    onGenerateInvoice = async () => {
        this.setState({ isInvoicing: true });

        let rowsSelected = this.state.rowsSelected;

        //call accept
        if (!!rowsSelected) {
            //check if all the selected TS are from same contract
            let contractIds = rowsSelected.map((x) => x.contractId);
            let distinctContractIds = [...new Set(contractIds)];
            let workOrderNumbers = rowsSelected.map((x) =>
                x.workOrderNumber?.toUpperCase()
            );
            let invoiced = rowsSelected.filter((x) => x.billingStatus == "Invoiced");

            let distinctWorkOrderNumbers = [...new Set(workOrderNumbers)];

            if (invoiced.length > 0) {
                toasty.error(
                    'Selections contain timesheets that are already invoiced.'
                );
                this.setState({ isInvoicing: false });
            }
            else if (distinctContractIds.length !== 1) {
                //throw error
                toasty.error(
                    'Selected timesheets are not from the same contract.'
                );
                this.setState({ isInvoicing: false });
            } else {
                let allowMultipleWONumbers =
                    rowsSelected[0].allowMultipleWONumbers;

                if (
                    distinctWorkOrderNumbers.length !== 1 &&
                    !allowMultipleWONumbers
                ) {
                    //throw error
                    toasty.error(
                        'Selected timesheets are not from the same work order.'
                    );
                    this.setState({ isInvoicing: false });
                } else {
                    await this.context.setFormOpened(true);
                    //get rows ids
                    let model = {
                        billingIds: rowsSelected.map((x) => x.id),
                        invoiceId: null,
                    };
                    this.invoiceSlideoutRef.current.open(model);
                }
            }
        }
    };

    onInvoiceChargeReport = async () => {
        let rowsSelected = this.state.rowsSelected;

        if (!!rowsSelected) {
            let model = rowsSelected.map((x) => x.id);

            await util.fetch
                .downloadFile(
                    ApiRoutes.invoiceGeneration.generateChargeReport(),
                    model,
                    'InvoiceChargeReport.xlsx'
                )
                .catch(this.handleSaveError);
        }
    };

    openTimesheetDetails = async (id) => {
        if (!!id) {
            await this.context.setFormOpened(true);
            this.timesheetDetailsRef.current.open(id);
        }
    };

    onTimesheetDetailsClosed = async () => {
        await this.context.setFormOpened(false);
        this.setState({ selectedRow: null });
        this.state.gridOptions.refresh();
    };

    onInvoiceSlideoutClosed = async () => {
        await this.context.setFormOpened(false);
        this.setState({ rowsSelected: [], isInvoicing: false });
        this.state.gridOptions.refresh();
    };

    onAcceptButtonClick = async () => {
        this.setState({ isApproving: true });

        let rowsSelected = this.state.rowsSelected;

        //call accept
        if (!!rowsSelected) {
            let model = rowsSelected.map((x) => x.id);
            let response = await util.fetch
                .post(ApiRoutes.invoiceGeneration.approve(), model)
                .catch(this.handleSaveError);

            if (response) {
                toasty.error(`Error approving records: ${response}`);
            } else {
                toasty.success('Records approved');
            }
        }

        this.state.gridOptions.refresh();

        this.setState({ rowsSelected: [], isApproving: false });
    };

    onRejectTimesheet = async () => {
        toasty.error('Not Implemented');
    };

    toggleRejectTimesheetModal = async () => {
        let { showRejectTimesheetModal } = this.state;

        if (!this.context?.user?.isAdmin) {
            toasty.info('Only admins are allowed to reject timesheets');
            return;
        }

        this.setState({
            showRejectTimesheetModal: !showRejectTimesheetModal,
            adminRejecting: true,
        });
    };

    toggleRejectBillingTimesheetModal = async () => {
        let { showRejectBillingTimesheetModal } = this.state;

        this.setState({
            showRejectBillingTimesheetModal: !showRejectBillingTimesheetModal,
        });
    };

    rejectTimesheet = async () => {
        let { rowsSelected, selectedRowRejectionNotes } = this.state;

        if (
            selectedRowRejectionNotes.length === 0 ||
            !selectedRowRejectionNotes.trim()
        )
            return;

        if (!this.context?.user?.isAdmin) {
            toasty.info('Only admins are allowed to reject timesheets');
            return;
        }

        this.setState({ isRejectingTimesheet: true });

        //call reject
        if (!!rowsSelected) {
            let model = {
                billingId: rowsSelected[0].id,
                note: selectedRowRejectionNotes,
            };

            let endPoint = ApiRoutes.invoiceGeneration.adminReject();
            let response = await util.fetch
                .post(endPoint, model)
                .catch(this.handleSaveError);

            if (response) {
                toasty.success('Billing: Timesheet Rejected');
            }
        }

        this.state.gridOptions.refresh();

        this.setState({
            selectedRow: null,
            showRejectTimesheetModal: false,
            selectedRowRejectionNotes: '',
            isRejectingTimesheet: false,
            adminRejecting: false,
        });
    };

    rejectBillingTimesheet = async () => {
        let { rowsSelected, selectedRowRejectionNotes } = this.state;

        if (
            selectedRowRejectionNotes.length === 0 ||
            !selectedRowRejectionNotes.trim()
        )
            return;

        this.setState({ isRejectingBillingTimesheet: true });

        //call reject
        if (!!rowsSelected) {
            let model = {
                billingIds: rowsSelected.map((x) => x.id),
                note: selectedRowRejectionNotes,
            };

            let endPoint = ApiRoutes.invoiceGeneration.billingReject();
            let response = await util.fetch
                .post(endPoint, model)
                .catch(this.handleSaveError);

            if (response) {
                toasty.success(
                    'All charges for the selected timesheets will be rejected to Billing: Charges'
                );
            }
        }

        this.state.gridOptions.refresh();

        this.setState({
            selectedRow: null,
            showRejectBillingTimesheetModal: false,
            selectedRowRejectionNotes: '',
            isRejectingBillingTimesheet: false,
        });
    };

    downloadExport = async (id) => {
        if (!!id) {
            this.setState({ loadingReport: true });
            /*let model = { id: id };*/
            await util.fetch
                .post(ApiRoutes.invoiceGeneration.download(id))
                .catch(this.handleSaveError);
            this.setState({ loadingReport: false });
        }
    };

    setOnHold = async (id) => {
        if (!!id) {
            let response = await util.fetch
                .post(ApiRoutes.invoiceGeneration.setOnHold(), id)
                .catch(this.handleSaveError);

            if (response) {
                toasty.error(`Error setting on hold: ${response}`);
            } else {
                toasty.success('Hold set');
            }

            this.state.gridOptions.refresh();
        }
    };

    removeOnHold = async (id) => {
        if (!!id) {
            let response = await util.fetch
                .post(ApiRoutes.invoiceGeneration.removeOnHold(), id)
                .catch(this.handleSaveError);

            if (response) {
                toasty.error(`Error setting removing on hold: ${response}`);
            } else {
                toasty.success('Hold removed');
            }

            this.state.gridOptions.refresh();
        }
    };

    toggleMovingTimesheetModal = async () => {
        let { rowsSelected, showMoveTimesheetModal } = this.state;
        let contracts = [];
        let allTimesheetsByJob = [];

        if (!showMoveTimesheetModal) {
            if (!!rowsSelected) {
                let jobNums = rowsSelected.map((e) => {
                    let p = e.timesheetNumber.split('-');
                    return p[0] + '-' + p[1];
                });

                let distinctjobs = [...new Set(jobNums)];

                if (distinctjobs.length !== 1) {
                    //throw error
                    toasty.error(
                        'Selected timesheets are not from the same job.'
                    );
                    return;
                }

                // Get contracts based on company
                contracts = await util.fetch.js(
                    ApiRoutes.typeAheads.contractsByCompany(
                        rowsSelected[0].customerId
                    )
                );

                contracts = contracts.map((x) => {
                    return {
                        label:
                            x.number +
                            (x.operationsCenterName != null
                                ? ' (' + x.operationsCenterName + ')'
                                : ''),
                        value: x.id,
                    };
                });

                // Get all timesheets belong to jobs involved and display them
                let timesheetIds = rowsSelected.map((x) => {
                    return x.timesheetId;
                });

                let timesheetList = timesheetIds.join(',');
                allTimesheetsByJob = await util.fetch.js(
                    ApiRoutes.timesheetManagement.timesheetsBelongingToJobs(
                        timesheetList
                    )
                );
            }
        }

        await this.setState({
            showMoveTimesheetModal: !showMoveTimesheetModal,
            contracts,
            allTimesheetsByJob,
            selectedContract: 0,
        });
    };

    moveTimesheets = async () => {
        let { selectedContract, allTimesheetsByJob, createJob } = this.state;

        this.setState({ isMovingTimesheet: true });

        let groupContainsInvoice =
            allTimesheetsByJob.filter((e) => e.invoiceStatusId > 0).length > 0;
        let groupMixSelected =
            allTimesheetsByJob.filter((e) => e.convert == false).length > 0;

        let model = {
            contractId: selectedContract,
            timesheetIds: allTimesheetsByJob
                .filter((x) => x.convert == true)
                .map((e) => {
                    return e.id;
                }),
            createJob: groupContainsInvoice || groupMixSelected,
        };

        try {
            let response = await util.fetch.post(
                ApiRoutes.contract.moveTimesheets(),
                model
            );

            if (response.result == 0) {
                toasty.success('Timesheets moved');
            } else {
                toasty.error('Error moving timesheets');
            }

            this.state.gridOptions.refresh();
        } catch {
            toasty.error('Error moving timesheets');
        }

        this.setState({
            isMovingTimesheet: false,
            showMoveTimesheetModal: false,
        });
    };

    onBillingIssuesSlideout = async (id) => {
        let messages = await util.fetch.js(
            ApiRoutes.billingIssues.messages(id)
        );

        this.setState({
            billingIssueMessages: messages,
            showBillingIssuesSlideout: true,
        });

        this.billingIssuesSlideoutRef.current.open();
    };

    onRejectToBillingIssues = async (id, message, updateStatus) => {
        if (message == null || message.length == 0) {
            toasty.error('Please supply rejection notes');
            return;
        }

        let model = {
            timesheetId: id,
            notes: message,
            updateStatus: updateStatus
        };

        await util.fetch.post(ApiRoutes.billingIssues.reject(), model);

        toasty.success('Billing row rejected to Billing Issues');

        this.setState({
            showBillingIssuesSlideout: false,
        });

        this.state.gridOptions.refresh();
    };

    onAcceptBillingIssueReply = async (id, message) => {
        let model = {
            timesheetId: id,
            notes: message,
        };

        await util.fetch.post(ApiRoutes.billingIssues.accept(), model);

        toasty.success('Billing reply accepted');

        this.setState({
            showBillingIssuesSlideout: false,
        });

        this.state.gridOptions.refresh();
    };

    renderTimesheetByjobList = () => {
        let { allTimesheetsByJob } = this.state;

        let groupContainsInvoice =
            allTimesheetsByJob.filter((e) => e.invoiceStatusId > 0).length > 0;
        let groupMixSelected =
            allTimesheetsByJob.filter((e) => e.convert == false).length > 0;

        return allTimesheetsByJob.map((timesheet, timesheetIndex) => {
            let tsHasInvoice = timesheet.invoiceStatusId > 0;

            return (
                <tr key={allTimesheetsByJob}>
                    <td>
                        {tsHasInvoice && (
                            <FormCheckbox
                                id={timesheet.id}
                                checked={false}
                                labelText={timesheet.timesheetNumber}
                                disabled={true}
                            />
                        )}
                        {!tsHasInvoice && (
                            <FormCheckbox
                                id={timesheet.id}
                                checked={timesheet.convert}
                                onChange={(e) => {
                                    let { allTimesheetsByJob } = this.state;
                                    timesheet.convert = e.target.checked;
                                    this.setState({
                                        allTimesheetsByJob: allTimesheetsByJob,
                                    });
                                }}
                                labelText={timesheet.timesheetNumber}
                            />
                        )}
                    </td>
                    <td>
                        {timesheet.convert && (
                            <>
                                {!tsHasInvoice &&
                                    (groupContainsInvoice ||
                                        groupMixSelected) && (
                                        <span>
                                            <ul
                                                style={{
                                                    paddingLeft: '18px',
                                                    marginBottom: '0px',
                                                }}
                                            >
                                                <li>New job</li>
                                                <li>New timesheet number</li>
                                            </ul>
                                        </span>
                                    )}
                                {!tsHasInvoice &&
                                    !groupContainsInvoice &&
                                    !groupMixSelected && (
                                        <span>
                                            <ul
                                                style={{
                                                    paddingLeft: '18px',
                                                    marginBottom: '0px',
                                                }}
                                            >
                                                <li>Same job</li>
                                                <li>New timesheet number</li>
                                            </ul>
                                        </span>
                                    )}
                            </>
                        )}
                        {!timesheet.convert && (
                            <>
                                {tsHasInvoice && <span>(unavailable)</span>}
                                {!tsHasInvoice && <span>(none)</span>}
                            </>
                        )}
                    </td>
                    <td>{timesheet.timesheetStatus}</td>
                    <td>{timesheet.billingStatus}</td>
                    <td>{timesheet.invoiceStatus}</td>
                </tr>
            );
        });
    };

    render() {
        const {
            rowData,
            gridOptions,
            filterModel,
            showRejectTimesheetModal,
            showRejectBillingTimesheetModal,
            showMoveTimesheetModal,
            isRejectingTimesheet,
            isRejectingBillingTimesheet,
            isMovingTimesheet,
            isInvoicing,
            selectedRowRejectionNotes,
            rowsSelected,
            isExporting,
            showExportModal,
            adminRejecting,
            contracts,
            allTimesheetsByJob,
            selectedContract,
            selectedRow,
            billingIssueMessages,
            scoreboardData,
            canMoveTimesheetContract,
            invoicedTimesheetsSelected
        } = this.state;

        let counts = {
            onHold: 0,
            pendingReview: 0,
            billingIssue: 0,
        };
        if (!!scoreboardData) {
            counts = scoreboardData;
        }

        let countOnHold =
            'mr-3 badge badge badge-lg ' +
            (counts.onHold === 0 ? 'badge-success p-2' : 'badge-warning p-2');
        let countPendingReview =
            'mr-3 badge badge badge-lg ' +
            (counts.pendingReview === 0
                ? 'badge-success p-2'
                : 'badge-warning p-2');
        let countBillingIssue =
            'mr-3 badge badge badge-lg ' +
            (counts.billingIssue === 0
                ? 'badge-success p-2'
                : 'badge-warning p-2');

        let billingFilter = BillingStatus.ReadyToInvoice;
        if (filterModel != null) {
            billingFilter =
                filterModel?.BillingStatusId?.value != null
                    ? filterModel.BillingStatusId.value
                    : billingFilter;
        }

        return (
            <CommonContext.Consumer>
                {(value) => {
                    const { tenantSettings } = (value ?? {}).tenant ?? {};

                    if (this.state.loading || !tenantSettings)
                        return (
                            <LinearProgress
                                variant="indeterminate"
                                color="secondary"
                            />
                        );

                    if (
                        !tenantSettings.billingEnabled ||
                        !tenantSettings.invoicingEnabled
                    )
                        return <NotAuthorizedPage />;

                    const isAdmin = this.context?.user?.isAdmin;

                    return (
                        <PageWrap>
                            <PageHeading>
                                <FontAwesomeIcon
                                    icon={faTasks}
                                    className="mr-2 text-muted"
                                />
                                <span>Invoice Generation</span>

                                {billingFilter != BillingStatus.IssueReject &&
                                    billingFilter !=
                                    BillingStatus.PendingReview &&
                                    billingFilter !=
                                    BillingStatus.Invoiced &&
                                    (
                                        <span
                                            style={{
                                                float: 'right',
                                                position: 'relative',
                                                top: '-5px',
                                            }}
                                        >
                                            {/*    <Button*/}
                                            {/*        size="sm"*/}
                                            {/*        style={{ marginRight: "10px" }}*/}
                                            {/*        color="danger"*/}
                                            {/*        onClick={() => this.toggleExportModal()}*/}
                                            {/*    >*/}
                                            {/*        Export*/}
                                            {/*</Button>*/}
                                            {rowsSelected.length > 0 && !invoicedTimesheetsSelected && (
                                                <Button
                                                    size="sm"
                                                    color="success"
                                                    style={{
                                                        marginRight: '10px',
                                                    }}
                                                    onClick={() =>
                                                        this.onGenerateInvoice()
                                                    }
                                                    disabled={
                                                        rowsSelected.length ===
                                                            0 || isInvoicing
                                                    }
                                                >
                                                    Generate Invoice
                                                </Button>
                                            )}
                                            {rowsSelected.length > 0 && !invoicedTimesheetsSelected && (
                                                <Button
                                                    size="sm"
                                                    color="danger"
                                                    style={{
                                                        marginRight: '10px',
                                                    }}
                                                    onClick={() =>
                                                        this.toggleRejectBillingTimesheetModal()
                                                    }
                                                    disabled={
                                                        rowsSelected.length ===
                                                            0 ||
                                                        isRejectingBillingTimesheet
                                                    }
                                                >
                                                    Return To Billing Charges
                                                </Button>
                                            )}
                                            {isAdmin &&
                                                rowsSelected.length == 1 && !invoicedTimesheetsSelected &&  (
                                                    <Button
                                                        size="sm"
                                                        color="danger"
                                                        style={{
                                                            marginRight: '10px',
                                                        }}
                                                        onClick={() =>
                                                            this.toggleRejectBillingTimesheetModal()
                                                        }
                                                        disabled={
                                                            rowsSelected.length !==
                                                                1 ||
                                                            isRejectingTimesheet
                                                        }
                                                    >
                                                        Return To Timesheet
                                                        Management
                                                    </Button>
                                                )}
                                            {rowsSelected.length > 0 && !invoicedTimesheetsSelected && (
                                                <Button
                                                    size="sm"
                                                    color="info"
                                                    style={{
                                                        marginRight: '10px',
                                                    }}
                                                    onClick={() =>
                                                        this.onInvoiceChargeReport()
                                                    }
                                                >
                                                    Invoice Charge Report
                                                </Button>
                                            )}
                                            {canMoveTimesheetContract &&
                                                rowsSelected.length > 0 && !invoicedTimesheetsSelected && (
                                                    <Button
                                                        size="sm"
                                                        color="warning"
                                                        onClick={() =>
                                                            this.toggleMovingTimesheetModal()
                                                        }
                                                        disabled={
                                                            rowsSelected.length ==
                                                                0 ||
                                                            isMovingTimesheet
                                                        }
                                                    >
                                                        Move Timesheet OPS
                                                        Center
                                                    </Button>
                                                )}
                                        </span>
                                    )}
                            </PageHeading>

                            <FlexCenterRow
                                className="pb-3 pt-2"
                                id="invoiceGenerationWorkflowStatus"
                            >
                                <span
                                    style={{ minWidth: '100px' }}
                                    className={countOnHold}
                                >
                                    On Hold
                                    <span className="ml-2">
                                        {counts.onHold}
                                    </span>
                                </span>
                                <span
                                    style={{ minWidth: '100px' }}
                                    className={countBillingIssue}
                                >
                                    Billing Issue
                                    <span className="ml-2">
                                        {counts.billingIssue}
                                    </span>
                                </span>
                                <span
                                    style={{ minWidth: '100px' }}
                                    className={countPendingReview}
                                >
                                    Pending Review
                                    <span className="ml-2">
                                        {counts.pendingReview}
                                    </span>
                                </span>
                            </FlexCenterRow>

                            <DataGridToolbar
                                entity="Billing"
                                gridApi={this.state.gridApi}
                                dataSource={this.state.dataSource}
                                hideAdd={true}
                                hideExcelButton={true}
                                gridOptions={this.state.gridOptions}
                                serverExport={{
                                    apiPath:
                                        ApiRoutes.invoiceGeneration.excelExport(),
                                    filePrefix: 'BillingSearch',
                                }}
                                serverExportDisabled={
                                    !!this.state.saving ||
                                    !!this.state.loading ||
                                    !!this.state.loadingData
                                }
                            />
                            {!!this.state.loadingReport && (
                                <CustomCircularProgress />
                            )}

                            {!this.state.loadingReport && (
                                <>
                                    <DataGrid
                                        domLayout={'normal'}
                                        rowData={rowData}
                                        gridOptions={gridOptions}
                                        gridStatus={this.state.gridStatus}
                                    />
                                </>
                            )}
                            <TimesheetDetails
                                ref={this.timesheetDetailsRef}
                                show={this.state.timesheetDetailsOpen}
                                toggleShow={(open) =>
                                    this.setState({
                                        timesheetDetailsOpen: open,
                                    })
                                }
                                onClose={this.onTimesheetDetailsClosed}
                                onAccept={this.onAcceptTimesheet}
                                onReject={this.onRejectTimesheet}
                                onlyBillable={true}
                                isBilling={true}
                            />
                            <InvoiceSlideout
                                ref={this.invoiceSlideoutRef}
                                show={this.state.invoiceSlideoutOpen}
                                toggleShow={(open) =>
                                    this.setState({ invoiceSlideoutOpen: open })
                                }
                                onClose={this.onInvoiceSlideoutClosed}
                            />
                            <Modal
                                isOpen={showRejectTimesheetModal}
                                toggle={this.toggleRejectTimesheetModal}
                            >
                                <ModalHeader
                                    toggle={this.toggleRejectTimesheetModal}
                                >
                                    Billing: Reject Timesheet
                                </ModalHeader>
                                <ModalBody>
                                    <div
                                        style={{ marginBottom: '20px' }}
                                        className="form-text text-danger"
                                    >
                                        This administrative action will delete
                                        any approved payroll or billing records,
                                        and reset the timesheet. Please take
                                        note of Payroll and Billing
                                        implications.
                                    </div>

                                    <FormGroup>
                                        <FormLabel
                                            required={true}
                                            text="Notes"
                                        ></FormLabel>
                                        <textarea
                                            id="selectedRowRejectionNotes"
                                            name="selectedRowRejectionNotes"
                                            className="form-control"
                                            defaultValue={
                                                selectedRowRejectionNotes ?? ''
                                            }
                                            onChange={this.onChange}
                                            required
                                            placeholder="Enter notes regarding the rejection."
                                            type="text"
                                            maxLength="500"
                                            rows="5"
                                        />
                                        <small className="text-danger">
                                            Notes are required.
                                        </small>
                                    </FormGroup>
                                </ModalBody>
                                <ModalFooter>
                                    {isRejectingTimesheet && (
                                        <FontAwesomeIcon
                                            icon={faCircleNotch}
                                            className="fa-spin mr-2"
                                            size="sm"
                                        />
                                    )}
                                    <Button
                                        color="primary"
                                        disabled={isRejectingTimesheet}
                                        onClick={this.rejectTimesheet}
                                    >
                                        Ok
                                    </Button>{' '}
                                </ModalFooter>
                            </Modal>
                            <Modal
                                isOpen={showRejectBillingTimesheetModal}
                                toggle={this.toggleRejectBillingTimesheetModal}
                            >
                                <ModalHeader
                                    toggle={
                                        this.toggleRejectBillingTimesheetModal
                                    }
                                >
                                    Reject Timesheet To Billing: Charges
                                </ModalHeader>
                                <ModalBody>
                                    <div
                                        style={{ marginBottom: '20px' }}
                                        className="form-text text-danger"
                                    >
                                        This action will reset all charges to
                                        associated with these timesheets back to
                                        Billing: Charges.
                                    </div>

                                    <FormGroup>
                                        <FormLabel
                                            required={true}
                                            text="Notes"
                                        ></FormLabel>
                                        <textarea
                                            id="selectedRowRejectionNotes"
                                            name="selectedRowRejectionNotes"
                                            className="form-control"
                                            defaultValue={
                                                selectedRowRejectionNotes ?? ''
                                            }
                                            onChange={this.onChange}
                                            required
                                            placeholder="Enter notes regarding the rejection."
                                            type="text"
                                            maxLength="500"
                                            rows="5"
                                        />
                                        <small className="text-danger">
                                            Notes are required.
                                        </small>
                                    </FormGroup>
                                </ModalBody>
                                <ModalFooter>
                                    {isRejectingTimesheet && (
                                        <FontAwesomeIcon
                                            icon={faCircleNotch}
                                            className="fa-spin mr-2"
                                            size="sm"
                                        />
                                    )}
                                    <Button
                                        color="primary"
                                        disabled={isRejectingBillingTimesheet}
                                        onClick={this.rejectBillingTimesheet}
                                    >
                                        Ok
                                    </Button>{' '}
                                </ModalFooter>
                            </Modal>
                            <Modal
                                isOpen={showExportModal}
                                toggle={() => this.toggleExportModal()}
                            >
                                <ModalHeader
                                    toggle={() => this.toggleExportModal()}
                                >
                                    Export Billing
                                </ModalHeader>
                                <ModalBody>
                                    <p>
                                        Export will be generated based upon
                                        search criteria entered on the billing
                                        screen. If the result set is too large
                                        the export may timeout.
                                    </p>
                                    {/*<FormGroup>*/}
                                    {/*    <label>Dispatching</label>*/}
                                    {/*    <Select*/}
                                    {/*        placeholder={'Select Dispatch Location'}*/}
                                    {/*        id="selectedDispatchLocation"*/}
                                    {/*        name="selectedDispatchLocation"*/}
                                    {/*        styles={CompactSelectStyles}*/}
                                    {/*        isClearable={false}*/}
                                    {/*        className="react-select"*/}
                                    {/*        options={dispatchLocations}*/}
                                    {/*        value={(dispatchLocations ?? []).find(x => x.value === selectedDispatchLocation) ?? ''}*/}
                                    {/*        onChange={this.onSelectedDispatchLocationChanged}*/}
                                    {/*    />*/}
                                    {/*</FormGroup>*/}
                                    {/*<FormGroup>*/}
                                    {/*    <label>Week Of</label>*/}
                                    {/*    <input*/}
                                    {/*        id="weekOf"*/}
                                    {/*        name="selectedWeekOf"*/}
                                    {/*        className="form-control"*/}
                                    {/*        defaultValue={selectedWeekOf ?? ''}*/}
                                    {/*        onChange={this.onChange}*/}
                                    {/*        type="date"*/}
                                    {/*    />*/}
                                    {/*</FormGroup>*/}
                                </ModalBody>
                                <ModalFooter>
                                    {isExporting && (
                                        <FontAwesomeIcon
                                            icon={faCircleNotch}
                                            className="fa-spin mr-2"
                                            size="sm"
                                        />
                                    )}
                                    <Button
                                        color="primary"
                                        disabled={isExporting}
                                        onClick={() => this.onExportClick()}
                                    >
                                        Ok
                                    </Button>{' '}
                                    <Button
                                        color="secondary"
                                        onClick={() =>
                                            this.setState({
                                                showExportModal: false,
                                            })
                                        }
                                    >
                                        Cancel
                                    </Button>
                                </ModalFooter>
                            </Modal>
                            <Modal
                                size="lg"
                                isOpen={showMoveTimesheetModal}
                                toggle={this.toggleMovingTimesheetModal}
                            >
                                <ModalHeader
                                    toggle={this.toggleMovingTimesheetModal}
                                >
                                    Move Timesheets to Contract / OPS Center
                                </ModalHeader>
                                <ModalBody>
                                    <FormGroup>
                                        <label>Job Timesheets</label>

                                        <SimpleTable
                                            className="mb-0"
                                            id={'timesheetJobsTable'}
                                            noTopBorder={true}
                                            permission={'contract'}
                                            tableHeaderLabels={[
                                                {
                                                    name: 'Timesheet Number',
                                                },
                                                {
                                                    name: 'Action(s)',
                                                },
                                                {
                                                    name: 'Timesheet Status',
                                                },
                                                {
                                                    name: 'Billing Status ',
                                                },
                                                {
                                                    name: 'Invoice Status ',
                                                },
                                            ]}
                                            editable={false}
                                            entities={allTimesheetsByJob}
                                            rowRenderer={
                                                this.renderTimesheetByjobList
                                            }
                                            noDataText={'No timesheets found.'}
                                        />
                                    </FormGroup>

                                    <FormGroup style={{ marginTop: '15px' }}>
                                        <label>Contract / OPS Center</label>
                                        <Select
                                            placeholder={'Select Contract'}
                                            id="selectedContract"
                                            name="selectedContract"
                                            styles={CompactSelectStyles}
                                            isClearable={false}
                                            className="react-select"
                                            options={contracts}
                                            value={
                                                (contracts ?? []).find(
                                                    (x) =>
                                                        x.value ===
                                                        selectedContract
                                                ) ?? ''
                                            }
                                            onChange={(evt) => {
                                                let value = evt.value;
                                                this.setState({
                                                    selectedContract: value,
                                                });
                                            }}
                                        />
                                    </FormGroup>
                                </ModalBody>
                                <ModalFooter>
                                    {isMovingTimesheet && (
                                        <FontAwesomeIcon
                                            icon={faCircleNotch}
                                            className="fa-spin mr-2"
                                            size="sm"
                                        />
                                    )}
                                    <Button
                                        color="primary"
                                        disabled={
                                            selectedContract == null ||
                                            selectedContract == 0 ||
                                            isMovingTimesheet ||
                                            allTimesheetsByJob.filter(
                                                (x) => x.convert == true
                                            ).length == 0
                                        }
                                        onClick={this.moveTimesheets}
                                    >
                                        Ok
                                    </Button>{' '}
                                </ModalFooter>
                            </Modal>

                            <SlideForm
                                loading={false}
                                show={this.state.showBillingIssuesSlideout}
                                id="billingIssuesSlideout"
                                formIcon={faComments}
                                formTitle="Billing Issues"
                                onClose={() => {
                                    this.setState({
                                        showBillingIssuesSlideout: false,
                                    });
                                }}
                            >
                                <BillingIssuesWidget
                                    ref={this.billingIssuesSlideoutRef}
                                    billing={selectedRow}
                                    messages={billingIssueMessages}
                                    context={'rejecting'}
                                    onRejectCallback={(id, message, updateStatus) => {
                                        this.onRejectToBillingIssues(
                                            id,
                                            message,
                                            updateStatus
                                        );
                                    }}
                                    onAcceptCallback={(id, message) => {
                                        this.onAcceptBillingIssueReply(
                                            id,
                                            message
                                        );
                                    }}
                                    onCloseCallback={(id, message) => {
                                        this.setState({
                                            showBillingIssuesSlideout: false,
                                        });
                                    }}
                                ></BillingIssuesWidget>
                            </SlideForm>
                        </PageWrap>
                    );
                }}
            </CommonContext.Consumer>
        );
    }
}

export default withRouter(InvoiceGenerationIndex);
