import React from 'react';
import { faCalendarTimes } from '@fortawesome/free-solid-svg-icons';
import { FormGroup, Input } from 'reactstrap';
import { BaseFormViewModel } from '../common/ViewModel';
import {
    FormCheckbox,
    FormLabel,
    ValidationErrorMessage,
} from '../common/forms/FormElements';
import { LongTermStatus } from './LongTermStatus';
import CommonContext, { ApiRoutes } from '../Common';
import SlideForm from '../common/forms/SlideForm';
import { util } from '../Util';
import { handleFormSaveError } from '../common/forms/ValidationError';

export default class LongTermStatusForm extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();

        let stateBase = Object.assign(
            {
                selectedLongTermStatus:
                    props.selectedLongTermStatus ?? new LongTermStatus(),
            },
            new BaseFormViewModel()
        );

        this.state = stateBase;
        this.onSubmit = this.onSubmit.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    componentDidMount = () => this.populateState();

    populateState = async () => {
        this.setState({
            loading: false,
        });
    };

    resetForm = () => this.setState({ formValidated: false });
    clearErrors = () => this.setState({ errors: {} });

    onSubmit = async (e) => {
        //Clear any fluent api errors
        this.clearErrors();
        this.props.onSaveCallback();
        this.resetForm();
    };

    onClose = (response) => {
        this.resetForm();
        this.context.setFormOpened(false);
        this.props.onClose(response);
    };

    handleSaveError = (err) => handleFormSaveError(this, err);

    onDelete = async (e) => {
        let { selectedLongTermStatus } = this.props;

        let response = await util.fetch
            .delete(ApiRoutes.longTermStatus.delete(selectedLongTermStatus.id))
            .catch(this.handleSaveError);

        if (response) {
            this.props.onDeleteCallback(response);
        }
    };

    render() {
        let { formValidated, validationMessage, loading } = this.state;
        let { selectedLongTermStatus, show } = this.props;

        if (!selectedLongTermStatus) {
            return '';
        }

        return (
            <SlideForm
                loading={loading}
                show={show}
                id={'longTermStatusForm'}
                formIcon={faCalendarTimes}
                formTitle={
                    !!parseInt(selectedLongTermStatus.id ?? 0) > 0
                        ? 'Edit Long Term Status'
                        : 'Add Long Term Status'
                }
                ref={this.formRef}
                setIsValidated={(value) => {
                    this.setState({ formValidated: value });
                }}
                isValidated={formValidated}
                onSubmit={this.onSubmit}
                onClose={this.onClose}
                onSave={this.onSave}
                onDelete={this.onDelete}
                errors={this.state.errors}
                onClearErrors={this.onClearErrors}
                validationMessage={validationMessage}
                entityId={selectedLongTermStatus.id}
            >
                <FormGroup>
                    <FormLabel htmlFor="name" text="Name" required={true} />
                    <Input
                        id="name"
                        name="selectedLongTermStatus.name"
                        value={selectedLongTermStatus.name ?? ''}
                        onChange={this.props.onChange}
                        placeholder="Enter Name (maximum 250 characters)"
                        maxLength="250"
                        pattern="[^><\][\\\x22,;|]+"
                        type="text"
                        required
                    />
                    <ValidationErrorMessage>
                        Name is required and can contain hyphens and periods.
                    </ValidationErrorMessage>
                </FormGroup>

                <FormGroup>
                    <FormCheckbox
                        id="showOnDispatch"
                        name="selectedLongTermStatus.hideFromDispatchBoard"
                        checked={
                            selectedLongTermStatus.hideFromDispatchBoard ?? ''
                        }
                        onChange={this.props.onChange}
                        labelText={'Hide From Dispatch Board When Assigned'}
                    />
                </FormGroup>

                {!Boolean(selectedLongTermStatus.hideFromDispatchBoard) && (
                    <FormGroup>
                        <FormCheckbox
                            id="allowDispatch"
                            name="selectedLongTermStatus.allowDispatch"
                            checked={selectedLongTermStatus.allowDispatch ?? ''}
                            onChange={this.props.onChange}
                            labelText={'Allow Dispatch'}
                        />
                    </FormGroup>
                )}

                <FormGroup>
                    <FormCheckbox
                        id="requiresEndDate"
                        name="selectedLongTermStatus.requiresEndDate"
                        checked={selectedLongTermStatus.requiresEndDate ?? ''}
                        onChange={this.props.onChange}
                        labelText={'Requires End Date'}
                    />
                </FormGroup>
            </SlideForm>
        );
    }
}
