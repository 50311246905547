import React from 'react';
import { createDataSource, createGridOptions, DataGrid, indexCellRenderer, LinkCellRenderer, TextFilterDefaults } from '../common/dataGrid/DataGrid';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import CommonContext, { agGridConstants, ApiRoutes, AppNavPaths } from '../Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarehouse } from '@fortawesome/free-solid-svg-icons'
import { LinearProgress } from '@material-ui/core';
import { PageHeading, PageWrap } from '../common/forms/FormElements';
import { util } from '../Util';
import { withRouter } from "react-router-dom";
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';
import { NotAuthorizedPage } from '../status/StatusCodes';

class ChargeTypeGroupIndex extends React.Component {

    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        }
    }

    componentDidMount = () => this.populateState();
    componentWillUnmount = () => this.setState = (state, callback) => { return };
    onViewGroup = (id) => this.props.history.push(`${AppNavPaths.ChargeTypeGroup}/${id}`);

    populateState = async () => {

        var [employeeGroups] = await Promise.all([            
            util.fetch.js(ApiRoutes.typeAheads.chargeTypes())
        ]);

        let gridOptions = createGridOptions(this);

        gridOptions.components = {
            selectFilter: DataGridSelectFilter,
            selectFloatingFilter: DataGridSelectFloatingFilter,
            nameRenderer: LinkCellRenderer
        };

        gridOptions.columnDefs = [
            {
                flex: 0,
                width:80,
                headerName: "",
                valueGetter: "node.id",
                sortable: false,
                hide: true,
                cellRenderer: indexCellRenderer
            },
            {
                colId: 'InvoiceDescription',
                sortable: true,
                headerName: 'Invoice Description',
                field: 'invoiceDescription',
                filter: agGridConstants.columnFilterTypes.text,
                floatingFilter: true,
                filterParams: TextFilterDefaults,   
                sort: { direction: 'asc', priority: 0 },
                cellRenderer: 'nameRenderer',
                cellRendererParams: {
                    clicked: this.onViewGroup,
                    nameField: 'invoiceDescription',
                    idField: 'id',
                    title: 'View this Charge Type Group'
                }
            },
            {
                colId: 'ApplicationGroupUserGroups.ApplicationUserGroup',
                sortable: false,
                headerName: 'Charge Types',
                field: 'chargeTypeNames',
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: {
                    labelText: 'Filter by type',
                    options: employeeGroups,
                    optionsLabel: 'label',
                    optionsValue: 'value',
                },
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                    options: employeeGroups,
                    optionsLabel: 'label',
                    optionsValue: 'value'
                }
            },
            {
                colId: 'BillRate',
                headerName: 'Bill Rate',
                field: 'billRate',
                minWidth: 85,
                sortable: false

            },
            {
                colId: 'BillQuantity',
                headerName: 'Bill Quantity',
                field: 'billQuantity',
                minWidth: 65,
                sortable: false

            },
        ];

        let ds = createDataSource(ApiRoutes.chargeTypeGroup.search(), gridOptions);

        this.setState({
            loading: false,
            gridOptions: gridOptions,
            dataSource: ds
        });
    }

    render() {

        return (
            <CommonContext.Consumer>
                {value => {
                    const { tenantSettings } = (value ?? {}).tenant ?? {};

                    if (!tenantSettings) return (<LinearProgress variant="indeterminate" color="secondary" />);

                    const invoicingEnabled = !!tenantSettings.invoicingEnabled;
                    const chargeTypeGroupsEnabled = !!tenantSettings.chargeTypeGroupsEnabled;

                    if (!!this.state.loading) return (<LinearProgress variant="indeterminate" color="secondary" />)
                    let { rowData, gridOptions } = this.state;

                    return (
                        <>
                            {
                                !invoicingEnabled || !chargeTypeGroupsEnabled ? <NotAuthorizedPage /> :
                                    <>
                                        <PageWrap>
                                            <PageHeading>
                                                <FontAwesomeIcon icon={faWarehouse} className="mr-2 text-muted" />
                                                <span>Charge Type Groups</span>
                                            </PageHeading>
                                            <DataGridToolbar
                                                entity="user_group"
                                                gridApi={this.state.gridApi}
                                                dataSource={this.state.dataSource}
                                                onAdd={() => this.props.history.push(`${AppNavPaths.ChargeTypeGroupNew}`)}
                                                addLabel="Add Charge Type Group"
                                            />
                                            <DataGrid
                                                domLayout={"normal"}
                                                rowData={rowData}
                                                gridOptions={gridOptions}
                                                gridStatus={this.state.gridStatus}
                                            >
                                            </DataGrid>
                                        </PageWrap>
                                    </>
                            }
                        </>
                    );


                    }
                }
            </CommonContext.Consumer>
        );


    }
}
export default withRouter(ChargeTypeGroupIndex);