import React from 'react';
import {
    createDataSource,
    createGridOptions,
    DataGrid,
    EditLinkCellRenderer,
    TextFilterDefaults,
    DateFilterDefaults,
    ConditionalEditLinkCellRenderer,
    SelectToEditLinkCellRenderer,
} from '../common/dataGrid/DataGrid';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments } from '@fortawesome/free-solid-svg-icons';
import { LinearProgress } from '@material-ui/core';
import { PageHeading, PageWrap } from '../common/forms/FormElements';
import { withRouter } from 'react-router-dom';
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';
import { util } from '../Util';
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';
import { ActivityTypeOptions } from './ContactActivity';
import ContactActivityForm from './ContactActivityForm';
import _ from 'lodash-es';

class ContactActivityIndex extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            contacts: [],
            companies: [],
            activityTypeOptions: ActivityTypeOptions.filter((ato) =>
                Boolean(ato.value)
            ),
            activityTaskStatuses: [],
        };

        this.contactActivityFormRef = React.createRef();
    }

    componentDidMount = () => this.populateState();
    componentWillUnmount = () =>
        (this.setState = (state, callback) => {
            return;
        });

    onEditCompany = (id) =>
        this.props.history.push(`${AppNavPaths.Organization}/${id}`);

    onEditContactActivity = (id) => {
        this.contactActivityFormRef.current.open(id);
    };

    onContactActivityFormClosed = () => {};

    onContactActivityFormSaved = () => {
        const { gridOptions } = this.state;

        gridOptions.refresh();
    };

    async populateState() {
        const { activityTypeOptions } = this.state;

        const [
            contacts,
            allCompanies,
            activityTaskStatuses,
            employees,
            activityTaskTypes,
        ] = await Promise.all([
            util.fetch.js(ApiRoutes.locationContact.all()),
            util.fetch.js(ApiRoutes.company.all()),
            util.fetch.js(ApiRoutes.typeAheads.activityTaskStatuses()),
            util.fetch.js(ApiRoutes.employees.associatedWithContactActivity()),
            util.fetch.js(ApiRoutes.typeAheads.activityTaskTypes()),
        ]);

        const companies = allCompanies.filter((c) =>
            Boolean(c.active && c.locationId)
        );

        const companyFilterParams = {
            suppressFilterButton: true,
            labelText: 'Filter by Organization',
            options: companies,
            optionsLabel: 'companyName',
            optionsValue: 'id',
        };

        const activityTypeFilterParams = {
            suppressFilterButton: true,
            labelText: 'Filter by Activity Type',
            options: activityTypeOptions,
            optionsLabel: 'label',
            optionsValue: 'value',
        };

        const taskStatusFilterParams = {
            suppressFilterButton: true,
            labelText: 'Filter by Task Status',
            options: activityTaskStatuses,
            optionsLabel: 'label',
            optionsValue: 'value',
        };

        const taskTypeFilterParams = {
            suppressFilterButton: true,
            labelText: 'Filter by Task Type',
            options: activityTaskTypes,
            optionsLabel: 'label',
            optionsValue: 'value',
        };

        const sortedEmployees = _.sortBy(employees, 'name');

        const employeeFilterParams = {
            suppressFilterButton: true,
            labelText: 'Filter by Employee',
            options: sortedEmployees,
            optionsLabel: 'name',
            optionsValue: 'id',
        };

        const companyLinkParams = companies.map((c) => {
            return { value: c.id, text: c.companyName };
        });

        let gridOptions = createGridOptions(this);

        gridOptions.components = {
            selectFilter: DataGridSelectFilter,
            selectFloatingFilter: DataGridSelectFloatingFilter,
            nameRenderer: EditLinkCellRenderer,
            complianceAvailableRenderer: ConditionalEditLinkCellRenderer,
            companyNameRenderer: SelectToEditLinkCellRenderer,
        };

        gridOptions.columnDefs = [
            {
                colId: 'locationContact.lastName',
                sortable: true,
                headerName: 'Last Name',
                field: 'locationContact.lastName',
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilter: true,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'locationContact.firstName',
                sortable: true,
                headerName: 'First Name',
                field: 'locationContact.firstName',
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilter: true,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                coldId: 'locationContact.companyId',
                sortable: false,
                headerName: 'Organization',
                field: 'locationContact.companyId',
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: companyFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: companyFilterParams,
                cellRenderer: 'companyNameRenderer',
                cellRendererParams: {
                    clicked: this.onEditCompany,
                    idField: 'locationContact.companyId',
                    title: 'Edit This Company',
                    entity: 'company',
                    displayValues: companyLinkParams,
                },
            },
            {
                colId: 'employeeId',
                sortable: false,
                headerName: 'Lead',
                valueGetter: (row) => {
                    let leadName = '';

                    if (row && row.data && row.data.assignedToEmployee) {
                        leadName = row.data.assignedToEmployee.name;
                    }

                    return leadName;
                },
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: employeeFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: employeeFilterParams,
            },
            {
                colId: 'activityType',
                sortable: false,
                headerName: 'Activity Type',
                field: 'activityType',
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: activityTypeFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: activityTypeFilterParams,
            },
            {
                colId: 'title',
                sortable: false,
                headerName: 'Title',
                valueGetter: (row) => {
                    let title = '';

                    if (row && row.data) {
                        const rowData = row.data;

                        title = Boolean(rowData.title)
                            ? rowData.title
                            : rowData.description;
                    }

                    return title;
                },
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilter: true,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
                cellRenderer: 'nameRenderer',
                cellRendererParams: {
                    clicked: this.onEditContactActivity,
                    idField: 'id',
                    nameField: 'title || description',
                    title: 'View this Contact Activity',
                    entity: 'contactactivity',
                },
            },
            {
                colId: 'notes',
                sortable: false,
                headerName: 'Notes',
                valueGetter: (row) => {
                    let notes = '';

                    if (row && row.data) {
                        const rowData = row.data;

                        notes = Boolean(rowData.notes)
                            ? rowData.notes
                            : rowData.details;
                    }

                    return notes;
                },
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilter: true,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'activityDate',
                sortable: false,
                headerName: 'Date',
                valueGetter: (row) => {
                    let activityDate = '';

                    if (row && row.data) {
                        const rowData = row.data;

                        activityDate = Boolean(rowData.noteDate)
                            ? rowData.noteDate
                            : rowData.taskDueDate;
                    }

                    return activityDate;
                },
                filter: 'agDateColumnFilter',
                filterParams: DateFilterDefaults,
            },
            {
                colId: 'activityTaskStatusId',
                sortable: false,
                headerName: 'Task Status',
                field: 'activityTaskStatus.description',
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: taskStatusFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: taskStatusFilterParams,
            },
            {
                colId: 'activityTaskTypeId',
                sortable: false,
                headerName: 'Task Type',
                field: 'activityTaskType.description',
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: taskTypeFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: taskTypeFilterParams,
            },
        ];

        let dataSource = createDataSource(
            ApiRoutes.contactActivity.search(),
            gridOptions
        );

        this.setState({
            loading: false,
            gridOptions,
            dataSource,
            contacts,
            companies,
        });
    }

    render() {
        if (!!this.state.loading)
            return <LinearProgress variant="indeterminate" color="secondary" />;

        let { rowData, gridOptions } = this.state;

        return (
            <PageWrap>
                <PageHeading>
                    <FontAwesomeIcon
                        icon={faComments}
                        className="mr-2 text-muted"
                    />
                    <span>Contact Activities</span>
                </PageHeading>
                <DataGridToolbar
                    entity="contactactivity"
                    gridApi={this.state.gridApi}
                    dataSource={this.state.dataSource}
                    onAdd={this.onAddContactActivity}
                    addLabel="Add Contact Activity"
                    hideAdd={true}
                    hideExcelButton={true}
                    gridOptions={gridOptions}
                    serverExportDisabled={
                        !!this.state.saving ||
                        !!this.state.loading ||
                        !!this.state.loadingData
                    }
                    serverExport={{
                        apiPath: ApiRoutes.contactActivity.excelExport(),
                        filePrefix: 'ContactActivities',
                    }}
                ></DataGridToolbar>
                <DataGrid
                    domLayout={'normal'}
                    rowData={rowData}
                    gridOptions={gridOptions}
                    onNameCellClicked={this.onEditContactActivity}
                    gridStatus={this.state.gridStatus}
                />

                <ContactActivityForm
                    onClose={this.onContactActivityFormClosed}
                    ref={this.contactActivityFormRef}
                    onSaveCallback={this.onContactActivityFormSaved}
                    onDeleteCallback={this.onContactActivityFormSaved}
                />
            </PageWrap>
        );
    }
}
export default withRouter(ContactActivityIndex);
