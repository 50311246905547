import React from 'react';
import { Button, FormGroup, } from 'reactstrap';
import { FlexCenterRow, SimpleTable, FlexStartRow, FormLabel } from '../common/forms/FormElements';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faCheckCircle, faReply } from '@fortawesome/fontawesome-free-solid';
import { BillingIssueMessageTypes } from '../billingIssues/BillingIssues';

export default class InvoiceIssuesWidget extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            messages: []
        };
    }

    open = async () => {
        this.setState({ userMessage: "" });
    }

    onReply = () => {
        this.props.onReplyCallback(this.props?.invoice?.invoiceTimesheets[0].timesheetId, this.state.userMessage, true);
    }

    onReject = () => {
        this.props.onRejectCallback(this.props?.invoice?.id, this.props?.invoice?.invoiceTimesheets[0].timesheetId, this.state.userMessage, true);
    }

    onRejectAddNote = () => {
        this.props.onRejectCallback(this.props?.invoice?.id, this.props?.invoice?.invoiceTimesheets[0].timesheetId, this.state.userMessage, false);
    }

    onAccept = () => {
        this.props.onAcceptCallback(this.props?.invoice?.id, this.props?.invoice?.invoiceTimesheets[0].timesheetId, this.state.userMessage);
    }

    onClose = () => {
        this.props.onCloseCallback();
    }

    renderMessages = () => {

        return this.props.messages.map((message, messageIndex) => {

            return (
                <>
                    <tr>
                        <td width="30" style={{ paddingTop: '15px', textAlign: 'center' }}>
                            {message.billingIssueMessageTypeId == BillingIssueMessageTypes.Reply &&
                                <FontAwesomeIcon
                                    icon={faReply}
                                    size="lg"
                                    color="blue"
                                />
                            }
                            {message.billingIssueMessageTypeId == BillingIssueMessageTypes.Rejection &&
                                <FontAwesomeIcon
                                    icon={faTimesCircle}
                                    size="lg"
                                    color="red"
                                />
                            }
                            {message.billingIssueMessageTypeId == BillingIssueMessageTypes.Accepted &&
                                <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    size="lg"
                                    color="green"
                                />
                            }
                        </td>
                        <td width="150">
                            <span style={{ whiteSpace: 'nowrap' }}>{message.createdBy}</span><br></br>
                            <span style={{ whiteSpace: 'nowrap' }}>{message.createdOn}</span>
                        </td>
                        <td>
                            <div style={{ whiteSpace: 'pre-line' }}>{message.notes}</div>
                        </td>
                    </tr>
                </>
            )
        });
    }

    render() {
        return (
            <>
                <FormGroup>
                    <div className="invoice-details" style={{ paddingTop: '4px', paddingBottom: '0px' }}>
                        <FlexStartRow>
                            <FormLabel
                                text="Invoice Date"
                            />
                            <span
                                className="text-muted"
                                style={{ marginLeft: '16px', marginTop: '5px' }}
                            >
                                {this.props?.invoice?.invoiceDate}
                            </span>
                        </FlexStartRow>
                        <FlexStartRow>
                            <FormLabel
                                text="Invoice Number"
                            />
                            <span
                                className="text-muted"
                                style={{ marginLeft: '16px', marginTop: '5px' }}
                            >
                                {this.props?.invoice?.invoiceNumber}
                            </span>
                        </FlexStartRow>
                        <FlexStartRow>
                            <FormLabel
                                text="Customer"
                            />
                            <span
                                className="text-muted"
                                style={{ marginLeft: '16px', marginTop: '5px' }}
                            >
                                {this.props?.invoice?.customerCompanyName}
                            </span>
                        </FlexStartRow>
                        <FlexStartRow>
                            <FormLabel
                                text="Contract#"
                            />
                            <span
                                className="text-muted"
                                style={{ marginLeft: '16px', marginTop: '5px' }}
                            >
                                {this.props?.invoice?.contractNumber}
                            </span>
                        </FlexStartRow>
                        <FlexStartRow>
                            <FormLabel
                                text="Dispatching"
                            />
                            <span
                                className="text-muted"
                                style={{ marginLeft: '16px', marginTop: '5px' }}
                            >
                                {this.props?.invoice?.dispatchOfficeLocationName}
                            </span>
                        </FlexStartRow>
                        <FlexStartRow>
                            <FormLabel
                                text="Work Order"
                            />
                            <span
                                className="text-muted"
                                style={{ marginLeft: '16px', marginTop: '5px' }}
                            >
                                {this.props?.invoice?.workOrderNumber}
                            </span>
                        </FlexStartRow>
                        <FlexStartRow>
                            <FormLabel
                                text="Timesheets"
                            />
                            <span
                                className="text-muted"
                                style={{ marginLeft: '16px', marginTop: '5px' }}
                            >
                                {this.props?.timesheetNumbers}
                            </span>
                        </FlexStartRow>
                    </div>

                    <label>Messages</label>

                    <SimpleTable
                        id={'messagesTable'}
                        noTopBorder={true}
                        permission={'contract'}
                        tableHeaderLabels={[
                            {
                                name: ' '
                            },
                            {
                                name: 'From'
                            },
                            {
                                name: 'Message'
                            }

                        ]}
                        editable={false}
                        entities={this.props.messages}
                        rowRenderer={
                            this.renderMessages
                        }
                        noDataText={'No messages found.'}
                    />
                </FormGroup>

                {this.props?.context === 'rejecting' && (this.props?.invoice?.invoiceStatus == "New"
                    || this.props?.invoice?.invoiceStatus == "Draft"
                    || this.props?.invoice?.invoiceStatus == "Approved"
                    || this.props?.invoice?.invoiceStatus == "Exported") &&
                    <>
                        <FormGroup>
                            <FormLabel
                                required={true}
                                text="Rejection Notes"
                            ></FormLabel>
                            <textarea
                                id="userMessage"
                                name="userMessage"
                                className="form-control"
                                onChange={(e) => {
                                    this.setState({ userMessage: e.target.value });
                                }}
                                value={this.state.userMessage}
                                required
                                placeholder="Enter notes regarding the rejection."
                                type="text"
                                maxLength="500"
                                rows="5"
                            />
                            <small className="text-danger">
                                Notes are required.
                            </small>
                        </FormGroup>

                        <FlexCenterRow className="mb-3">
                            <Button
                                size="md"
                                name="reject"
                                type="button"
                                color="danger"
                                onClick={this.onReject}>
                                {"Reject"}
                                </Button>
                        </FlexCenterRow>
                    </>
                }

                {this.props?.context === 'rejecting' && this.props?.invoice?.invoiceStatus == "Pending Review" &&
                    <>
                        <FormGroup>
                            <FormLabel
                                text="Rejection Notes"
                        ></FormLabel>
                            <textarea rows="8"
                                id="userMessage"
                                name="userMessage"
                                className="form-control"
                                onChange={(e) => {
                                    this.setState({ userMessage: e.target.value });
                                }}
                                value={this.state.userMessage}
                                required
                                placeholder="Enter notes regarding the rejection."
                                type="text"
                                maxLength="500"
                                rows="5"
                            />
                            <small className="text-danger">
                                If rejecting, notes are required.
                            </small>
                        </FormGroup>

                        <FlexCenterRow className="mb-3">
                            <Button
                                size="md"
                                name="accept"
                                type="button"
                                color="success"
                                onClick={this.onAccept}>
                                {"Accept"}
                            </Button>
                            &nbsp;&nbsp;&nbsp;
                            <Button
                                size="md"
                                name="reject"
                                type="button"
                                color="danger"
                                onClick={this.onReject}>
                                {"Reject"}
                            </Button>
                        </FlexCenterRow>
                    </>
                }

                {this.props?.context === 'rejecting' && this.props?.invoice?.invoiceStatus == "Rejected - Invoice Issue" &&
                    <>
                        <FormGroup>
                            <FormLabel
                                text="Notes"
                            ></FormLabel>
                            <textarea rows="8"
                                id="userMessage"
                                name="userMessage"
                                className="form-control"
                                onChange={(e) => {
                                    this.setState({ userMessage: e.target.value });
                                }}
                                value={ this.state.userMessage }
                                required
                                placeholder="Add a note"
                                type="text"
                                maxLength="500"
                                rows="5"
                            />
                        </FormGroup>

                        <FlexCenterRow className="mb-3">
                            <Button
                                size="md"
                                name="reject"
                                type="button"
                                color="success"
                                onClick={this.onRejectAddNote}>
                                {"Add Note"}
                            </Button>
                        </FlexCenterRow>
                    </>
                }

                
            </>
        )
    }
}