import React, { Fragment } from 'react';
//Contexts
import { Can } from '../Can'
import CommonContext, { ApiRoutes } from '../Common';
import { StatusForm } from '../common/Generic Status/StatusForm';
import { ApplicationPaths } from '../api-authorization/ApiAuthorizationConstants';
import { createDataSource, createGridOptions, DataGrid, indexCellRenderer } from '../common/dataGrid/DataGrid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faScrewdriver } from '@fortawesome/free-solid-svg-icons'
import { LinearProgress } from '@material-ui/core';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import { FlexRow, FlexStartRow, FlexEndRow, PageHeading, SmallButton } from '../common/forms/FormElements';
import { withRouter } from "react-router-dom";
import { Row, Col } from 'reactstrap';
import { util } from '../Util';
import { ChargeTypeUnit } from './ChargeTypeUnit';

class ChargeTypeUnitIndex extends React.Component {

    static contextType = CommonContext;

    // #region [ Constructor and Overrides ]

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            loading: true,
            chargeTypeUnit: null,
            readOnly: true,
            formValidated: false,
            errors: {},
            showChargeTypeUnitForm: false,
            selectedChargeTypeUnit: null,
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.onAddChargeTypeUnit = this.onAddChargeTypeUnit.bind(this);
        this.onEditChargeTypeUnit = this.onEditChargeTypeUnit.bind(this);
        this.onSaveChargeTypeUnit = this.onSaveChargeTypeUnit.bind(this);
    }

    componentDidMount() {
        this.populateState();
    }

    componentWillUnmount() {
    }

    async populateState() {
        const chargeTypeUnitId = this.props.match.params.id;

        var data = await Promise.all([
            !!chargeTypeUnitId ? util.fetch.js(ApiRoutes.chargeTypeUnit.byId(chargeTypeUnitId)) : new ChargeTypeUnit()
        ]);

        let gridOptions = createGridOptions(this);

        gridOptions.components = {
            statusFilter: DataGridSelectFilter,
            equipmentTypeFilter: DataGridSelectFilter,
        };

        gridOptions.columnDefs = [
            { headerName: "", valueGetter: "node.id", sortable: false, cellRenderer: indexCellRenderer },
            { colId: 'ChargeTypeUnit.Description', sortable: true, headerName: 'Description', field: 'description', sort: { direction: 'asc', priority: 0 } }
        ];

        let dataSource = createDataSource(ApiRoutes.chargeTypeUnit.search(), gridOptions);

        this.setState(state => {
            return {
                loading: false,
                chargeTypeUnit: data[0],
                readOnly: false,
                gridOptions: gridOptions,
                dataSource: dataSource
            }
        });
    }

    onClearErrorNotificationClicked = e => {
        e.stopPropagation();
        this.setState((state) => { return { errors: {} }; });
    }

    // #endregion

    async onSubmit() {

        //Clear any fluent api errors
        this.setState((state) => { return { errors: {} }; });

        let { chargeTypeUnit } = this.state;

        let url = chargeTypeUnit.id ? ApiRoutes.chargeTypeUnit.update(chargeTypeUnit.id) : ApiRoutes.chargeTypeUnit.create();
        let fetch_stat = chargeTypeUnit.id ? util.fetch.put : util.fetch.post

        let response = await fetch_stat(url, chargeTypeUnit).catch((err) => {
            if (err && err.validationMessages && (Object.keys(err.validationMessages) ?? []).length)
                this.setState((state) => { return { errors: err.validationMessages } });
        });

        if (response.ok) {

            var id = await response.json();

            //if we got this far, redirect to employee edit page.
            this.redirect(id);
        }
    }

    // #region [ Status Flyout for Add/Edit ]

    //Add New Status
    onAddChargeTypeUnit() {
        let { chargeTypeUnit } = this.state;
        let new_status = new ChargeTypeUnit();
        //This is a new status, embed the Id and description.
        new_status.id = chargeTypeUnit.id ?? 0;
        new_status.description = chargeTypeUnit.description;
        this.setState({
            selectedChargeTypeUnit: new_status,
            showChargeTypeUnitForm: true
        });
    }

    //Status table edit click handler
    onEditChargeTypeUnit(status) {
        this.setState({ selectedChargeTypeUnit: JSON.parse(JSON.stringify(status)), showChargeTypeUnitForm: true });
    }

    //After status save callback
    onSaveChargeTypeUnit(status) {
        if (!!status) {
            this.setState({ showChargeTypeUnitForm: false });
            //refresh the grid
        }
    }

    //Close status window
    onCloseChargeTypeUnit(response) {
        this.resetForm();
        this.props.onClose(response);
    }

    //Delete status shown
    onDeleteChargeTypeUnit = async e => {
        const { chargeTypeUnit } = this.state;
        let response = await util.fetch.delete(ApiRoutes.chargeTypeUnit.delete(chargeTypeUnit.id)).catch(this.onCatchFetchError);
        if (response)
            this.onClose(response);
    }

    // #endregion 

    redirect(id) {
        this.props.history.push(`${ApplicationPaths.chargeTypeUnit}/${id}`);
    }

    onInputChange = event => {
        let { chargeTypeUnit } = this.state;
        chargeTypeUnit.description = event.target.value;

        this.setState({
            chargeTypeUnit: chargeTypeUnit
        });
    }

    // #region [Render and Render Functions]



    render() {
        if (!!this.state.loading) return (<LinearProgress variant="indeterminate" color="secondary" />)

        let { rowData, gridOptions } = this.state;

        return (
            <Fragment>
                <PageHeading>
                    <FontAwesomeIcon icon={faScrewdriver} className="mr-2 text-muted" />
                    <span>Charge Type Unit</span>
                </PageHeading>
                <Row>
                    <Col>
                        <FlexRow>
                            <FlexStartRow>
                            </FlexStartRow>
                            <FlexEndRow>
                                <Can I="create,view,edit" a="equipment">
                                    <CommonContext.Consumer>
                                        {(value) => {
                                            return (
                                                <SmallButton
                                                    type="button"
                                                    disabled={!!value.formIsOpen}
                                                    hidden={!this.state.selectedChargeTypeUnit}
                                                    onClick={this.onEditChargeTypeUnit}
                                                >
                                                    <i className="fa fa-edit fa-md mr-2" />View Selected
                                                </SmallButton>);
                                        }}
                                    </CommonContext.Consumer>
                                </Can>
                                <Can I="create" a="equipment">
                                    <CommonContext.Consumer>
                                        {(value) => {
                                            return (
                                                <SmallButton
                                                    type="button"
                                                    disabled={!!value.formIsOpen}
                                                    onClick={this.onAddChargeTypeUnit}
                                                >
                                                    <i className="fa fa-plus-circle fa-md mr-2" />Add Charge Type Unit
                                                </SmallButton>);
                                        }}
                                    </CommonContext.Consumer>
                                </Can>
                            </FlexEndRow>
                        </FlexRow>
                    </Col>
                </Row>
                <DataGrid
                    domLayout={"normal"}
                    rowData={rowData}
                    gridOptions={gridOptions}>
                </DataGrid>
                <StatusForm
                    status={this.state.selectedChargeTypeUnit}
                    show={this.state.showChargeTypeUnitForm}
                    onClose={() => this.setState({ showChargeTypeUnitForm: false })}
                    onSaveCallback={this.onSaveChargeTypeUnit}
                    statusName='Charge Type Unit'
                    route={"chargeTypeUnit"}
                />
            </Fragment>
        );

    }

    // #endregion
}
export default withRouter(ChargeTypeUnitIndex);