import React, { Fragment } from 'react';
import CommonContext from '../Common';
import { CircularProgress } from '@material-ui/core';
import { FlexColumnStart, FlexStartRow } from '../common/forms/FormElements';

export class SignatureReviewTable extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true
        }
    }

    componentDidMount() {
        this.populateState();
    }

    async populateState() {
        this.setState({
            loading: false
        });
    }

    render() {
        if (this.state.loading) {
            return (<CircularProgress color="secondary" variant="indeterminate" />)
        } else {            
            return (
                <Fragment>  
                    <table className="table table-sm table-bordered signature-review-table mb-0">
                        <thead>
                            <tr>
                                <th>Description</th>
                                <th>Charge Type</th>
                                <th>Quantity</th>
                                <th>Units</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!(this.props.timeCharges ?? []).length
                                ? <tr><td colSpan="4" className="text-center">No employee time charges found.</td></tr>
                                : this.props.timeCharges.map((timeCharge, i) => {
                                    return (
                                        <tr key={timeCharge.id}>
                                            <td>{timeCharge.description}</td>
                                            <td>
                                                <FlexColumnStart>
                                                    <FlexStartRow>{timeCharge.chargeType}</FlexStartRow>
                                                    {
                                                        !!timeCharge.startTime &&
                                                        <FlexStartRow>
                                                            <small className="text-info font-weight-bold">
                                                                {`${timeCharge.startTime} - ${timeCharge.endTime}`}
                                                            </small>
                                                        </FlexStartRow>
                                                    }
                                                </FlexColumnStart>

                                            </td>
                                            <td>{timeCharge.isBreak
                                                ?
                                                <span className='text-danger'>
                                                    {timeCharge.value}
                                                </span>
                                                :
                                                    timeCharge.value 
                                            }
                                            </td>
                                            <td>{timeCharge.chargeTypeUnit}</td>
                                        </tr>
                                    )
                                })}
                        </tbody>
                    </table>
                </Fragment>
            );
        }
    }
}

SignatureReviewTable.contextType = CommonContext;