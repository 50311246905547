import React, { Fragment } from 'react';
import {
    AppPageForm,
    FlexCenterRow,
    /*FlexEndRow,*/ FormDivider,
    onFieldChange,
    SubHeading,
    toasty,
} from '../common/forms/FormElements';
import { BaseFormViewModel } from '../common/ViewModel';
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import { faSave, faWarehouse } from '@fortawesome/free-solid-svg-icons';
import { FormLabel, FormCheckbox } from '../common/forms/FormElements';
import { PaySchedule } from './PaySchedule';
import { Button, Input, FormGroup, Row, Col } from 'reactstrap';
import { util } from '../Util';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { handleFormSaveError } from '../common/forms/ValidationError';
import { withRouter } from 'react-router-dom';
import NumericInput from '../common/forms/NumericInput';

class PayScheduleForm extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();

        let stateBase = Object.assign(
            {
                paySchedule: new PaySchedule(),
            },
            new BaseFormViewModel()
        );

        this.state = stateBase;
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount = () => this.populateState();
    componentDidUpdate = (prevProps, prevState) => {
        if (
            prevProps &&
            this.props.match.params.id !== (prevProps.match.params ?? {}).id
        ) {
            this.populateState();
        }
    };

    async populateState() {
        const payScheduleId = this.props.match.params.id;

        let paySchedule = !!payScheduleId
            ? await util.fetch.js(ApiRoutes.paySchedule.byId(payScheduleId))
            : new PaySchedule();

        if (paySchedule.countyIds != null)
            paySchedule.counties = paySchedule.countyIds.join(',');

        let originalData = util.object.clone(payScheduleId);

        const [usStates, usCounties] = await Promise.all([
            util.fetch.js(ApiRoutes.typeAheads.USStates()),
            util.fetch.js(ApiRoutes.USCounties()),
        ]);

        this.setState((state) => {
            return {
                originalData: originalData,
                paySchedule: paySchedule,
                loading: false,
                saving: false,
                usStates: usStates,
                usCounties: usCounties,
            };
        });
    }

    onUSStateChanged = (selection) => {
        let { paySchedule } = this.state;

        paySchedule.stateId = selection.id;
        paySchedule.countyIds = [];
        paySchedule.counties = '';

        this.setState({
            paySchedule: paySchedule,
        });
    };

    onCountiesChanged = (selection) => {
        let { paySchedule } = this.state;

        paySchedule.countyIds = selection?.map((x) => x.id);

        paySchedule.counties = '';
        if (paySchedule.countyIds != null && paySchedule.countyIds.length > 0)
            paySchedule.counties = paySchedule.countyIds.join(',');

        this.setState({
            paySchedule: paySchedule,
        });
    };

    onChange = onFieldChange;
    handleSaveError = (err) => handleFormSaveError(this, err);
    onClearErrors = () =>
        this.setState((state) => {
            return { errors: {} };
        });
    setSaving = (b) => this.setState({ saving: b });

    onSubmit = async (e) => {
        //Clear any fluent api errors
        this.onClearErrors();
        this.setSaving(true);

        this.props.location.pathname == AppNavPaths.PayScheduleNew
            ? this.create()
            : this.update();
    };

    update = async () => {
        let { paySchedule } = this.state;

        let resp = await util.fetch.put(
            ApiRoutes.paySchedule.update(paySchedule.id),
            paySchedule
        );

        if (!!resp) {
            this.props.history.push(AppNavPaths.PaySchedule);
        } else {
            toasty.error('Error saving pay schedule');
            this.setState({ saving: false });
        }
    };

    create = async () => {
        let { paySchedule } = this.state;
        paySchedule.id = 0;

        let resp = await util.fetch.put(
            ApiRoutes.paySchedule.update(0),
            paySchedule
        );

        if (!!resp) {
            this.props.history.push(AppNavPaths.PaySchedule);
        } else {
            toasty.error('Error adding pay schedule');
            this.setState({ saving: false });
        }
    };

    resetForm = () => this.setState({ formValidated: false });

    render() {
        const {
            paySchedule,
            originalData,
            usStates,
            usCounties,
            errors,
            formValidated,
            saving,
        } = this.state;

        let existing = !!paySchedule.id;

        return (
            <Fragment>
                <AppPageForm
                    formShown={this.context.formIsOpen}
                    formId={'groupForm'}
                    formHeadingIcon={faWarehouse}
                    formHeading={
                        !paySchedule.id
                            ? 'New Pay Schedule'
                            : 'Edit Pay Schedule'
                    }
                    formName={'groupForm'}
                    formRef={this.formRef}
                    onSubmit={this.onSubmit}
                    setIsValidated={(value) => {
                        this.setState({ formValidated: value });
                    }}
                    isValidated={formValidated}
                    saving={saving}
                    errors={errors}
                    onClearErrors={this.onClearErrors}
                    loading={this.state.loading}
                >
                    <br />
                    <SubHeading>Details</SubHeading>
                    <Row className="pt-3">
                        <Col
                            xl="6"
                            lg="8"
                            md="10"
                            sm="12"
                            className="ml-auto mr-auto"
                        >
                            <FormGroup>
                                <FormLabel
                                    htmlFor="groupName"
                                    text="Name"
                                    required={true}
                                />
                                <Input
                                    id="governingBody"
                                    name="paySchedule.governingBody"
                                    value={paySchedule.governingBody ?? ''}
                                    onChange={this.onChange}
                                    placeholder="Enter Name (max 150 characters)"
                                    maxLength="150"
                                    pattern="[^()/><\][\\\x22,;|]+"
                                    type="text"
                                    required
                                />
                                <small className="invalid-feedback text-danger">
                                    Description is required and can only contain
                                    the following special characters: hyphens
                                    and periods.
                                </small>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel
                                    htmlFor={`state`}
                                    text="State"
                                    required={true}
                                />
                                <ValidatedSelect
                                    id={`state`}
                                    name={`state`}
                                    required
                                    options={usStates}
                                    value={
                                        (usStates ?? []).find(
                                            (s) => s.id === paySchedule.stateId
                                        ) ?? ''
                                    }
                                    onChange={this.onUSStateChanged}
                                    validationMessage="A state selection is required."
                                />
                            </FormGroup>
                            <FormGroup>
                                <FormLabel htmlFor="type" text={'Counties'} />
                                <ValidatedSelect
                                    id="counties"
                                    name={`counties`}
                                    isMulti
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    options={(usCounties ?? []).filter(
                                        (c) =>
                                            c.usStateId == paySchedule.stateId
                                    )}
                                    value={
                                        (usCounties ?? []).filter((x) =>
                                            (
                                                paySchedule.countyIds ?? []
                                            ).includes(x.id)
                                        ) ?? ''
                                    }
                                    onChange={this.onCountiesChanged}
                                />
                            </FormGroup>

                            <Row>
                                <Col>
                                    <FormGroup>
                                        <FormLabel
                                            htmlFor="type"
                                            text={'Reg'}
                                        />
                                        <Input
                                            id="reg"
                                            name="paySchedule.reg"
                                            value={paySchedule.reg ?? 0}
                                            onChange={this.onChange}
                                            maxLength="100"
                                            type="number"
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <FormLabel htmlFor="type" text={'OT'} />
                                        <Input
                                            id="ot"
                                            name="paySchedule.ot"
                                            value={paySchedule.ot ?? 0}
                                            onChange={this.onChange}
                                            maxLength="100"
                                            type="number"
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <FormLabel
                                            htmlFor="type"
                                            text={'Fringe'}
                                        />
                                        <Input
                                            id="ot"
                                            name="paySchedule.fringe"
                                            value={paySchedule.fringe ?? 0}
                                            onChange={this.onChange}
                                            maxLength="100"
                                            type="number"
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <FormGroup>
                                        <FormCheckbox
                                            small={true}
                                            id={'useDailyOT'}
                                            checked={paySchedule.useDailyOT}
                                            onChange={(e) => {
                                                let { paySchedule } =
                                                    this.state;
                                                paySchedule.useDailyOT =
                                                    e.target.checked;
                                                this.setState({
                                                    paySchedule: paySchedule,
                                                });
                                            }}
                                            labelText={'Daily Overtime'}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    {paySchedule.useDailyOT && (
                                        <FormGroup>
                                            <FormLabel
                                                htmlFor="dailyOTThreshold"
                                                text="Daily Overtime Threshold"
                                                required={true}
                                            />
                                            <NumericInput
                                                id="dailyOTThreshold"
                                                name="paySchedule.dailyOTThreshold"
                                                value={
                                                    paySchedule.dailyOTThreshold ??
                                                    ''
                                                }
                                                onChange={this.onChange}
                                                placeholder="Hours"
                                                type="number"
                                                required
                                            />
                                        </FormGroup>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <FormDivider />

                    <FlexCenterRow className="mb-3">
                        <Button
                            disabled={!!this.state.saving}
                            size="sm"
                            type="submit"
                            color="primary"
                            name="groupForm"
                        >
                            <FontAwesomeIcon className="mr-2" icon={faSave} />
                            {!!this.state.saving
                                ? 'Saving...'
                                : !existing
                                ? 'Save New Pay Schedule'
                                : 'Save'}
                        </Button>
                        <Button
                            disabled={!!this.state.saving}
                            size="sm"
                            type="button"
                            color="secondary"
                            className="ml-3"
                            onClick={() =>
                                this.props.history.push(AppNavPaths.PaySchedule)
                            }
                        >
                            <span className="mr-2 fa fa-long-arrow-alt-left"></span>
                            Back to Pay Schedules
                        </Button>
                    </FlexCenterRow>
                </AppPageForm>
            </Fragment>
        );
    }
}
export default withRouter(PayScheduleForm);
