import React, { Fragment } from 'react';
import CommonContext, { ApiRoutes } from '../Common';
import { FormGroup, Input } from 'reactstrap';
import { util } from '../Util';
import { FormLabel } from '../common/forms/FormElements';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import cls from 'classnames';

export default class AddressInput extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.address1Input = React.createRef();
        this.state = {
            usStates: [],
        };
    }

    componentDidMount() {
        this.populateState();
    }

    async populateState() {
        const [usStates] = await Promise.all([
            util.fetch.js(ApiRoutes.typeAheads.USStates()),
        ]);

        this.setState({
            usStates,
        });
    }

    focusAddress = () => {
        //I dont know why this doesnt work
        //this.address1Input.current.focus();
        document.getElementById(this.address1Input.current.props.id).focus();
    };

    getCountyName = () => {
        const { address, usCounties, isCountyCaptureEnabled } = this.props;
        if (!isCountyCaptureEnabled) {
            return '';
        }

        const county = usCounties.find((usc) => usc.id == address.usCountyId);

        if (!county) {
            return 'Not Set';
        }

        return county.name;
    };

    render() {
        const { address, namePrefix, isCountyCaptureEnabled } = this.props;
        const { usStates } = this.state;
        const prefixName = !!namePrefix ? namePrefix + '.' : '';
        const colClass = cls(this.props.className ?? '', {
            'col-6': !this.props.single,
        });

        const countyName = this.getCountyName();

        return (
            <Fragment>
                <FormGroup className={colClass}>
                    <FormLabel
                        htmlFor={`address1`}
                        text="Address Line 1"
                        required={true}
                    />
                    <Input
                        id={`address1`}
                        name={`${prefixName}address1`}
                        ref={this.address1Input}
                        onFocus={(e) => {
                            e.currentTarget.select();
                        }}
                        value={address.address1 ?? ''}
                        onChange={this.props.onChange}
                        placeholder="Enter Address Line 1"
                        type="text"
                        required
                    />
                    <small className="invalid-feedback text-danger">
                        Address Line 1 is required.
                    </small>
                </FormGroup>
                <FormGroup className={colClass}>
                    <FormLabel htmlFor={`address2`} text="Address Line 2" />
                    <Input
                        id={`address2`}
                        name={`${prefixName}address2`}
                        value={address.address2 ?? ''}
                        onChange={this.props.onChange}
                        placeholder="Enter Address Line 2"
                        type="text"
                    />
                </FormGroup>
                <FormGroup className={colClass}>
                    <FormLabel htmlFor={`city`} text="City" required={true} />
                    <Input
                        id={`city`}
                        name={`${prefixName}city`}
                        required
                        placeholder="Enter City"
                        value={address.city ?? ''}
                        onChange={this.props.onChange}
                        type="text"
                    />
                    <small className="invalid-feedback text-danger">
                        City is required.
                    </small>
                </FormGroup>
                <FormGroup className={colClass}>
                    <FormLabel htmlFor={`state`} text="State" required={true} />
                    <ValidatedSelect
                        id={`state`}
                        name={`${prefixName}state`}
                        required
                        options={usStates}
                        value={
                            usStates.find((s) => s.value === address.state) ??
                            ''
                        }
                        onChange={this.props.onStateChanged}
                        validationMessage="A state selection is required."
                    />
                </FormGroup>
                <FormGroup className={colClass}>
                    <FormLabel
                        htmlFor={`zip`}
                        text="ZIP Code"
                        required={true}
                    />
                    <Input
                        id={`zip`}
                        name={`${prefixName}zip`}
                        placeholder="Enter ZIP Code"
                        type="text"
                        pattern="(\d{5}([\-]\d{4})?)"
                        value={address.zip ?? ''}
                        onChange={this.props.onChange}
                        required
                    />
                    <small className="invalid-feedback text-danger">
                        ZIP Code is required.
                    </small>
                </FormGroup>

                {isCountyCaptureEnabled && (
                    <FormGroup className={colClass}>
                        <Input
                            type="hidden"
                            name={`${prefixName}usCountyId`}
                            value={address.usCountyId ?? ''}
                        />

                        <FormLabel htmlFor={`countyName`} text="County" />
                        <Input
                            id={`countyName`}
                            type="text"
                            pattern="(\d{5}([\-]\d{4})?)"
                            value={countyName}
                            readOnly={true}
                        />
                    </FormGroup>
                )}
            </Fragment>
        );
    }
}
