import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { YesNoBadge } from '../common/forms/FormElements';
import CommonContext from '../Common';
import { CircularProgress } from '@material-ui/core';

export class QBIIChargeTypeGrid extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            showForm: false
        }
    }

    componentDidMount() {
        this.populateState();
    }

    async populateState() {
        this.setState({
            loading: false
        });
    }

    render() {
        if (this.state.loading) {
            return (<CircularProgress color="secondary" variant="indeterminate" />)
        } else {
            let { chargeTypes } = this.props;

            return (
                <Container fluid>
                    <Row>
                        <Col className="ml-0 mr-0">
                            <table className="table table-sm table-bordered">
                                <thead>
                                    <tr>
                                        <th rowSpan="2">Charge Type</th>
                                        <th rowSpan="2">Effective Date</th>
                                        <th rowSpan="2">Expiration Date</th>
                                        <th rowSpan="2">Is Active?</th>
                                        <th colSpan="3">Rates</th>
                                    </tr>
                                    <tr>
                                        <th>Normal</th>
                                        <th>Overtime</th>
                                        <th>Alternative</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!(chargeTypes ?? []).length
                                        ? <tr><td colSpan="10" className="text-center">No Charge Types Found.</td></tr>
                                        : chargeTypes.map((cct, i) => {
                                            return (
                                                <tr key={cct.id}>
                                                    <td>{cct.chargeType}</td>
                                                    <td>{cct.effectiveDate}</td>
                                                    <td>{cct.expirationDate}</td>
                                                    <td><YesNoBadge value={cct.isActive} /></td>
                                                    <td>{cct.rate}</td>
                                                    <td>{cct.overtimeRate}</td>
                                                    <td>{cct.alternativeRate}</td>
                                                </tr>
                                            )
                                        })}
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </Container>
            );
        }
    }
}