import CommonContext, { MomentDateFormat, ApiRoutes } from '../Common';
import SlideForm from '../common/forms/SlideForm';
import React from 'react';
import { Row, Col, FormGroup, Input } from 'reactstrap';
import { util } from '../Util';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { FormLabel, ValidationErrorMessage, FormCheckbox } from '../common/forms/FormElements';
import * as moment from 'moment';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import { getTenantUserProfile } from '../common/TenantUserProfile';
import { NotAuthorizedPage } from '../status/StatusCodes';
//import cls from 'classnames';
//import NumericInput from '../common/forms/NumericInput';

export default class BillingRateForm extends React.Component {

    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            loading: true,
            errors: {},
            formValidated: false,
            validationMessage: '',
            isValidated: false,
            inventoryItems: [],
            associateInventoryItem: false
        }
    }

    componentDidMount() {
        this.populateState();
    }

    async populateState() {

        const { userTenant } = await getTenantUserProfile();
        const { tenantSettings } = { ...userTenant };

        var [inventoryItemsRaw] = await Promise.all([
            util.fetch.js(ApiRoutes.typeAheads.quickBooksInventoryItems())
        ]);

        let inventoryItems = [];

        if ((inventoryItemsRaw ?? []).length) {
            inventoryItems = inventoryItemsRaw.map(x => { return { label: x.name, value: x.id } });
        }

        this.setState(state => {
            return {
                loading: false,
                inventoryItems: inventoryItems,
                associateInventoryItem: tenantSettings.useQuickBooksInvoicing
            }
        });
    }

    resetForm = () => this.setState({ formValidated: false, errors: {} });

    onSubmit = e => {
        this.context.setFormOpened(false);
        this.props.onSaveCallback();
    }

    onClose = (response) => {
        this.resetForm();
        this.context.setFormOpened(false);
        this.props.onClose(response);
    }

    onRemove = e => {
        this.context.setFormOpened(false);
        this.props.onRemoveCallback();
    }

    render() {

        const { tenantSettings } = ((this.context ?? {}).tenant ?? {});

        if(!tenantSettings.billingRatesEnabled) return <NotAuthorizedPage />


        if (!this.props.entity) return '';
        let entity = this.props.entity,
            name = (this.props.entityName ? `${this.props.entityName}.` : ''),
            chargeTypes = (this.props.chargeTypes ?? []),
            inventoryItems = (this.state.inventoryItems ?? []),
            chargeTypeReadonly = this.props.chargeTypeReadonly

        return (
            <SlideForm
                size="col-xl-9 col-md-9 col-xs-9"
                loading={this.state.loading}
                show={this.props.show}
                id={"billingRateForm"}
                formIcon={faMinusCircle}
                formTitle={!!parseInt(this.props.entity.id ?? 0) > 0 ? "Edit Rate" : "Add Rate"}
                ref={this.formRef}
                setIsValidated={(value) => { this.setState({ formValidated: value }) }}
                isValidated={this.state.formValidated}
                onSubmit={this.onSubmit}
                onClose={this.onClose}
                errors={this.state.errors}
                onClearErrors={this.onClearErrors}
                validationMessage={this.state.validationMessage}
                showDelete={this.props.showRemove === true}
                onDelete={this.onRemove}
            >
                <>
                    {!!this.props.showChargeType == true &&
                        <>
                        <FormGroup>
                            <FormLabel
                                    className="ml-3"
                                    htmlFor="chargeTypesDropdown"
                                    text="Charge"
                                required={!entity.chargeTypeId} />
                            {!chargeTypeReadonly &&
                                <ValidatedSelect
                                    className="ml-3 w-auto"
                                    isClearable={false}
                                    id={`${name}chargeTypeId`}
                                    name={`${name}chargeTypeId`}
                                    required={true}
                                    value={chargeTypes.find(i => i.value == entity.chargeTypeId) ?? ''}
                                    options={chargeTypes ?? []}
                                    onChange={this.props.onSelectChange}
                                    readOnly={true}
                                    getOptionLabel={o => o.label}
                                    getOptionValue={o => o.value}
                                    validationMessage="A charge is required."
                                />
                            }
                            {chargeTypeReadonly &&
                                <Input
                                    className="ml-3 w-auto"
                                    readOnly={true}
                                    value={entity.chargeTypeName}
                                    type="text"
                                />
                            }
                        </FormGroup>
                        {!!(entity.chargeTypeId ?? {}) &&
                            <>
                            <FormGroup>
                                <FormLabel
                                    className="ml-3"
                                    text="Minimum Bill Hours" />
                                <Input
                                    className="ml-3 w-auto"
                                    readOnly={true}
                                    value={!!(chargeTypes.find(i => i.value == entity.chargeTypeId) ?? {}).minimumBillUnits ? (chargeTypes.find(i => i.value == entity.chargeTypeId) ?? {}).minimumBillUnits.toFixed(2) : 'N/A'}
                                    placeholder="Min Bill Hours"
                                    type="text"                                    
                                />
                            </FormGroup>
                            <FormGroup>
                                <FormLabel
                                    className="ml-3"
                                    text="Maximum Bill Hours" />
                                <Input
                                    className="ml-3 w-auto"
                                    readOnly={true}
                                    value={!!(chargeTypes.find(i => i.value == entity.chargeTypeId) ?? {}).maximumBillUnits ? (chargeTypes.find(i => i.value == entity.chargeTypeId) ?? {}).maximumBillUnits.toFixed(2) : 'N/A'}
                                    placeholder="Max Bill Hours"
                                    type="text"
                                />
                            </FormGroup>
                            </>
                        }
                        </>
                    }

                    <Row noGutters>
                        <Col xs="3">
                            <FormGroup>
                                <FormLabel
                                    className="ml-3"
                                    text="Rate"
                                    required={!entity.billingRate.rate} />
                                <Input
                                    className="ml-3 w-auto"
                                    id={`${name}billingRate.rate`}
                                    name={`${name}billingRate.rate`}
                                    value={entity.billingRate.rate ?? {}}
                                    onChange={this.props.onChange}
                                    placeholder="Enter Rate"
                                    type="number"
                                    step='0.01'
                                    required={true}
                                />
                                <ValidationErrorMessage>Rate is required.</ValidationErrorMessage>
                            </FormGroup>
                        </Col>
                        <Col xs={this.state.associateInventoryItem ? "6" : "9"}>
                            <FormGroup>
                                <FormLabel
                                    className="ml-3"
                                    text="Description" />
                                <Input
                                    className="ml-3 w-auto"
                                    id={`${name}billingRate.billingRaterateDescription`}
                                    name={`${name}billingRate.rateDescription`}
                                    value={entity.billingRate.rateDescription ?? ''}
                                    onChange={this.props.onChange}
                                    placeholder="Rate Description"
                                    maxLength="100"
                                    type="text"
                                />
                            </FormGroup>
                        </Col>
                        {/*If the tenant setting exists and there are inventory items to load*/}
                        {this.state.associateInventoryItem && inventoryItems.length &&
                            <Col xs="3">
                                <FormGroup>
                                    <FormLabel
                                        className="ml-3"
                                        htmlFor="rateQBInventoryItemId"
                                        text="QuickBooks Inventory Item"
                                        required={false} />
                                    <ValidatedSelect
                                        className="ml-3 w-auto"
                                        id="rateQBInventoryItemId"
                                        name={`${name}billingRate.rateQBInventoryItemId`}
                                        options={inventoryItems}
                                        value={(inventoryItems ?? []).find(s => s.value == entity.billingRate.rateQBInventoryItemId) ?? ''}
                                        onChange={this.props.onSelectChange}
                                    />
                                </FormGroup>
                            </Col>
                        }
                    </Row>
                   
                    <Row noGutters>
                        <Col xs="3">
                            <FormGroup>
                                <FormLabel
                                    className="ml-3"
                                    text="Overtime Rate"
                                    required={false} />
                                <Input
                                    className="ml-3 w-auto"
                                    id={`${name}billingRate.otRate`}
                                    name={`${name}billingRate.otRate`}
                                    value={entity.billingRate.otRate ?? {}}
                                    onChange={this.props.onChange}
                                    placeholder="Enter Overtime Rate"
                                    type="number"
                                    step='0.01'
                                    required={false}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={this.state.associateInventoryItem ? "6" : "9"}>
                            <FormGroup>
                                <FormLabel
                                    className="ml-3"
                                    text="Description" />
                                <Input
                                    className="ml-3 w-auto"
                                    id={`${name}billingRate.otRateDescription`}
                                    name={`${name}billingRate.otRateDescription`}
                                    value={entity.billingRate.otRateDescription ?? ''}
                                    onChange={this.props.onChange}
                                    placeholder="Rate Description"
                                    maxLength="100"
                                    type="text"
                                />
                            </FormGroup>
                        </Col>
                        {/*If the tenant setting exists and there are inventory items to load*/}
                        {this.state.associateInventoryItem && inventoryItems.length &&
                            <Col xs="3">
                                <FormGroup>
                                    <FormLabel
                                        className="ml-3"
                                        htmlFor="otRateQBInventoryItemId"
                                        text="QuickBooks Inventory Item"
                                        required={false} />
                                    <ValidatedSelect
                                        className="ml-3 w-auto"
                                        id="otRateQBInventoryItemId"
                                        name={`${name}billingRate.otRateQBInventoryItemId`}
                                        options={inventoryItems}
                                        value={(inventoryItems ?? []).find(s => s.value == entity.billingRate.otRateQBInventoryItemId) ?? ''}
                                        onChange={this.props.onSelectChange}
                                    />
                                </FormGroup>
                            </Col>
                        }
                    </Row>
                    
                    <Row noGutters>
                        <Col xs="3">
                            <FormGroup>
                                <FormLabel
                                    className="ml-3"
                                    text="Alternative Rate"
                                    required={false} />
                                <Input
                                    className="ml-3 w-auto"
                                    id={`${name}billingRate.altRate`}
                                    name={`${name}billingRate.altRate`}
                                    value={entity.billingRate.altRate ?? {}}
                                    onChange={this.props.onChange}
                                    placeholder="Enter Alternative Rate"
                                    type="number"
                                    step='0.01'
                                    required={false}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={this.state.associateInventoryItem ? "6" : "9"}>
                            <FormGroup>
                                <FormLabel
                                    className="ml-3"
                                    text="Description" />
                                <Input
                                    className="ml-3 w-auto"
                                    id={`${name}billingRate.altRateDescription`}
                                    name={`${name}billingRate.altRateDescription`}
                                    value={entity.billingRate.altRateDescription ?? ''}
                                    onChange={this.props.onChange}
                                    placeholder="Rate Description"
                                    maxLength="100"
                                    type="text"
                                />
                            </FormGroup>
                        </Col>
                        {/*If the tenant setting exists and there are inventory items to load*/}
                        {this.state.associateInventoryItem && inventoryItems.length &&
                            <Col xs="3">
                                <FormGroup>
                                    <FormLabel
                                        className="ml-3"
                                        htmlFor="altRateQBInventoryItemId"
                                        text="QuickBooks Inventory Item"
                                        required={false} />
                                    <ValidatedSelect
                                        className="ml-3 w-auto"
                                        id="altRateQBInventoryItemId"
                                        name={`${name}billingRate.altRateQBInventoryItemId`}
                                        options={inventoryItems}
                                        value={(inventoryItems ?? []).find(s => s.value == entity.billingRate.altRateQBInventoryItemId) ?? ''}
                                        onChange={this.props.onSelectChange}
                                    />
                                </FormGroup>
                            </Col>
                        }
                    </Row>
                    
                    <FormGroup>
                        <FormLabel
                            className="ml-3"
                            text="Effective Date"
                            required={true} />
                        <Input
                            className="ml-3 w-auto"
                            id={`${name}billingRate.effectiveDate`}
                            name={`${name}billingRate.effectiveDate`}                            
                            onChange={this.props.onChange}
                            value={moment(entity.billingRate.effectiveDate).format(MomentDateFormat.DateOnlyUTCHyphenated) ?? ''}                           
                            type="date"
                            required={!entity.billingRate.effectiveDate}
                        />
                        <ValidationErrorMessage>Effective Date is required.</ValidationErrorMessage>
                    </FormGroup>
                    <FormGroup>
                        <FormLabel
                            className="ml-3"
                            text="Expiration Date"
                            required={false} />
                        <Input
                            className="ml-3 w-auto"
                            id={`${name}billingRate.expirationDate`}
                            name={`${name}billingRate.expirationDate`}
                            value={moment(entity.billingRate.expirationDate).format(MomentDateFormat.DateOnlyUTCHyphenated) ?? ''}
                            onChange={this.props.onChange}
                            type="date"
                            required={false}
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel
                            className="ml-3"
                            text="Active"
                            required={false} />
                        <FormCheckbox
                            className="ml-3 w-auto"
                            id={`${name}billingRate.current`}
                            name={`${name}billingRate.current`}
                            checked={entity.billingRate.current ?? ''}
                            onChange={this.props.onChange}
                            labelText={"Active"}
                        />
                    </FormGroup>
                </>
            </SlideForm>
        );
    }
}