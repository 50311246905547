import React from 'react';
import { CircularProgress } from "@material-ui/core"

export default class CustomCircularProgress extends React.Component {
    render() {
        return (
            <div className="h-100 w-100 d-flex flex-column flex-nowrap align-items-center justify-content-center">
                <CircularProgress color="secondary" variant="indeterminate" />
                { !!this.props.label && <span className="mt-3 w-100 text-center font-weight bold text-muted">{this.props.label}</span>}
            </div>
        )
    }
}