import React from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarTimes } from '@fortawesome/free-solid-svg-icons';
import cls from 'classnames';

export default class LongTermStatusIndicator extends React.Component {
    getEmployeeLongTermStatus = () => {
        const { staff, employeeLongTermStatuses } = this.props;

        if (
            !staff ||
            !employeeLongTermStatuses ||
            !employeeLongTermStatuses.length
        ) {
            return null;
        }

        return employeeLongTermStatuses.find(
            (elts) => staff.id == elts.employeeId
        );
    };

    getEffectiveLongTermStatus = () => {
        const { longTermStatuses } = this.props;

        if (!longTermStatuses || !longTermStatuses.length) {
            return null;
        }

        const employeeLongTermStatus = this.getEmployeeLongTermStatus();

        if (!employeeLongTermStatus) {
            return null;
        }

        return longTermStatuses.find(
            (lts) => employeeLongTermStatus.longTermStatusId == lts.id
        );
    };

    getEffectiveLongTermStatusName = (effectiveLongTermStatus) => {
        return Boolean(effectiveLongTermStatus)
            ? effectiveLongTermStatus.name
            : null;
    };

    getStatusDateRange = () => {
        const employeeLongTermStatus = this.getEmployeeLongTermStatus();

        if (!employeeLongTermStatus) {
            return null;
        }

        const startDate = moment(employeeLongTermStatus.startDate)
            .toDate()
            .toLocaleDateString();

        if (!employeeLongTermStatus.endDate) {
            return `(${startDate} - Current)`;
        }

        const endDate = moment(employeeLongTermStatus.endDate)
            .toDate()
            .toLocaleDateString();

        return `(${startDate} - ${endDate})`;
    };

    getExtraClass = (effectiveLongTermStatus) => {
        return Boolean(
            effectiveLongTermStatus && !effectiveLongTermStatus.allowDispatch
        )
            ? 'text-danger'
            : '';
    };

    render = () => {
        const effectiveLongTermStatus = this.getEffectiveLongTermStatus();

        if (!effectiveLongTermStatus) {
            return null;
        }

        const longTermStatusName = this.getEffectiveLongTermStatusName(
            effectiveLongTermStatus
        );

        const dateRange = this.getStatusDateRange();

        const extraClass = this.getExtraClass(effectiveLongTermStatus);

        return (
            <FontAwesomeIcon
                icon={faCalendarTimes}
                className={cls('cursor-pointer', extraClass)}
                title={`${longTermStatusName} - ${dateRange}`}
            />
        );
    };
}
