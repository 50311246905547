import CommonContext, { MomentDateFormat } from '../Common';
import SlideForm from '../common/forms/SlideForm';
import React from 'react';
import {FormGroup, Input } from 'reactstrap';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { FormLabel, ValidationErrorMessage } from '../common/forms/FormElements';
import * as moment from 'moment';
import { getTenantUserProfile } from '../common/TenantUserProfile';

export default class MinimumWageRateForm extends React.Component {

    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            loading: true,
            errors: {},
            formValidated: false,
            validationMessage: '',
            isValidated: false
        }
    }

    componentDidMount() {
        this.populateState();
    }

    async populateState() {

        const { userTenant } = await getTenantUserProfile();
        const { tenantSettings } = { ...userTenant };
              
        this.setState(state => {
            return {
                loading: false
            }
        });
    }

    resetForm = () => this.setState({ formValidated: false, errors: {} });

    onSubmit = e => {
        this.context.setFormOpened(false);
        this.props.onSaveCallback();
    }

    onClose = (response) => {
        this.resetForm();
        this.context.setFormOpened(false);
        this.props.onClose(response);
    }

    onRemove = e => {
        this.context.setFormOpened(false);
        this.props.onRemoveCallback();
    }

    render() {
        if (!this.props.entity) return '';
        let entity = this.props.entity,
            name = (this.props.entityName ? `${this.props.entityName}.` : '')

        return (
            <SlideForm
                size="col-xl-9 col-md-9 col-xs-9"
                loading={this.state.loading}
                show={this.props.show}
                id={"billingRateForm"}
                formIcon={faMinusCircle}
                formTitle={!!parseInt(this.props.entity.id ?? 0) > 0 ? "Edit Min. Wage Rate" : "Add Min. Wage Rate"}
                ref={this.formRef}
                setIsValidated={(value) => { this.setState({ formValidated: value }) }}
                isValidated={this.state.formValidated}
                onSubmit={this.onSubmit}
                onClose={this.onClose}
                errors={this.state.errors}
                onClearErrors={this.onClearErrors}
                validationMessage={this.state.validationMessage}
                showDelete={this.props.showRemove === true}
                onDelete={this.onRemove}
            >
                <>                    
                    
                    <FormGroup>
                        <FormLabel
                            className="ml-3"
                            text="Rate"
                            required={!entity.payRate} />
                        <Input
                            className="ml-3 w-auto"
                            id={`${name}payRate`}
                            name={`${name}payRate`}
                            value={entity.payRate ?? {}}
                            onChange={this.props.onChange}
                            placeholder="Enter Rate"
                            type="number"
                            step='0.01'
                            required={true}
                        />
                        <ValidationErrorMessage>Min. Wage Rate is required.</ValidationErrorMessage>
                    </FormGroup>                    
                    <FormGroup>
                        <FormLabel
                            className="ml-3"
                            text="Effective Date"
                            required={true} />
                        <Input
                            className="ml-3 w-auto"
                            id={`${name}startDate`}
                            name={`${name}startDate`}
                            onChange={this.props.onChange}
                            value={moment(entity.startDate).format(MomentDateFormat.DateOnlyUTCHyphenated) ?? ''}
                            type="date"
                            required={!entity.startDate}
                        />
                        <ValidationErrorMessage>Effective Date is required.</ValidationErrorMessage>
                    </FormGroup>
                    <FormGroup>
                        <FormLabel
                            className="ml-3"
                            text="Expiration Date"
                            required={false} />
                        <Input
                            className="ml-3 w-auto"
                            id={`${name}endDate`}
                            name={`${name}endDate`}
                            value={moment(entity.endDate).format(MomentDateFormat.DateOnlyUTCHyphenated) ?? ''}
                            onChange={this.props.onChange}
                            type="date"
                            required={false}
                        />
                    </FormGroup>
                </>
            </SlideForm>
        );
    }
}