import React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { Can } from '../Can'
import { SmallButton, YesNoBadge } from '../common/forms/FormElements';
import CommonContext from '../Common';
import { CircularProgress } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { util } from '../Util';


export class ChargeTypeConfigurationInfo extends React.Component {

    static contextType = CommonContext;

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            showForm: false
        }
    }

    componentDidMount() {
        this.populateState();
    }

    async populateState() {
        this.setState({
            loading: false
        });
    }

    render() {
        if (this.state.loading) {
            return (<CircularProgress color="secondary" variant="indeterminate" />)
        } else {

            let { exceptions, increments, customers } = this.props;

            return (
                <Container fluid>
                    <Row className="pb-1">
                        <Col className="ml-0 mr-0">
                            <div className="d-flex flex-row align-items-center justify-content-end">
                                <Can I="edit" a="chargetype">
                                    <CommonContext.Consumer>
                                        {(value) => {
                                            return (
                                                <SmallButton
                                                    type="button"
                                                    disabled={!!value.formIsOpen}
                                                    onClick={this.props.onAddException}
                                                >
                                                    <i className="fa fa-plus-circle fa-md mr-2" />Add Exception
                                                </SmallButton>);
                                        }}
                                    </CommonContext.Consumer>

                                </Can>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="ml-0 mr-0">
                            <table className="table table-sm table-bordered">
                                <thead>
                                    <tr>
                                        <th rowSpan="2">Customers</th>
                                        <th style={{ width: '75px' }} rowSpan="2">Increments</th>
                                        <th style={{ width: '75px' }} rowSpan="2">Break?</th>
                                        <th style={{ width: '75px' }} rowSpan="2">Break Paid?</th>
                                        <th  rowSpan="2">Break Desc</th>
                                        <th style={{ width: '75px' }} rowSpan="2">N/A</th>
                                        <th colSpan="2">Pay Units</th>
                                        <Can I="edit" a="chargetype">
                                            <th rowSpan="2" className="text-center" style={{ width: '30px' }}>
                                                <FontAwesomeIcon icon={faEdit} />
                                            </th>
                                        </Can>
                                    </tr>
                                    <tr>
                                        <th style={{ width: '75px' }}>Min</th>
                                        <th style={{ width: '75px' }}>Max</th>         
                                    </tr>
                                </thead>
                                <tbody>
                                    {!(exceptions ?? []).length
                                        ? <tr><td colSpan="9" className="text-center">No exceptions found.</td></tr>
                                        : this.props.exceptions.map((exception, i) => {
                                            return (
                                                <tr key={exception.id}>
                                                    <td className="d-flex flex-column">{
                                                        customers.length && (exception ?? {}).customers 
                                                            ? (<ul>{customers.filter(x => exception.customers.includes(x.value)).map(x => <li key={x.value}>{x.label}</li>)}</ul>)
                                                            : ''
                                                    }</td>
                                                    <td>{
                                                        this.props.chargeUnitId === 2
                                                            ? exception.increment
                                                            : ((increments ?? []).find(x => parseFloat(x.value) === parseFloat(exception.increment)) ?? {}).label
                                                    }</td>
                                                    <td>
                                                        {!util.isEmpty(exception.canBreak) && <YesNoBadge value={exception.canBreak} />}
                                                    </td>
                                                    <td>
                                                        {!util.isEmpty(exception.paidBreak) && <YesNoBadge value={exception.paidBreak} />}
                                                    </td>
                                                    <td>
                                                        {exception.breakDescription}
                                                    </td>
                                                    <td>
                                                        {exception.doesNotApply && <FontAwesomeIcon style={{ color: 'red'}} icon={faTimesCircle} />}
                                                    </td>
                                                    <td>
                                                        {!util.isEmpty(exception.minimumPayUnits) && util.number.formatFloat(exception.minimumPayUnits)}
                                                    </td>
                                                    <td>
                                                        {!util.isEmpty(exception.maximumPayUnits) && util.number.formatFloat(exception.maximumPayUnits)}
                                                    </td>
                                                    <Can I="edit" a="chargetype">
                                                        <td className="text-center" style={{ width: '30px' }}>                                                        
                                                            <CommonContext.Consumer>
                                                                {(value) => {
                                                                    return (<Button
                                                                        disabled={!!value.formIsOpen}
                                                                        type="button"
                                                                        color="outline-primary"
                                                                        onClick={() => this.props.onEditException(exception)}
                                                                        size="sm">Edit</Button>);
                                                                }}
                                                            </CommonContext.Consumer>                                                        
                                                        </td>
                                                    </Can>
                                                </tr>
                                            )
                                        })}
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </Container>
            );
        }
    }
}