import { faTimes } from '@fortawesome/fontawesome-free-solid';
import { faExclamationTriangle, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Dropzone from 'react-dropzone';
import { Button } from 'reactstrap';
import FileLink from './FileLink';
import { toasty } from './FormElements';
import cls from 'classnames';

export default class FileUpload extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            rejections: [],
            uploads: [],
            uploading: false            
        };
    }

    componentDidMount() {
        
    }

    validator = file => {
        if (file.name.length > this.props.maxNameLength) {
            return {
                code: "name-too-large",
                message: `Name is larger than ${this.props.maxNameLength} characters`
            };
        }
        return null
    }

    onDrop = (acceptedFiles, fileRejections, event) => {
        let { uploads, uploading } = { ...this.state };

        if (!!uploading) return false;

        //Upsert, newest wins.
        for (let af of acceptedFiles) {
            if (!uploads.filter(u => af.path === u.path).length)
                uploads.push({ file: af, progress: 0.0 });
            else
                uploads[uploads.find(x => x.file.path === af.path)] = af;
        }

        this.setState({ uploads: uploads, rejections: fileRejections ?? [] });
    }

    getUploads = () => ({ ...this.state.uploads });

    remove = (x) => {
        let { uploads } = { ...this.state };
        uploads.splice(uploads.findIndex(x => x.path === x.file.path), 1);
        this.setState({ uploads: uploads });
    }

    dismissError = file => {
        let { rejections } = { ...this.state };
        rejections.splice(rejections.findIndex(x => x.path === file.path), 1);
        this.setState({ rejections: rejections });
    }

    upload = async () => {
        await this.setState({ uploading: true });
        var files = [ ...this.state.uploads ];
        var url = this.props.url;
        const onPreSend = this.props.onPreSend;


        for (let x of files) {
            const index = files.indexOf(x);
            var formData = new FormData();
            formData.append('file', x.file, x.file.path);

            if (!!onPreSend) onPreSend(x, formData);

            var xhr = new XMLHttpRequest();
            xhr.open('POST', url, true);            

            xhr.upload.onprogress = (e) => this.onProgressUpdated(e, index);
            xhr.onload = (e) => this.onUploadComplete(e, index);
            xhr.send(formData);
        }
    }

    onProgressUpdated = (e, index) => {
        if (e.lengthComputable) {
            let { uploads } = { ...this.state };
            var percentage = Math.round((e.loaded / e.total) * 100);
            uploads[index].progress = percentage;
            this.setState({ uploads: uploads });
        }
        else {
            console.log("Unable to compute progress information since the total size is unknown");
        }
    }

    onUploadComplete = async (e, index) => {
        let { uploads } = { ...this.state };

        if (!uploads.filter(x => x.progress < 100.00).length) {
            toasty.success('File attachments uploaded.');
            await this.setState({ uploading: false, uploads: [] });
            !!this.props.onUploadComplete && this.props.onUploadComplete();
        }
    }

    render() {

        const { uploads, rejections, uploading } = { ...this.state };

        return (
            <Dropzone                
                {...this.props}
                onDrop={ this.onDrop }
                inputContent={(files, extra) => (extra.reject ? 'File type not permitted.' : 'Drag and drop your files here, or click to select')}
                validator={this.validator}
                maxSize={25000000} /*25MB application wide*/
            >
                {({ getRootProps, getInputProps }) => (
                    <>
                        {
                            !!(uploads ?? []).length &&
                            <div className="file-uploads-preview">
                                <small className="p-2 w-100 text-warning text-center">
                                    <FontAwesomeIcon icon={faExclamationTriangle} size="lg" className="mr-2" />
                                    Files with the same name as an existing attachment will be overwritten. This cannot be undone.
                                </small>
                                {
                                    uploads.map(x =>
                                    (
                                        <FileLink                                            
                                            preview={true}
                                            showFileSize={true}
                                            key={x.file.path}
                                            url={'#'}
                                            file={x.file}
                                            remove={() => this.remove(x)}
                                            progress={x.progress} />
                                        ))
                                }
                            </div>
                        }
                        <div className="file-upload-toolbar ">
                            {
                                !!(uploads ?? []).length &&
                                <Button
                                    disabled={ !!uploading }
                                    type="button"
                                    size="sm"
                                    className={cls("file-upload-button mb-2", { 'disabled': !!uploading })}
                                    color="primary"
                                    onClick={this.upload}
                                >
                                    <FontAwesomeIcon icon={faUpload} className="mr-2" size="lg" />
                                    {(!!uploading ? 'Uploading, please wait...' : 'Upload')}
                                </Button>
                            }
                        </div>
                        {
                            !!(rejections ?? []).length &&
                            <div className="file-uploads-preview mt-2">
                                {rejections.map(({ file, errors }) =>
                                    <div className="file-upload-error p-2 mb-1" key={file.path}>
                                        <div className="d-flex flex-row justify-content-between">
                                            <div className="file-upload-error-name"><FontAwesomeIcon icon={faExclamationTriangle} size="lg" className="mr-2" />{file.path}</div>
                                            <div className="flex-fill d-flex flex-row justify-content-end">
                                                <FontAwesomeIcon icon={faTimes} className="cursor-pointer" onClick={() => this.dismissError(file)} />
                                            </div>
                                        </div>
                                        <ul>
                                            {errors.map(e => (<li key={e.code}><small>{e.message}</small></li>))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        }
                        <section className="file-upload-section" hidden={ !!uploading }>
                            <div {...getRootProps({ className: 'dropzone' })} className="file-upload-section-inner">
                                <input {...getInputProps()} />
                                <span className="border-bottom mb-2">Drag and drop your files here, or click here to select from your device.</span>
                                <small>Supported file types:<span className="ml-2 text-success">{`${this.props.accept}`}</span></small>
                                <small>{`File names must be less than ${this.props.maxNameLength} characters.`}</small>
                            </div>
                        </section>                       
                    </>
                )}
                </Dropzone>
        );
    }
}