import React from 'react';
import './DispatchBoard.scss';
import { Weekdays } from '../Common';
import StaffJobAssignment from './StaffJobAssignment';
import EquipmentJobAssignment from './EquipmentJobAssignment';
import { FlexBetweenRow, FlexCenterRow } from '../common/forms/FormElements';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Modal, ModalBody, ModalHeader, UncontrolledTooltip } from 'reactstrap';

export default class DispatchJobAssignments extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedOverride: null,
        };
    }

    selectedDayHasOverride = (selectedDay) => {
        const { job } = this.props;

        if (!job.overrides || job.overrides.length === 0) {
            return false;
        }

        return job.overrides.find(override => moment(override.overrideDate, moment.ISO_8601).isSame(moment(selectedDay), 'date'));
    };

    handleOverrideButtonClick = (override) => {
        this.setState({ selectedOverride: override });
    }

    render = () => {
        const { selectedOverride } = this.state;
        const { job, selectedWeekRangeDays, jobIndex, tenantSettings, onRemoveDayJob } = { ...this.props };

        return (
            <>
                <table key={jobIndex} className="table table-sm table-bordered border-top-0 jobAssignmentsTable mb-0">
                    <thead>
                        <tr>
                            {
                                (selectedWeekRangeDays ?? []).map(day => {
                                    const dayInt = day.getDay();
                                    var showCancel = !!job.assignments[dayInt].canEdit && (!!job.assignments[dayInt].staff.length
                                        || !!job.assignments[dayInt].equipment.length)

                                    const override = this.selectedDayHasOverride(day);
                                    const { abbreviation } = Weekdays.find(x => x.id === dayInt);

                                    //if (!cancelled) {
                                        return (
                                            <th key={day}>
                                                <div className="w-100 job-assignment-table-header d-flex flex-row flex-nowrap">
                                                    <span className="text-left flex-fill">
                                                        {Boolean(override) ? (
                                                            <span role="button" onClick={() => this.handleOverrideButtonClick(override)}>
                                                                <FontAwesomeIcon
                                                                    id={`day-override-${job.id}-${override.id}`}
                                                                    icon={faExclamationTriangle}
                                                                    className="text-warning ml-1"
                                                                />

                                                                <span className="text-warning">
                                                                    {abbreviation}
                                                                </span>

                                                                <UncontrolledTooltip
                                                                    placement="top"
                                                                    target={`day-override-${job.id}-${override.id}`}
                                                                >
                                                                    The job details for this day have been changed.
                                                                </UncontrolledTooltip>
                                                            </span>
                                                        ) : abbreviation}
                                                    </span>

                                                    <span className="text-muted">{`${day.getMonth() + 1}/${day.getDate()}`}</span>
                                                    {showCancel &&
                                                        <span
                                                            title="Remove all assignments for this job"
                                                            className="text-danger cursor-pointer ml-2"
                                                            onClick={() => onRemoveDayJob(job, dayInt)}>
                                                            Cancel Job
                                                        </span>
                                                    }
                                                </div>
                                            </th>
                                    )
                                    //}
                                })                                
                            }
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {
                                (selectedWeekRangeDays ?? []).map(day => {
                                    const dayInt = day.getDay();
                                    const hasAsns = !!job.assignments[dayInt].staff.length || !!job.assignments[dayInt].equipment.length;

                                    return (
                                        <td key={day} style={!!job.assignments[dayInt].isEmergency ? { backgroundColor: "rgba(240, 191, 76, 0.2" } : {}}>
                                            <FlexCenterRow>
                                                {
                                                    !!hasAsns && !job.assignments[dayInt].canEdit &&
                                                    <div className="d-flex flex-column flex-fill">
                                                        <span className="site-link text-info" title="Once the timesheet has been filled out by the crew for this job, date, and start time, no further dispatching modifications can be done.">
                                                            <i className="mr-1 fa fa-clock"></i>Timesheet Complete
                                                        </span>
                                                    </div>
                                                }
                                                {
                                                    (
                                                        //job.canEdit is backed by whether or not the crew has submitted a timesheet.
                                                        //if we have a timesheet for this crew for this day, we cannot modify the assignment.
                                                        !!job.assignments[dayInt].canEdit && hasAsns
                                                    ) &&
                                                    <>
                                                        <div className="d-flex flex-column flex-fill">
                                                            <span
                                                                className="site-link"
                                                                onClick={() => this.props.onEditJobWorkflowClicked(job, dayInt)}>
                                                                <i className="mr-1 fa fa-recycle"></i>Edit Job Workflow
                                                            </span>
                                                        </div>
                                                        {!!tenantSettings.timesheetEmergencyEnabled && job.canEmergency &&
                                                            <div className="d-flex flex-column flex-fill">
                                                                <span
                                                                    className="site-link"
                                                                    style={!job.assignments[dayInt].isEmergency ? { color: "grey" } : {}}
                                                                    onClick={() => this.props.onEmergencyClicked(job, dayInt)}>
                                                                    <i className="mr-1 fa fa-ambulance"></i>Emergency
                                                                </span>
                                                            </div>
                                                        }
                                                    </>
                                                }
                                            </FlexCenterRow>
                                            <div className="assignmentsWrapper">
                                                <div className="assignments">
                                                    {
                                                        //Staff-employee assignments
                                                        //_.sortBy(job.assignments[dayInt].staff, s => s.name).map((group, index) => {
                                                        job.assignments[dayInt].staff.map((group, index) => {
                                                            let key = `${jobIndex}_${dayInt}_staff_${index}`;
                                                            return (
                                                                <StaffJobAssignment
                                                                    key={key}
                                                                    job={job}
                                                                    jobIndex={jobIndex}
                                                                    day={day}
                                                                    assignmentIndex={index}
                                                                    group={group}
                                                                    onRemoveAssignment={this.props.onRemoveAssignment}
                                                                    setCrewLead={this.props.setCrewLead}
                                                                    crewLeadTimesheets={this.props.crewLeadTimesheets}
                                                                    strings={this.props.strings}
                                                                />
                                                            )
                                                        })
                                                    }
                                                    {
                                                        //Any standalone required equipment (not needing to be assigned to staff)
                                                        //_.sortBy(job.assignments[dayInt].equipment, e => e.description).map((requiredEquipmentType, index) => {
                                                        job.assignments[dayInt].equipment.map((requiredEquipmentType, index) => {
                                                            let key = `${jobIndex}_${dayInt}_equipment_${index}`;
                                                            return (
                                                                <EquipmentJobAssignment
                                                                    key={key}
                                                                    job={job}
                                                                    jobIndex={jobIndex}
                                                                    day={day}
                                                                    dayAssignments={job.assignments[dayInt]}
                                                                    assignmentIndex={index}
                                                                    requiredEquipmentType={requiredEquipmentType}
                                                                    onEquipmentAssignmentChange={this.props.onEquipmentAssignmentChange}
                                                                    onRemoveAssignment={this.props.onRemoveAssignment}
                                                                />
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </td>
                                    )
                                })
                            }
                        </tr>
                    </tbody>
                </table>

                <Modal
                    className="full-screen-modal"
                    backdrop="static"
                    keyboard={false}
                    isOpen={Boolean(selectedOverride)}
                >
                    <ModalHeader>
                        <FlexBetweenRow className="w-100">
                            <span>
                                View Changes for
                                {' '}

                                {selectedOverride?.overrideDay}
                            </span>

                            <span
                                className="fa fa-times fa-lg cursor-pointer"
                                onClick={() => this.handleOverrideButtonClick(null)}
                            ></span>
                        </FlexBetweenRow>
                    </ModalHeader>

                    <ModalBody>
                        <ListGroup>
                            {Boolean(selectedOverride?.startTime) && (
                                <ListGroupItem>
                                    <ListGroupItemText className="mb-1">
                                        Start Time
                                    </ListGroupItemText>
                            
                                    <ListGroupItemHeading className="mb-0">
                                        {selectedOverride.startTime}
                                    </ListGroupItemHeading>
                                </ListGroupItem>
                            )}

                            {Boolean(selectedOverride?.foremenName) && (
                                <ListGroupItem>
                                    <ListGroupItemText className="mb-1">
                                        Foreman
                                    </ListGroupItemText>
                            
                                    <ListGroupItemHeading className="mb-0">
                                        {selectedOverride.foremenName}
                                    </ListGroupItemHeading>
                                </ListGroupItem>
                            )}

                            {Boolean(selectedOverride?.workOrderNumber) && (
                                <ListGroupItem>
                                    <ListGroupItemText className="mb-1">
                                        Work Order Number
                                    </ListGroupItemText>
                            
                                    <ListGroupItemHeading className="mb-0">
                                        {selectedOverride.workOrderNumber}
                                    </ListGroupItemHeading>
                                </ListGroupItem>
                            )}

                            {Boolean(selectedOverride?.meetingAddress) && (
                                <ListGroupItem>
                                    <ListGroupItemText className="mb-1">
                                        Meeting Address
                                    </ListGroupItemText>
                            
                                    <ListGroupItemHeading className="mb-0">
                                        {selectedOverride.meetingAddress}
                                    </ListGroupItemHeading>
                                </ListGroupItem>
                            )}

                            {Boolean(selectedOverride?.jobAddress) && (
                                <ListGroupItem>
                                    <ListGroupItemText className="mb-1">
                                        Job Address
                                    </ListGroupItemText>
                            
                                    <ListGroupItemHeading className="mb-0">
                                        {selectedOverride.jobAddress}
                                    </ListGroupItemHeading>
                                </ListGroupItem>
                            )}

                            {Boolean(selectedOverride?.notes) && (
                                <ListGroupItem>
                                    <ListGroupItemText className="mb-1">
                                        Notes
                                    </ListGroupItemText>
                            
                                    <ListGroupItemHeading className="mb-0">
                                        {selectedOverride.notes}
                                    </ListGroupItemHeading>
                                </ListGroupItem>
                            )}
                        </ListGroup>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}