import React from 'react';
import { Input } from 'reactstrap';

/**
 * Cleanly handles formatting of decimal-based numeric input.  When the user clicks on the control,
 * editing is enabled; when the user blurs, the decimal is formatted.
 *
 * Added: RLC 11/2/20
 * */
export default class NumericInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = { editing: false };
    }

    onChange(event) {
        event.persist();
        this.props.onChange(event);
    }

    toFormatted = (number) => {
        if (number.constructor === String) {
            if (number === '') return '';
            number = parseFloat(number);
        }
        if (this.props.wholeNumber ?? {} === true) {
            return Math.round(number);
        } else {
            return number.toFixed(2);
        }
    };

    toggleEditing = () => this.setState({ editing: !this.state.editing });

    render() {
        return (
            <>
                {this.state.editing ? (
                    <Input
                        className={this.props.className}
                        type="number"
                        step={this.props.step}
                        min={this.props.min ?? -99999}
                        max={this.props.max ?? 999999}
                        placeholder={this.props.placeholder}
                        name={this.props.name}
                        defaultValue={this.props.value ?? ''}
                        onChange={this.onChange.bind(this)}
                        onBlur={this.toggleEditing.bind(this)}
                        required={this.props.required ?? false}
                        disabled={this.props.disabled}
                        onKeyDown={(e) => {
                            if (this.props.preventNegative) {
                                if (
                                    !(
                                        (e.keyCode > 95 && e.keyCode < 106) ||
                                        (e.keyCode > 47 && e.keyCode < 58) ||
                                        e.keyCode == 8
                                    )
                                ) {
                                    return false;
                                }
                            }
                        }}
                    />
                ) : (
                    <Input
                        className={this.props.className}
                        disabled={this.props.disabled}
                        placeholder={this.props.placeholder}
                        type="number"
                        step={this.props.step}
                        max={this.props.max ?? 999999}
                        name={this.props.name}
                        value={this.toFormatted(this.props.value) ?? ''}
                        onChange={this.onChange.bind(this)}
                        onFocus={this.toggleEditing.bind(this)}
                        required={this.props.required ?? false}
                    />
                )}
            </>
        );
    }
}
