import { faTasks } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LinearProgress } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import CommonContext, { ApiRoutes } from "../Common";
import {
    createDataSource,
    createGridOptions,
    DataGrid,
    LinkCellRenderer,
    TextFilterDefaults,
    DateFilterDefaults,
    getServerSideRowRequest,
} from '../common/dataGrid/DataGrid';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';
import HoursReport from '../chargeView/HoursReport';
import { FlexCenterRow, PageHeading, PageWrap, SmallButton, toasty } from '../common/forms/FormElements';
import { Spinner } from 'reactstrap';
import { util } from '../Util';
import TimesheetDetails from '../timesheet/TimesheetDetails';

//Added by MFN.  May need to be removed/altered.
class ChargeViewIndex extends React.Component {

    static contextType = CommonContext;

    constructor(props) {

        super(props);
        this.timesheetDetailsRef = React.createRef();

        this.state = {
            loading: true,
            rowData: [],
            rowsSelected: [],
            showConfirmExportLargeDataset: false,
            showHoursReport: false,
            isGeneratingHoursReport: false,
            timesheetDetailsOpen: false,
        };

        this.onRowSelected = this.onRowSelected.bind(this);
        this.onCellClicked = this.onCellClicked.bind(this);

        this.generateHoursReportRef = React.createRef();
    }

    componentDidMount = () => this.populateState();
    componentWillUnmount = () => this.setState = (state, callback) => { return };

    populateState = async () => {

        const dispatchLocations = await util.fetch.js(ApiRoutes.typeAheads.dispatchLocations());
        const timesheetDetailStatuses = await util.fetch.js(ApiRoutes.typeAheads.timesheetStatuses());
        const yesNo = [
            { label: "Yes", value: 'true' },
            { label: "No", value: 'false' }
        ];

        await this.setState({ dispatchLocations, timesheetDetailStatuses, yesNo });

        const gridOptions = createGridOptions(this);

        gridOptions.components = {
            selectFilter: DataGridSelectFilter,
            selectFloatingFilter: DataGridSelectFloatingFilter,
            nameRenderer: LinkCellRenderer,
        };

        //https://www.ag-grid.com/documentation/javascript/row-selection/#checkbox-selection
        //One column must have "checkboxSelection" set to true.
        //Note: headerCheckboxSelection not available with infinite scroll.
        //gridOptions.rowSelection = "multiple";

        gridOptions.postProcessData = this.transformRowData;
        gridOptions.onRowSelected = this.onRowSelected;

        gridOptions.columnDefs = this.getColumnDefs();

        const dataSource = createDataSource(ApiRoutes.chargeView.search(), gridOptions);

        this.setState({
            loading: false,
            gridOptions: gridOptions,
            dataSource: dataSource
        });

    }

    transformRowData(data) {

        data.rows.forEach(row => {


            if (row.isBreak && row.isPaidBreak) {
                //Combine these columns to save space.
                row.quantityDisplay = `${row.quantity} ${row.unitsName} P`;
            }
            else if (row.isBreak && !row.isPaidBreak) {
                //Combine these columns to save space.
                row.quantityDisplay = `${row.quantity} ${row.unitsName} UP`;
            }
            else {
                //Combine these columns to save space.
                row.quantityDisplay = `${row.quantity} ${row.unitsName}`;
            }



            //Placeholder column so we can have it be the last checkable thing on the right.
            //Otherwise, we could set checkboxSelection = true for an existing column.
            row.selectionPlaceholder = "";
        });
        return { ...data };
    }

    getColumnDefs() {

        //https://www.ag-grid.com/documentation/javascript/column-definitions/

        let dispatchFilterParams = {
            suppressFilterButton: true,
            labelText: 'Filter by Dispatch',
            options: this.state.dispatchLocations,
            optionsLabel: 'label',
            optionsValue: 'value',
        },
            timesheetStatusFilterParams = {
                suppressFilterButton: true,
                options: this.state.timesheetDetailStatuses,
                optionsLabel: 'label',
                optionsValue: 'value',
                initialFilterValue: null
            },
            yesNoFilterParams = {
                suppressFilterButton: true,
                options: this.state.yesNo,
                optionsLabel: 'label',
                optionsValue: 'value',
                initialFilterValue: null
            };

        const defs = [
            {
                headerName: "",
                valueGetter: "node.id",
                sortable: false,
                hide: true,
                flex: 1,
                maxWidth: 35,
                cellRenderer: this.indexCellRenderer
            },
            {
                colId: 'Timesheet.JobDate',
                headerName: 'Date',
                field: 'date',
                sortable: true,
                flex: 1.5,
                sort: { direction: 'asc', priority: 0 },
                filter: 'agDateColumnFilter',
                filterParams: DateFilterDefaults,
                //floatingFilterComponentParams: {
                //    suppressFilterButton: true,
                //}
            },
            {
                colId: 'Timesheet.Job.Contract.Company.CompanyName',
                headerName: 'Customer Name',
                field: 'customerName',
                sortable: true,
                flex: 2,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
                valueGetter: (row) => {
                    if (!!row.data) {
                        let name = `${row.data.customerName}${(!!row.data.subcontractorName ? ` - ${row.data.subcontractorName}` : '')}`;
                        return name;
                    }

                    return '';
                }
            },
            {
                colId: 'Timesheet.TimesheetNumber',
                headerName: 'Timesheet #',
                field: 'timesheetNumber',
                sortable: true,
                flex: 2,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
                cellRenderer: (params) => {
                    if (params.data) {
                        if (params.data.timesheetId) {
                            return (
                                <span
                                    className="site-link"
                                    disabled={!!this.context.formIsOpen}
                                    title='OpenTimesheet'
                                    onClick={() => { this.openTimesheetDetails(params.data.timesheetId) }}
                                >
                                    {params.data.timesheetNumber}
                                    {!!Boolean(!!params.data.timesheetNumber && !!params.data.isSelfDispatching) &&
                                        <span className="ml-2 badge badge-info" style={{ fontSize: '1em', marginTop: '-2px' }}>
                                            {params.data.isNonFlagging ? "NF" : "SD"}
                                        </span>
                                    }
                                </span>
                            );
                        }

                        return params.data.timesheetNumber;
                    }

                    return null;
                },
            },
            {
                colId: 'Timesheet.WorkOrderNumber',
                headerName: 'Work Order',
                field: 'workOrderNumber',
                sortable: true,
                flex: 1.5,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                }
            },
            {
                colId: 'Timesheet.Job.DispatchOffice.CompanyName',
                headerName: 'Dispatching',
                sortable: true,
                flex: 1.5,
                field: 'dispatchOfficeName',
                tooltipField: 'dispatchOfficeName',
                filter: 'selectFilter',
                filterParams: dispatchFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: dispatchFilterParams
            },
            {
                colId: 'ResourceName',
                headerName: 'Resource',
                field: 'resourceName',
                sortable: true,
                flex: 2,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'ChargeTypeName',
                headerName: 'Charge Type',
                field: 'chargeTypeName',
                sortable: true,
                flex: 2,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                }
            },
            {
                colId: "Timesheet.Start",
                headerName: "Start",
                field: "start",
                sortable: false,
                maxWidth: 100,

            },
            {
                colId: "Timesheet.End",
                headerName: "Stop",
                field: "end",
                sortable: false,
                maxWidth: 100,

            },
            {
                colId: 'QuantityDisplay',
                headerName: 'Quantity',
                field: 'quantityDisplay',
                type: "rightAligned",
                sortable: false,
                flex: 1.5,
                cellStyle: (q) => {
                    if ((q.data ?? {}).isBreak) {
                        if (q.data.isPaidBreak) {
                            return { color: 'blue' };
                        }
                        else {
                            return { color: 'red' };
                        }
                    }

                    return {};
                },
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                }
            },
            {
                colId: 'ChargeTypeIsBillable',
                headerName: 'Billable',
                field: 'billable',
                sortable: false,
                flex: 1,
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: yesNoFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: yesNoFilterParams,
            },
            {
                colId: 'Timesheet.Status.Description',
                headerName: 'Status',
                field: 'status',
                sortable: true,
                flex: 2,
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: timesheetStatusFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: timesheetStatusFilterParams,
            },
            //{
            //    colId: 'SelectionPlaceholder',
            //    headerName: '',
            //    field: 'selectionPlaceholder',
            //    sortable: false,
            //    flex: 1,
            //    checkboxSelection: true
            //}
        ];

        return defs;
    }

    onCellClicked = (data) => {
        //test
        alert(data);
    }

    //https://stackoverflow.com/questions/44263350/count-number-of-selected-rows-in-ag-grid
    //Possibly use lodash dequeue?
    onRowSelected(e) {

        const rs = e.api.getSelectedRows();

        this.setState({
            rowsSelected: rs
        });
    }

    async onAcceptRejectButtonClick(isAccept) {

        //TODO: Anything needed with the response.
        const url = ApiRoutes.chargeManagement.update(isAccept);
        await util.fetch.post(url, this.state.rowsSelected);

    }

    openTimesheetDetails = async (id) => {
        if (!!id) {
            await this.context.setFormOpened(true);
            this.timesheetDetailsRef.current.open(id);
        }
    }

    onTimesheetDetailsClosed = async () => {
        await this.context.setFormOpened(false);
        this.setState({ selectedRow: null });
        this.state.gridOptions.refresh();
    }


    //Before allowing the excel export, check specified date range, if any, and try to 
    //protect against abnormally large datasets.  We don't use the way in DataGridToolbar
    //so we can launch a popup beforehand.
    onExportToExcelClicked = async () => {
        const { gridOptions } = { ...this.state };
        const req = getServerSideRowRequest(gridOptions);

        let from = req?.filterModel["Timesheet.JobDate"]?.dateFrom,
            to = req?.filterModel["Timesheet.JobDate"]?.dateTo;

        if (!from || !to) {
            toasty.error('Please specify a date range when exporting Charge View search results to excel.');
        } else {
            var daySpan = moment(new Date(to)).diff(moment(new Date(from)), "days");
            if (daySpan > 30) {
                this.setState({ showConfirmExportLargeDataset: true });
                return false;
            } else {
                this.exportToExcel();
            }
        }
    }

    exportToExcel = async () => {
        await this.setState({ showConfirmExportLargeDataset: false, exporting: true });
        const { gridOptions } = { ...this.state };
        const req = getServerSideRowRequest(gridOptions);
        const fileName = `ChargeViewSearch_${moment().format('YYYYMMDD_hhmmss')}.xlsx`;
        try {
            await util.fetch.downloadFile(ApiRoutes.chargeView.excelExport(), req, fileName);
        } catch (err) {
            toasty.error('Error exporting data: ' + err);
        } finally {
            await this.setState({ exporting: false });
        }
    }

    render() {

        if (this.state.loading) return (<LinearProgress variant="indeterminate" color="secondary" />)

        const { exporting, rowData, gridOptions, showConfirmExportLargeDataset, showHoursReport, isGeneratingHoursReport } = this.state;

        const hasHoursReport = this.context.tenant.tenantSettings.hasHoursReport;


        return (
            <PageWrap>
                <PageHeading>
                    <FontAwesomeIcon icon={faTasks} className="mr-2 text-muted" />
                    <span>View Timecharges</span>
                </PageHeading>
                <DataGridToolbar
                    entity="timechargeDetails"
                    gridApi={this.state.gridApi}
                    dataSource={this.state.dataSource}
                    hideAdd={true}
                    hideExcelButton={true}
                >
                    {hasHoursReport &&

                        <SmallButton
                            disabled={!!this.state.saving}
                            onClick={() => this.setState({ showHoursReport: true })}
                        >
                            <i className="fa fa-file-invoice fa-lg" />
                            <span className="ml-2 small-viewport-hide">Hours Report</span>
                        </SmallButton>
                    }

                    <SmallButton

                        disabled={!!this.state.saving}
                        onClick={this.onExportToExcelClicked}
                    >
                        <i className="fa fa-file-excel fa-lg" />
                        <span className="ml-2 small-viewport-hide">Export to Excel</span>
                    </SmallButton>

                </DataGridToolbar>
                <DataGrid
                    domLayout={"normal"}
                    rowData={rowData}
                    gridOptions={gridOptions}
                    gridStatus={this.state.gridStatus}
                />

                <TimesheetDetails
                    ref={this.timesheetDetailsRef}
                    show={this.state.timesheetDetailsOpen}
                    toggleShow={(open) => this.setState({ timesheetDetailsOpen: open })}
                    onClose={this.onTimesheetDetailsClosed}
                    isBilling={false}
                    isPayroll={false}
                />

                {hasHoursReport &&

                    <Modal size="lg" backdrop="static" keyboard={false} isOpen={showHoursReport}>
                        <ModalHeader>Hours Report</ModalHeader>
                        <ModalBody>
                            <HoursReport generateRef={this.generateHoursReportRef}
                                onGenerateStatusChange={isGen => this.setState({ isGeneratingHoursReport: isGen })}
                                onGenerateError={err => toasty.error("Error generating report: " + err)}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                hidden={!isGeneratingHoursReport}
                            />
                            <Button
                                className="d-flex flex-row flex-nowrap align-items-center"
                                color="primary"
                                innerRef={this.generateHoursReportRef}>
                                Generate Report

                            </Button>
                            <Button
                                className="d-flex flex-row flex-nowrap align-items-center"
                                color="secondary"
                                onClick={() => this.setState({ showHoursReport: false })}>
                                Cancel
                            </Button>
                        </ModalFooter>
                    </Modal>

                }


                <Modal backdrop="static" keyboard={false} isOpen={showConfirmExportLargeDataset}>
                    <ModalHeader>Warning: Large Dataset Request</ModalHeader>
                    <ModalBody>
                        You are requesting a large amount of Charge View data (over 30 days).  This operation could potentially take a long time.  It is recommended that you set an appropriate date range to reduce the size of the request.
                        <br /><br />Proceed?
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            className="d-flex flex-row flex-nowrap align-items-center"
                            color={(!!exporting ? "secondary" : "primary")}
                            disabled={!!exporting}
                            onClick={this.exportToExcel}>
                            Yes, continue
                        </Button>
                        <Button
                            disabled={!!exporting}
                            color="secondary"
                            onClick={() => {
                                this.setState({ showConfirmExportLargeDataset: false })
                            }}
                        >
                            No
                        </Button>
                    </ModalFooter>
                </Modal>
                <Modal backdrop="static" keyboard={false} isOpen={exporting}>
                    <ModalBody className="pt-5 pb-5">
                        <FlexCenterRow>
                            <span className="fa fa-lg fa-spin fa-circle-notch mr-2"></span>
                            <span>Exporting data, please wait...</span>
                        </FlexCenterRow>
                    </ModalBody>
                </Modal>
            </PageWrap>
        );
    }
}

export default withRouter(ChargeViewIndex);