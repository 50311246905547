export class EmployeePayRate {
    id = null;
    employeeId = null;
    payRate = null;
    startDate = null;
    endDate = null;
    rateReceivedDate = null;
}

export class Employee {
    id = null;
    companyId = null;
    userName = '';
    firstName = '';
    lastName = '';
    roles = [];
    dispatchCompanyId = null;
    employeeNumber = '';
    accountStatus = '';
    mobilePhone = '';
    email = '';
    smsNotify = false;
    emailNotify = false;
    password = '';
    confirmPassword = '';
    payRates = [];
    addresses = [];
    restrictions = [];
    onboarding = false;
}

export const EmployeeLocationTypes = {
    Home: 1,
    Routing: 2
}