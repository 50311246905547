import React from 'react';
import { Button, Row, Col } from 'reactstrap';
import { util } from '../Util';
import { Can } from '../Can'
import { SmallButton } from '../common/forms/FormElements';
import CommonContext from '../Common';
import { CircularProgress } from '@material-ui/core';


export class EmployeeComplianceInfo extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true
        }
    }

    componentDidMount() {
        this.populateState();
    }

    async populateState() {
        this.setState({
            loading: false
        });
    }

    render() {
        let { selectedEmployeeCompliances } = this.props;

        if (this.state.loading) {
            return (<CircularProgress color="secondary" variant="indeterminate" />);
        } else {
            return (
                <>
                    <Row className="pb-1">
                        <Col>
                            <div className="d-flex flex-row align-items-center justify-content-end">
                                <Can I="edit" a="employee">
                                    <CommonContext.Consumer>
                                        {(value) => {
                                            return (
                                                <SmallButton
                                                    disabled={!!value.formIsOpen}
                                                    type="button"
                                                    onClick={this.props.onAddEmployeeCompliance}>
                                                    <i className="fa fa-plus-circle fa-md mr-2" />Add Compliance
                                                </SmallButton>
                                            );
                                        }}
                                    </CommonContext.Consumer>
                                </Can>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <table className="table table-sm table-bordered">
                                <thead className="text-muted">
                                    <tr>
                                        <th>Compliance Name</th>
                                        <th>Effective Date</th>
                                        <th>Expiration Date</th>
                                        <th>Notes</th>
                                        <Can I="edit" a="applicationusercompliance">
                                            <th className="text-center" style={{ width: '30px' }}></th>
                                        </Can>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!(selectedEmployeeCompliances ?? []).length
                                        ? <tr><td colSpan="5" className="text-center">No compliances found.</td></tr>
                                        : selectedEmployeeCompliances.map((selectedEmployeeCompliance, i) => {
                                            return (
                                                <tr key={selectedEmployeeCompliance.id}>
                                                    <td>{selectedEmployeeCompliance.complianceType.name}</td>
                                                    <td>{util.date.getShort(selectedEmployeeCompliance.effectiveDate)}</td>
                                                    <td>{util.date.getShort(selectedEmployeeCompliance.expirationDate) ?? 'Current'}</td>
                                                    <td>{selectedEmployeeCompliance.notes}</td>
                                                    <Can I="edit" a="applicationusercompliance">
                                                        <CommonContext.Consumer>
                                                            {(value) => {
                                                                return (<td><Button
                                                                    disabled={!!value.formIsOpen}
                                                                    type="button"
                                                                    color="outline-primary"
                                                                    onClick={() => this.props.onEditEmployeeCompliance(selectedEmployeeCompliance)}
                                                                    size="sm">Edit</Button></td>);
                                                            }}
                                                        </CommonContext.Consumer>
                                                    </Can>
                                                </tr>
                                            )
                                        })}
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </>
            );
        }
    }
}

EmployeeComplianceInfo.contextType = CommonContext;