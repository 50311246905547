import React, { Component } from 'react';
import NavMenu from './NavMenu';
import { ToastContainer } from 'react-toastify';
import { FadeTransition } from './common/forms/FormElements';
import 'react-toastify/dist/ReactToastify.css';
import CommonContext, { ApiRoutes } from './Common';
import authService from './api-authorization/AuthorizeService';
import { util } from './Util';
import { ApplyBranding } from './ApplyBranding';
//import { Redirect } from 'react-router-dom';
//import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants';

export class Layout extends Component {
    static displayName = Layout.name;

    constructor(props) {
        super(props);
        this.state = {
            common: {
                isAuthenticated: false,
                pageTitle: '',
                formIsOpen: false, //global bool for when the app has a modal form opened
                setFormOpened: this.setFormOpened, //universal handler to control global bool
                setPageTitle: this.setPageTitle,
                version: props.version
            }
        }
        this.setFormOpened = this.setFormOpened.bind(this);
        this.setPageTitle = this.setPageTitle.bind(this);
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    populateState = async () => {
        const isAuthenticated = await authService.isAuthenticated();

        //if logged in, get the user details,
        //hydrate the context
        if (isAuthenticated) {

            let [tenant, userProfile] = await Promise.all([
                util.fetch.js(ApiRoutes.tenant.settings()),
                util.fetch.js(ApiRoutes.auth.profile())
            ]);

            userProfile.isAdmin = (userProfile.roles ?? []).map(x => x.name).includes('Administrator');

            let common = { ...this.state };

            common.isAuthenticated = isAuthenticated;
            common.user = userProfile;
            common.permissions = this.props.permissions;
            common.checkUserHasPermission = this.props.permissionChecker;
            common.formIsOpen = false;
            common.setFormOpened = this.setFormOpened;
            common.tenant = tenant;

            this.setState({
                isAuthenticated: isAuthenticated,
                common: common
            });
        }
    }

    setFormOpened = (value) => {
        let { common } = this.state;
        common.formIsOpen = value;        
        this.setState(state => (state.common = common, state));
    }

    setPageTitle = (value) => {
        let { common } = this.state, title = value;
        common.pageTitle = title;
        this.setState(state => (state.common = common, state));
    }

    render() {

        const themeValues = ((this.state.common.tenant ?? {}).tenantSettings ?? {}).defaultTheme;

        return (
            <CommonContext.Provider value={this.state.common}>
                { themeValues && <ApplyBranding colors={{
                    primary: themeValues.primaryColor,
                    secondary: themeValues.secondaryColor,
                    success: themeValues.successColor,
                    warning: themeValues.warningColor,
                    danger: themeValues.dangerColor,
                    information: themeValues.infoColor,
                    defaultHighlight: themeValues.defaultHighlightColor,
                    defaultHighlightAccent: themeValues.defaultHighlightAccentColor,
                }} />}
                <NavMenu />
                <main id="main" className="container-fluid p-0">
                    {this.props.children}
                </main>
                <ToastContainer
                    position="top-center"
                    transition={FadeTransition}
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </CommonContext.Provider>
        );
    }
}
