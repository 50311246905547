import React from 'react';
import { createDataSource, createGridOptions, DataGrid, indexCellRenderer, LinkCellRenderer, TextFilterDefaults } from '../common/dataGrid/DataGrid';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import CommonContext, { agGridConstants, ApiRoutes, AppNavPaths } from '../Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarehouse } from '@fortawesome/free-solid-svg-icons'
import { LinearProgress } from '@material-ui/core';
import { PageHeading, PageWrap } from '../common/forms/FormElements';
import { util } from '../Util';
import { withRouter } from "react-router-dom";
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';
import authService from '../api-authorization/AuthorizeService';

class TenantIndex extends React.Component {

    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        }
    }

    componentDidMount = () => this.populateState();
    componentWillUnmount = () => this.setState = (state, callback) => { return };
    onViewTenant = (id) => this.props.history.push(`${AppNavPaths.Tenant}/${id}`);

    populateState = async () => {

        const [isAuthenticated] = await Promise.all([authService.isAuthenticated(), authService.getUser()]);        

        if (!!isAuthenticated) {
            var [usStates] = await Promise.all([
                util.fetch.js(ApiRoutes.typeAheads.USStates())
            ]);

            let gridOptions = createGridOptions(this);

            gridOptions.components = {
                selectFilter: DataGridSelectFilter,
                selectFloatingFilter: DataGridSelectFloatingFilter,
                nameRenderer: LinkCellRenderer
            };

            gridOptions.columnDefs = [
                {
                    flex: 0,
                    width: 80,
                    headerName: "",
                    valueGetter: "node.id",
                    sortable: false,
                    cellRenderer: indexCellRenderer
                },
                {
                    colId: 'CompanyName',
                    sortable: true,
                    headerName: 'Name',
                    field: 'name',
                    filter: agGridConstants.columnFilterTypes.text,
                    floatingFilter: true,
                    filterParams: TextFilterDefaults,
                    sort: { direction: 'asc', priority: 0 },
                    cellRenderer: 'nameRenderer',
                    cellRendererParams: {
                        clicked: this.onViewTenant,
                        nameField: 'name',
                        idField: 'id',
                        title: 'View this Tenant'
                    }
                },
                {
                    colId: 'Location.Address1',
                    sortable: false,
                    headerName: 'Address',
                    field: 'address1',
                    cellRenderer: (params) => {
                        if (!params.data) return '';
                        return `${params.data.address1}${!!params.data.address2 ? ', ' + params.data.address2 : ''}`;
                    },
                    filter: agGridConstants.columnFilterTypes.text,
                    filterParams: TextFilterDefaults,
                    floatingFilter: true,
                },
                {
                    colId: 'Location.City',
                    sortable: true,
                    headerName: 'City',
                    field: 'city',
                    filter: agGridConstants.columnFilterTypes.text,
                    filterParams: TextFilterDefaults,
                    floatingFilter: true,
                },
                {
                    flex: 0,
                    width: 140,
                    colId: 'Location.State',
                    sortable: true,
                    headerName: 'State',
                    field: 'state',
                    filter: 'selectFilter',
                    floatingFilter: true,
                    filterParams: {
                        labelText: 'Filter by State',
                        options: usStates,
                        optionsLabel: 'label',
                        optionsValue: 'value',
                    },
                    floatingFilterComponent: 'selectFloatingFilter',
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                        options: usStates,
                        optionsLabel: 'label',
                        optionsValue: 'value'
                    }
                },
                {
                    flex: 0,
                    width: 160,
                    colId: 'Location.Zip',
                    sortable: true,
                    headerName: 'Zip',
                    field: 'zip',
                    filter: agGridConstants.columnFilterTypes.text,
                    filterParams: TextFilterDefaults,
                    floatingFilter: true,
                },
            ];

            let ds = createDataSource(ApiRoutes.tenant.search(), gridOptions);

            this.setState({
                isAuthenticated,
                loading: false,
                gridOptions: gridOptions,
                dataSource: ds
            });
        } else {
            //redirect to access denied?
        }
    }

    render() {
        let { isAuthenticated, rowData, gridOptions } = this.state;
        if (!!this.state.loading || !isAuthenticated || !this.context.user) return (<LinearProgress variant="indeterminate" color="secondary" />);
        if (!isAuthenticated || !this.context.user.isAdmin) return ''; //probably redundant
        return (
            <PageWrap>
                <PageHeading>
                    <FontAwesomeIcon icon={faWarehouse} className="mr-2 text-muted" />
                    <span>Tenants</span>
                </PageHeading>
                <DataGridToolbar
                    entity="company"
                    gridApi={this.state.gridApi}
                    dataSource={this.state.dataSource}
                />
                <DataGrid
                    domLayout={"normal"}
                    rowData={rowData}
                    gridOptions={gridOptions}
                    gridStatus={this.state.gridStatus}
                >
                </DataGrid>
            </PageWrap>
        );
    }
}
export default withRouter(TenantIndex);