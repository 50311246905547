import React from 'react';
import { faUserTag, faTrashAlt, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormGroup, Input } from 'reactstrap';
import { BaseFormViewModel } from '../common/ViewModel';
import {
    FormCheckbox,
    FormLabel,
    SmallButton,
    ValidationErrorMessage,
    toasty
} from '../common/forms/FormElements';
import { DailyAttendanceEvent, AssignedDailyAttendanceEvent } from './DailyAttendanceEvent';
import CommonContext, { ApiRoutes } from '../Common';
import SlideForm from '../common/forms/SlideForm';
import { util } from '../Util';
import { handleFormSaveError } from '../common/forms/ValidationError';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import DatePicker from 'react-datepicker';
import { v4 as uuid } from 'uuid';
import $ from 'jquery';

export default class DailyAttendanceEventForm extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();

        let stateBase = Object.assign(
            {
                selectedDailyAttendanceEvent:
                    props.selectedDailyAttendanceEvent ??
                    new DailyAttendanceEvent(),
            },
            new BaseFormViewModel()
        );

        stateBase.addingAssignment = false;
        stateBase.newAssignment = new AssignedDailyAttendanceEvent();
        stateBase.newAssignmentType = 0;

        this.state = stateBase;
        this.onSubmit = this.onSubmit.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    componentDidMount = () => this.populateState();

    populateState = async () => {
        this.setState({
            loading: false,
        });
    };

    resetForm = () => this.setState({ formValidated: false, newAssignment: new AssignedDailyAttendanceEvent() });
    clearErrors = () => this.setState({ errors: {} });

    onSubmit = async (e) => {
        let { selectedDailyAttendanceEvent } = this.props;

        //Clear any fluent api errors
        this.clearErrors();

        //If any events
        if (!!(selectedDailyAttendanceEvent.assignedDailyAttendanceEvents ?? []).length) {
            selectedDailyAttendanceEvent.assignedDailyAttendanceEvents.forEach((evt) => {
                //New events have a guid id, we need to set those to 0 here.
                if (!($.isNumeric(evt.id))) {
                    evt.id = 0;
                }
            });
        }

        this.props.onSaveCallback();
        this.resetForm();
    };

    onClose = (response) => {
        this.resetForm();
        this.context.setFormOpened(false);
        this.props.onClose(response);
    };

    handleSaveError = (err) => handleFormSaveError(this, err);

    onDelete = async (e) => {
        let { selectedDailyAttendanceEvent } = this.props;

        let response = await util.fetch
            .delete(
                ApiRoutes.dailyAttendanceEvent.delete(
                    selectedDailyAttendanceEvent.id
                )
            )
            .catch(this.handleSaveError);

        if (response) {
            this.props.onDeleteCallback(response);
        }
    };

    addAssignment = async () => {
        let { selectedDailyAttendanceEvent,  } = this.props;
        let { newAssignment, newAssignmentType } = { ...this.state }

        newAssignment.dailyAttendanceEvent_Id = selectedDailyAttendanceEvent.id;

        let errorString = '';

        if (!!!newAssignment.title.length) {
            errorString = "Title is required";
        }

        if (newAssignmentType == 1) {
            if (!!!newAssignment.specificDate) {
                if (!!errorString.length) {
                    errorString = errorString + ". A Date must be selected";
                } else {
                    errorString = "A Date must be selected";
                }
            }
        }

        if (!!errorString.length) {
            toasty.error('Validation Errors', errorString);
        } else {

            if (!!!selectedDailyAttendanceEvent.assignedDailyAttendanceEvents) {
                selectedDailyAttendanceEvent.assignedDailyAttendanceEvents = [];
            }

            selectedDailyAttendanceEvent.assignedDailyAttendanceEvents.push(newAssignment);

            this.setState({
                addingAssignment: false,
                newAssignment: new AssignedDailyAttendanceEvent(),
                selectedDailyAttendanceEvent: selectedDailyAttendanceEvent
            });
        }
    };

    removeAssignment = async (id) => {
        let { selectedDailyAttendanceEvent } = this.props;

        var eventToRemove = selectedDailyAttendanceEvent.assignedDailyAttendanceEvents.find(e => e.id == id);

        if (!!eventToRemove) {
            var ind = selectedDailyAttendanceEvent.assignedDailyAttendanceEvents.findIndex(a => a.id == eventToRemove.id);
            selectedDailyAttendanceEvent.assignedDailyAttendanceEvents.splice(ind, 1);
        }

        this.setState({
            selectedDailyAttendanceEvent: selectedDailyAttendanceEvent
        });
    };

    render() {
        let { formValidated, validationMessage, loading, addingAssignment, newAssignmentType, newAssignment } = this.state;
        let { selectedDailyAttendanceEvent, show } = this.props;

        if (!selectedDailyAttendanceEvent) {
            return '';
        }

        const dayOfWeek = [
            { label: 'Sunday', value: 0 },
            { label: 'Monday', value: 1 },
            { label: 'Tuesday', value: 2 },
            { label: 'Wednesday', value: 3 },
            { label: 'Thursday', value: 4 },
            { label: 'Friday', value: 5 },
            { label: 'Saturday', value: 6 }
        ];

        const assignmentTypes = [
            { label: 'Day of Week', value: 0 },
            { label: 'Specific Date', value: 1 }
        ];

        return (
            <SlideForm
                loading={loading}
                show={show}
                id={'dailyAttendanceEventForm'}
                formIcon={faUserTag}
                formTitle={
                    !!parseInt(selectedDailyAttendanceEvent.id ?? 0) > 0
                        ? 'Edit Daily Attendance Event'
                        : 'Add Daily Attendance Event'
                }
                ref={this.formRef}
                setIsValidated={(value) => {
                    this.setState({ formValidated: value });
                }}
                isValidated={formValidated}
                onSubmit={this.onSubmit}
                onClose={this.onClose}
                onSave={this.onSave}
                onDelete={this.onDelete}
                errors={this.state.errors}
                onClearErrors={this.onClearErrors}
                validationMessage={validationMessage}
                entityId={selectedDailyAttendanceEvent.id}
            >
                <FormGroup>
                    <FormLabel htmlFor="name" text="Name" required={true} />
                    <Input
                        id="name"
                        name="selectedDailyAttendanceEvent.name"
                        value={selectedDailyAttendanceEvent.name ?? ''}
                        onChange={this.props.onChange}
                        placeholder="Enter Name (maximum 250 characters)"
                        maxLength="250"
                        pattern="[^><\][\\\x22,;|]+"
                        type="text"
                        required
                    />
                    <ValidationErrorMessage>
                        Name is required and can contain hyphens and periods.
                    </ValidationErrorMessage>
                </FormGroup>

                <FormGroup>
                    <FormCheckbox
                        id="showOnDispatch"
                        name="selectedDailyAttendanceEvent.active"
                        checked={selectedDailyAttendanceEvent.active ?? ''}
                        onChange={this.props.onChange}
                        labelText={'Active?'}
                    />
                </FormGroup>

                <FormGroup>
                    <FormCheckbox
                        id="employeeMayNotBeAssigned"
                        name="selectedDailyAttendanceEvent.employeeMayNotBeAssigned"
                        checked={
                            selectedDailyAttendanceEvent.employeeMayNotBeAssigned ??
                            ''
                        }
                        onChange={this.props.onChange}
                        labelText={
                            'Employees Tagged With This May Not Be Assigned For The Rest Of The Day'
                        }
                    />
                </FormGroup>

                <FormGroup>
                    <label className="control-label" htmlFor={"precedence"} >Order for Default Assignments</label>
                    <Input
                        id="precedence"
                        name="selectedDailyAttendanceEvent.precedence"
                        className="form-control"
                        type="number"
                        step={1}
                        min={0}
                        max={65535}
                        placeholder={this.props.placeholder}
                        value={selectedDailyAttendanceEvent.precedence}
                        onChange={(e) => {
                            if (e.target?.value !== null) {
                                var nval = parseInt(e.target?.value ?? 0);
                                if (isNaN(nval)) {
                                    e.target.value = 0;
                                } else {
                                    e.target.value = nval;
                                }
                            }

                            this.props.onChange(e);
                        }}
                    />
                </FormGroup>

                <FormGroup>
                    {!addingAssignment &&
                        <SmallButton
                            type="button"
                            disabled={
                                addingAssignment
                            }
                            onClick={
                                () => {
                                    this.setState({
                                        addingAssignment: true
                                    });
                                }
                            }
                        >
                            <i className="fa fa-plus-circle fa-md mr-2" />
                            Add Default Assignment
                        </SmallButton>
                    }
                    {addingAssignment &&
                        <SmallButton
                            type="button"
                            onClick={
                                () => {
                                    this.setState({
                                        newAssignment: new AssignedDailyAttendanceEvent(),
                                        addingAssignment: false
                                    });
                                }
                            }
                        >
                            <i className="fa fa-times-circle fa-md mr-2" />
                            Cancel
                        </SmallButton>
                    }
                    <table className="table" >
                        <thead>
                            <tr>
                                <th>Assignment</th>
                                <th>Assigned On</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {addingAssignment && 
                                <tr>
                                    <td colSpan="3">
                                        <ValidatedSelect
                                            id="newAssignmentType"
                                            name="newAssignmentType"
                                            options={assignmentTypes}
                                            value={(assignmentTypes ?? []).find(s => s.value == newAssignmentType) ?? ''}
                                            onChange={(selection) => {
                                                newAssignmentType = selection?.value;
                                                this.setState({ newAssignmentType: newAssignmentType });
                                            }}
                                        />
                                    </td>
                                </tr>
                            }
                            {addingAssignment &&
                                <tr>
                                    <td>
                                        <Input
                                            id="title"
                                            name="newAssignment.title"
                                            value={newAssignment.title ?? ''}
                                            onChange={(e) => {
                                                newAssignment.title = e.target.value;
                                                this.setState({ newAssignment: newAssignment });
                                            }}
                                            placeholder="Enter Title (maximum 250 characters)"
                                            maxLength="250"
                                            type="text"
                                        />
                                    </td>
                                    <td>
                                    {(newAssignmentType == 0) && 
                                        <ValidatedSelect
                                            id="newAssignment.dayOfWeek"
                                            name="newAssignment.dayOfWeek"
                                            options={dayOfWeek}
                                            value={(dayOfWeek ?? []).find(s => s.value == newAssignment.dayOfWeek) ?? ''}
                                            onChange={(selection) => {
                                                newAssignment.dayOfWeek = selection?.value ?? 0;
                                                this.setState({ newAssignment: newAssignment });
                                            }}
                                        />
                                    }
                                    {(newAssignmentType == 1) &&
                                        <DatePicker
                                            className=" css-yk16xz-control"
                                            selected={newAssignment.specificDate}
                                            onChange={date => {
                                                newAssignment.specificDate = date;
                                                this.setState({ newAssignment: newAssignment });
                                            }}
                                            selectsStart
                                            startDate={new Date()}
                                        />
                                    }
                                    </td>
                                    <td>
                                        <SmallButton onClick={this.addAssignment}>
                                            <FontAwesomeIcon className="mr-2" icon={faSave} />
                                            Add
                                        </SmallButton>
                                    </td>
                                </tr>
                            }
                            {(!!!((selectedDailyAttendanceEvent.assignedDailyAttendanceEvents ?? []).length) && !addingAssignment )&& 
                                <tr>
                                    <td colSpan="3" style={{ textAlign: 'center' }}>No Assignments</td>
                                </tr>
                            }
                            {!!((selectedDailyAttendanceEvent.assignedDailyAttendanceEvents ?? []).length) &&
                                selectedDailyAttendanceEvent.assignedDailyAttendanceEvents.map(evt =>
                                    <tr key={uuid()} >
                                        <td>{evt.title}</td>
                                        <td>
                                            {!!evt.specificDate && 
                                                (new Date(evt.specificDate).toDateString())
                                            }
                                            {!!!evt.specificDate &&
                                                dayOfWeek.find(d => d.value == evt.dayOfWeek).label
                                            }
                                        </td>
                                        <td>
                                            <SmallButton onClick={() => { this.removeAssignment(evt.id) }} >
                                                <FontAwesomeIcon className="mr-2" icon={faTrashAlt} />
                                                Remove
                                            </SmallButton>
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </FormGroup>

            </SlideForm>
        );
    }
}
