import React from 'react';
import { Card, CardHeader, CardBody, Table } from 'reactstrap';
import cls from 'classnames';
import PropertyChange from './PropertyChange';

const isInitialEntry = (historicalChangeSet) =>
    historicalChangeSet.systemCreated ||
    historicalChangeSet.propertyChanges.some(
        (pc) => pc.property === 'Id' && pc.previousValue === '0'
    );

const getConcatenatedDisplayName = (historicalChangeSet) =>
    Boolean(
        historicalChangeSet.modifiedByLastName &&
            historicalChangeSet.modifiedByFirstName &&
            historicalChangeSet.modifiedByUserName
    )
        ? `${historicalChangeSet.modifiedByLastName}, ${historicalChangeSet.modifiedByFirstName} (${historicalChangeSet.modifiedByUserName})`
        : 'System Import';

const getRelevantPropertyChanges = (
    historicalChangeSet,
    propertiesToIgnore
) => {
    if (!propertiesToIgnore || !propertiesToIgnore.length) {
        return historicalChangeSet.propertyChanges;
    }

    return historicalChangeSet.propertyChanges.filter(
        (pc) => !propertiesToIgnore.includes(pc.property)
    );
};

export default function HistoricalChangeSet(props) {
    const { historicalChangeSet, propertiesToIgnore, renderOptions, index } =
        props;

    if (
        !historicalChangeSet ||
        !historicalChangeSet.propertyChanges ||
        historicalChangeSet.propertyChanges.length === 0
    ) {
        return <></>;
    }

    // change date is UTC, but the .Net JSON serializer doesn't include a TZ hint
    const utcAppendedDate = historicalChangeSet.changeDate.includes('Z')
        ? historicalChangeSet.changeDate
        : `${historicalChangeSet.changeDate}Z`;

    const changeDate = new Date(utcAppendedDate);

    const initialEntry = isInitialEntry(historicalChangeSet);
    const createdOrModified = initialEntry ? 'Created' : 'Modified';
    const concatenatedDisplayName =
        getConcatenatedDisplayName(historicalChangeSet);

    const relevantPropertyChanges = getRelevantPropertyChanges(
        historicalChangeSet,
        propertiesToIgnore
    );

    return (
        <Card className={cls({ 'mt-3': Boolean(index) })} color="light">
            <CardHeader>
                {createdOrModified} by {concatenatedDisplayName} on{' '}
                {changeDate.toLocaleString()}
            </CardHeader>
            <CardBody>
                <Table striped size="sm" color="light">
                    <thead>
                        <tr>
                            <th>Property</th>
                            <th>Previous Value</th>
                            <th>New Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {relevantPropertyChanges.map((pc, i) => (
                            <PropertyChange
                                propertyChange={pc}
                                key={`${changeDate}${i}`}
                                renderOptions={renderOptions}
                            />
                        ))}
                    </tbody>
                </Table>
            </CardBody>
        </Card>
    );
}
