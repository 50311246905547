import * as moment from "moment";
import { MomentDateFormat } from "../Common";

// SUPPORT CLASSES
export class SelfDispatchTimesheetDetailViewModel {
    id = null;
    timesheetId = null;
    chargeTypeId = null;
    chargeTypeName = null;
    chargeTypeUnitsId = null;
    chargeTypeUnitsName = null;
    start = null;
    end = null;
    value = null;
    notes = null;
    employeeId = null;
    employeeFirstName = null;
    employeeLastName = null;
    equipmentId = null;
    equipmentName = null;
    equipmentTypeId = null;
    equipmentType = null;
    isAdjustment = false;
    include = true;
    removed = false; //if removed through an adjustment
    answers = [];
};

export class SelfDispatchTimesheet {
    id = null;
    timesheetNumber = null;
    date = moment().format(MomentDateFormat.DateOnlyUTCHyphenated);
    nonFlagging = false;
    employeeId = null;
    employeeName = null;
    contractId = null;
    contractDescription = null;
    isComplete = false;
    statusId = null;
    status = null;
    timeEntries = [];
    employeeReview = null;
    clientReview = null;
    statusHistory = null;
    isDeleted = false;
    //surveys = [];
};

export class SelfDispatchTimesheetReviewViewModel {
    id = null;
    timesheetId = null;
    employeeId = null;
    employeeFullName = null;
    notes = null;
    signatureTypeId = null;
    reviewTypeId = null;
    reviewTypeName = null;
    isComplete = null;
    missingSigReasonId = null;
    missingSigReason = null;
    signatureData = null;
}