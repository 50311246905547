import React from 'react';
import _ from 'lodash';
import authService from './api-authorization/AuthorizeService';
import { ValidationError, HandledError } from './common/forms/ValidationError';
import moment from 'moment';
import cloneDeep from 'lodash-es/cloneDeep';
import { FlexColumnStart, toasty } from './common/forms/FormElements';
import Strings from './Strings';
import LocalizedStrings from 'localized-strings';
import cls from 'classnames';

//RLC: Syntactic sugar for vanilla js and other productivity shortcuts.
//================================================================

//Utility services
export const util = {
    //return true if variable equates to undef, null, empty string, empty array, empty object
    isEmpty: (value) => {
        return (
            value == null ||
            (value.hasOwnProperty('length') && value.length === 0) ||
            (value.constructor === Object && Object.keys(value).length === 0)
        );
    },

    form: {
        submitAsync: async function (formElementId) {
            var oFormElement = document.getElementById(formElementId);
            const formData = new FormData(oFormElement);
            return fetch(oFormElement.action, {
                headers: {
                    RequestVerificationToken: document.getElementsByName(
                        '__RequestVerificationToken'
                    )[0].value,
                },
                method: 'POST',
                body: formData,
            });
        },
        serialize: function (formElementId) {
            var obj = {};
            var formData = new FormData(document.getElementById(formElementId));
            for (var key of formData.keys()) {
                obj[key] = formData.get(key);
            }
            return obj;
        },
    },

    array: {
        /**
         * RLC: Upserts objects in an array by provided key match.
         * @param {Object} obj
         * @param {Array} array
         * @param {String} key
         */
        upsert: function (obj, array, key) {
            let cloned = util.object.clone(obj);
            var inx = array.findIndex((item) => item[key] === cloned[key]);
            if (inx >= 0) {
                array[inx] = cloned;
            } else {
                array.push(cloned);
            }
            return array;
        },
        /**
         * RLC: Upserts to primitive array.
         * @param {any} array
         * @param {any} value
         */
        insertIfNotExists: function (array, value) {
            if (!value || !array)
                throw new Error(
                    'util.array.upsertPrimitive: @array and @value parameters are required.'
                );

            var inx = array.findIndex((item) => item === value);
            if (inx < 0) {
                array.push(value);
            }
            return array;
        },
        baseSort: (array, property, ascending) =>
            array.sort((a, b) =>
                a[property] > b[property]
                    ? ascending
                        ? 1
                        : -1
                    : ascending
                    ? -1
                    : 1
            ),
        sortAscending: (array, property) =>
            util.array.baseSort(array, property, true),
        sortDescending: (array, property) =>
            util.array.baseSort(array, property, false),
        areCommon: (a1, a2) => {
            const [shortArr, longArr] =
                a1.length < a2.length ? [a1, a2] : [a2, a1];
            const set = new Set(longArr);
            return shortArr.some((el) => set.has(el));
        },
        uniqueObjects: (objectsArray) => {
            const unique = [
                ...new Set(objectsArray.map((o) => JSON.stringify(o))),
            ].map((string) => JSON.parse(string));
            return unique;
        },
    },

    debounce: function (func, wait, immediate) {
        var timeout;
        return function () {
            var context = this,
                args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    },

    function: {
        async: function (e) {
            return new Promise((resolve, reject) => {
                setTimeout(() => resolve(e), e * 1000);
            });
        },
    },

    //Wrapper over the Fetch API, includes ASP AAF token.
    //TODO: RLC - Need to be able to pass in accept/datatype
    fetch: {
        statusCodes: {
            badRequest: 400,
            unauthorized: 401,
            forbidden: 403,
            notFound: 404,
            methodNotAllowed: 405,
            internalServerError: 500,
            notImplemented: 501,
            serviceNotAvailable: 503,
        },
        format: {
            blob: 'blob',
            json: 'json',
            none: 'none', //maked fetch, promise response can be manually manipulated
        },
        types: {
            get: 'GET',
            put: 'PUT',
            post: 'POST',
            delete: 'DELETE',
        },
        base: async function (url, method, data, format) {
            //RLC: ASP.NET anti forgery token inclusion, if present.
            var aaf_token = null;
            var token_elms =
                document.getElementsByName('__RequestVerificationToken') ?? [];
            if (!!token_elms.length) aaf_token = token_elms[0].value;

            //RLC: Get OIDC token.
            const token = await authService.getAccessToken();

            var opt = {
                //redirect: 'follow' /* follow 302s */,
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            };

            if (aaf_token) opt.headers.RequestVerificationToken = aaf_token;

            if (token) opt.headers.Authorization = `Bearer ${token}`;

            if (
                (method === 'POST' ||
                    method === 'PUT' ||
                    method === 'DELETE') &&
                data
            ) {
                if (data) {
                    opt.body = JSON.stringify(data, function (key, value) {
                        if (value === undefined) {
                            return null;
                        }
                        return value;
                    });
                }
            }

            if (format === util.fetch.format.none) return fetch(url, opt);
            //Fetch with redirect / error handling / validation
            else
                return fetch(url, opt).then(async (response) => {
                    //400-500
                    if (!response.ok) {
                        //Server errors
                        if (response.status >= 500 && response.status <= 599) {
                            //Try to handle service response errors and messages with this
                            if (response.status == 500) {
                                let rj = await response.json();
                                if (!!rj.error) {
                                    throw new HandledError(rj);
                                }
                            } else {
                                throw new Error('Server Error (500) Occurred');
                            }
                        } else if (
                            response.status ===
                            util.fetch.statusCodes.methodNotAllowed
                        ) {
                            throw new Error(
                                'Server Error (405) Method Not Allowed'
                            );
                        } else if (
                            response.status === util.fetch.statusCodes.forbidden
                        ) {
                            throw new Error(
                                'Server Error (403) Occurred - Forbidden/Permission Denied'
                            );
                        } else if (
                            response.status ===
                            util.fetch.statusCodes.unauthorized
                        ) {
                            throw new Error(
                                'Server Error (401) Occurred - Unauthorized'
                            );
                        } else if (
                            response.status ===
                            util.fetch.statusCodes.badRequest
                        ) {
                            let rj = await response.json();
                            if (
                                rj.title ===
                                'One or more validation errors occurred.'
                            ) {
                                throw new ValidationError(rj.errors);
                            }
                        } else {
                            throw new Error(
                                `Server Error Occurred fetching ${url}: ${response.statusText}`
                            );
                        }

                        //200
                    } else {
                        //Files/blob
                        if (format === util.fetch.format.blob)
                            return response
                                .blob()
                                .then((b) => URL.createObjectURL(b));
                        //Quick json fetch
                        else if (format === util.fetch.format.json) {
                            var json = await response.json();
                            return json;
                        } else return await response.json(); //TODO: make provision for when someone wants a naked fetch / custom
                    }
                });
        },

        downloadFile: async function (url, data, fileName) {
            const response = await util.fetch.post(
                url,
                data,
                util.fetch.format.blob
            );
            const a = document.createElement('a');
            a.href = response;
            a.target = '_blank';
            a.download = fileName;
            document.body.appendChild(a);
            a.click();

            a.remove();
        },

        post: function (url, data, format) {
            return util.fetch.base(url, 'POST', data, format);
        },

        get: function (url, format) {
            return util.fetch.base(url, 'GET', null, format);
        },

        put: function (url, data, format) {
            return util.fetch.base(url, 'PUT', data, format);
        },
        delete: function (url, data, format) {
            return util.fetch.base(url, 'DELETE', data, format);
        },

        js: function (url) {
            return util.fetch.base(url, 'GET', null, util.fetch.format.json);
        },

        //RLC: a somewhat OSFA handler for fetch, with error display.
        andGetResponse: async (
            type,
            url,
            data,
            errorHeader,
            onFinally,
            suppressServerErrorBoilerplate
        ) => {
            if (!type || !url)
                throw new Error(
                    'util.fetch.andGetResponse: Missing required arguments.'
                );

            let fetcher = null;

            switch (type) {
                case util.fetch.types.post:
                    fetcher = util.fetch.post;
                    break;
                case util.fetch.types.put:
                    fetcher = util.fetch.put;
                    break;
                case util.fetch.types.get:
                    fetcher = util.fetch.get;
                    break;
                case util.fetch.types.delete:
                    fetcher = util.fetch.delete;
                    break;
                default:
                    throw new Error(
                        'util.fetch.andGetResponse: Type not found.'
                    );
            }

            try {
                let response = await fetcher(url, data, util.fetch.format.none);

                const messageClass = Boolean(suppressServerErrorBoilerplate)
                    ? []
                    : ['pt-2', 'pb-2', 'font-weight-bold'];

                if (response.redirected) {
                    window.location.href = response.url;
                    return false;
                } else if (!!response.ok) {
                    return await response.json();
                } else {
                    let message = '';
                    try {
                        const errResponseData = await response.json();
                        message =
                            typeof errResponseData === 'string'
                                ? errResponseData
                                : errResponseData.message;
                    } catch {
                    } finally {
                        toasty.error(
                            errorHeader ?? 'Error When Processing Request',
                            <FlexColumnStart>
                                {!suppressServerErrorBoilerplate && (
                                    <span>There was a server error:</span>
                                )}
                                {!!message && (
                                    <span
                                        className={cls(messageClass)}
                                    >{`${message}`}</span>
                                )}
                                {!suppressServerErrorBoilerplate && (
                                    <span>
                                        Please try your request again or contact
                                        support for assistance.
                                    </span>
                                )}
                            </FlexColumnStart>
                        );
                    }
                }
            } catch (error) {
                toasty.error(
                    errorHeader ?? 'Server Error',
                    `There was a server error. ${
                        !!error?.message ? `[${error.message}]` : ''
                    }  Please try your request again or contact support for assistance.`
                );
                return null;
            } finally {
                !!onFinally && onFinally();
            }
        },
    },

    file: {
        printFileSize: (bytes, si = false) => {
            let u,
                b = bytes,
                t = si ? 1000 : 1024;
            ['', si ? 'k' : 'K', ...'MGTPEZY'].find(
                (x) => ((u = x), (b /= t), b ** 2 < 1)
            );
            return `${u ? (t * b).toFixed(1) : bytes} ${u}${
                !si && u ? 'i' : ''
            }B`;
        },
    },

    json: function (json) {
        return JSON.stringify(json, function (key, value) {
            if (value === undefined) {
                return null;
            }
            return value;
        });
    },

    navigation: {
        localRedirect: (context, url) => {
            context.props.history.push(url);
        },
        reloadPage: (context) => {
            context.props.history.go(0);
        },
    },

    number: {
        formatFloat: (number) => {
            if (number.constructor === String) {
                if (!number) return '';
                number = parseFloat(number);
            }
            return number.toFixed(2);
        },
        formatCurrency: (number) => {
            if (number.constructor === String) {
                if (!number) return '';
                return '$' + parseFloat(number).toFixed(2);
            }
            return '$' + number.toFixed(2);
        },
    },

    object: {
        clone: (obj) => cloneDeep(obj),
        keyByValue: (obj, value) => {
            return Object.keys(obj).find((key) => obj[key] === value);
        },
        prop: {
            diff: function (firstObject, secondObject) {
                _.reduce(
                    firstObject,
                    function (result, value, key) {
                        return _.isEqual(value, secondObject[key])
                            ? result
                            : result.concat(key);
                    },
                    []
                );
            },
        },
        updateByPath: (obj, keys, value) => {
            let key = keys.shift();
            if (keys.length > 0) {
                let tmp = util.object.updateByPath(obj[key], keys, value);
                return { ...obj, [key]: tmp };
            } else {
                return { ...obj, [key]: value };
            }
        },
    },

    string: {
        capitalize: (s) => {
            if (typeof s !== 'string') return '';
            return s.charAt(0).toUpperCase() + s.slice(1);
        },
        addSpacesToProperCase: (s) => s.replace(/([A-Z])/g, ' $1').trim(),
        format: {
            float: function (strNumber) {
                if (!isNaN(parseFloat(strNumber))) {
                    return parseFloat(strNumber).toLocaleString('en', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    });
                }
                return strNumber;
            },
        },
        toJS: (jsonString) => JSON.parse(JSON.stringify(jsonString)),
        getFileNameExtension: (filename) => {
            var a = filename.split('.');
            if (a.length === 1 || (a[0] === '' && a.length === 2)) {
                return '';
            }
            return (a.pop() || '').toLowerCase();
        },
        cleanText: function (str) {
            let doc = new DOMParser().parseFromString(str, 'text/html');
            return doc.body.textContent || '';
        },
        decodeHTML: function (html_str) {
            var ta = document.createElement('textarea');
            ta.innerHTML = html_str;
            return ta.value;
        },
    },

    select: {
        mapToOptions: (array, labelKey, valueKey) => {
            (array ?? []).map((x) => {
                return { label: x[labelKey], value: x[valueKey] };
            });
        },
        reduceValue: (selection) => {
            return (selection ?? {}).constructor === Array
                ? selection.map((x) => x.value)
                : (selection ?? {}).value;
        },
    },

    date: {
        daysOfTheWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        getShortUTC: function (str) {
            if (!str) return null;

            const d = new Date(str);
            const ye = d.getUTCFullYear();
            let mo = d.getUTCMonth();
            mo++;
            const da = d.getUTCDate();
            return `${mo}/${da}/${ye}`;
        },
        getShort: function (str) {
            if (!str) return null;

            return moment(str).format('MM/DD/YYYY');
        },
        getInputFormat: function (str) {
            if (!str) return null;

            const d = new Date(str);
            const ye = new Intl.DateTimeFormat('en', {
                year: 'numeric',
            }).format(d);
            const mo = new Intl.DateTimeFormat('en', {
                month: '2-digit',
            }).format(d);
            const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(
                d
            );
            return `${ye}-${mo}-${da}`;
        },
        getWeekStarts(weekStartsOn, start, end) {
            let startDate = start ?? moment(),
                endDate = end ?? moment().add(1, 'y'),
                sundayPosition = weekStartsOn ?? 0, //default to Sunday
                weekStarts = [];

            let currentDate = startDate.clone();
            while (currentDate.day(7 + sundayPosition).isBefore(endDate)) {
                weekStarts.push(currentDate.clone());
            }
            return weekStarts;
        },
        getDaysInRange: (startDate, endDate) => {
            let dates = [];
            //to avoid modifying the original date
            const theDate = new Date(startDate);
            while (theDate <= endDate) {
                dates = [...dates, new Date(theDate)];
                theDate.setDate(theDate.getDate() + 1);
            }
            return dates;
        },
        getDaysArrayOrdered: (startDay, endDay) => {
            if (!Number.isInteger(startDay) || !Number.isInteger(endDay))
                throw new Error(
                    'util.date.getDaysIntArray: startDay and endDay must be each be an integer'
                );

            let current = parseInt(startDay);
            let weekStart = 0;
            let dates = [];

            //Add days ordered from the start date, up until Sat.
            while (current <= 6) {
                dates = [...dates, current];
                current += 1;
            }

            //Account for the week start
            while (weekStart < startDay) {
                dates = [...dates, weekStart];
                weekStart += 1;
            }

            return dates;
        },
        getMonthDateRange: (year, month) => {
            var start = moment([year, month - 1]);
            var end = moment(start).endOf('month');
            return { start: start, end: end };
        },
        yearsBetween: (intEndYear, intStartYear = 2021) => {
            const endDate = intEndYear || new Date().getFullYear();
            let yearsBetween = [];
            for (var i = intStartYear; i <= endDate; i++) {
                yearsBetween.push(intStartYear);
                intStartYear++;
            }
            return yearsBetween;
        },
    },

    validation: {
        patterns: {
            email: '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$',
            //chromium's email test
            emailRegex:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            phone: '\\d{3}[\\-]\\d{3}[\\-]\\d{4}',
            htmlPhone: '[0-9]{3}-[0-9]{3}-[0-9]{4}',
            phoneRegex: /[0-9]{3}-[0-9]{3}-[0-9]{4}/g,
        },
        phone: (number) => util.validation.patterns.phoneRegex.test(number),
        email: (str) => util.validation.patterns.emailRegex.test(str),
    },

    fileNameFromContentDisposition: (contentDisposition) => {
        var re = /filename?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/g;
        return contentDisposition.match(re)[0].split('=')[1].slice(0, -1) ?? '';
    },

    l10n: {
        //2022-08-11 - M. Nicol
        //
        //keys - array of localization keys from Common.js > LocalizationKeys.
        //  Not used here yet since, there are only 2 keys and 1 language as of when this was written.
        //  If we have many localized strings in the future though, we can use the keys to get
        //  just the strings we actually need.
        //
        //language -
        //  Intention is to only pass language if you want to override the
        //  automatic language detection from localized-strings.
        //  Auto detection handled by getInterfaceLanguage and getBestMatchingLanguage methods.
        //  in: https://github.com/stefalda/localized-strings/blob/master/src/utils.js
        //  Typically will be based on navigator.language. The browser will typically report "en-US"
        //  for navigator.language, but LocalizedStrings will parse it to get the "en" part.
        //  Coincidentally, navigator.languages is typically ["en-US", "en"].
        getStrings: async (_keys, _language) => {
            const baseKey = 'baseStrings';

            let baseStringified = sessionStorage.getItem(baseKey);

            let baseStrings = baseStringified
                ? JSON.parse(baseStringified)
                : null;

            if (!baseStrings) {
                //TODO: If we have a lot of strings, make API method to get strings by keys
                //and/or language.
                //Note: We could put all strings in CommonContext (see Layout.js), but we want
                //a scalable design where we don't have to load all strings for all languages
                //at once.
                baseStrings = await Strings.getStrings();

                //See "Custom getInterfaceLanguage" method: https://github.com/stefalda/localized-strings/blob/master/README.md
                //Only needed if we want to override the language detected by localized-strings,
                //which we probably don't want.
                //We could then use it as the 2nd argument in the constructor (new LocalizedStrings(strings, options))
                //Example of code we might use for language if it's supplied:
                //const options = language ? { customLanguageInterface: () => language } : null;

                baseStringified = JSON.stringify(baseStrings);
                sessionStorage.setItem(baseKey, baseStringified);
            }

            const localizedStrings = new LocalizedStrings(baseStrings);

            return localizedStrings;
        },
    },
};
