import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserTag } from '@fortawesome/free-solid-svg-icons';
import cls from 'classnames';
import moment from 'moment';

export default class DailyAttendanceEventIndicator extends React.Component {
    getEffectiveDailyAttendanceEvent = () => {
        const { dailyAttendanceEvents } = this.props;

        if (!dailyAttendanceEvents || !dailyAttendanceEvents.length) {
            return null;
        }

        const employeeDailyAttendanceEvent =
            this.getEffectiveEmployeeDailyAttendanceEvent();

        if (!employeeDailyAttendanceEvent) {
            return null;
        }

        return dailyAttendanceEvents.find(
            (dae) =>
                employeeDailyAttendanceEvent.dailyAttendanceEventId == dae.id
        );
    };

    getEffectiveEmployeeDailyAttendanceEvent = () => {
        const { staff, employeeDailyAttendanceEvents } = this.props;

        if (
            !staff ||
            !employeeDailyAttendanceEvents ||
            !employeeDailyAttendanceEvents.length
        ) {
            return null;
        }

        return employeeDailyAttendanceEvents.find(
            (edae) => edae.employeeId == staff.id
        );
    };

    getEffectiveEmployeeDailyAttendanceEventDate = (
        effectiveEmployeeDailyAttendanceEvent
    ) => {
        return Boolean(effectiveEmployeeDailyAttendanceEvent)
            ? moment(effectiveEmployeeDailyAttendanceEvent.eventDate)
                  .toDate()
                  .toLocaleDateString()
            : null;
    };

    getEffectiveDailyAttendanceEventName = (effectiveDailyAttendanceEvent) => {
        return Boolean(effectiveDailyAttendanceEvent)
            ? effectiveDailyAttendanceEvent.name
            : null;
    };

    getExtraClass = (effectiveDailyAttendanceEvent) => {
        return Boolean(
            effectiveDailyAttendanceEvent &&
                effectiveDailyAttendanceEvent.employeeMayNotBeAssigned
        )
            ? 'text-danger'
            : '';
    };

    render = () => {
        const effectiveEmployeeDailyAttendanceEvent =
            this.getEffectiveEmployeeDailyAttendanceEvent();

        const effectiveDailyAttendanceEvent =
            this.getEffectiveDailyAttendanceEvent();

        if (!effectiveDailyAttendanceEvent) {
            return null;
        }

        const dailyAttendanceEventName =
            this.getEffectiveDailyAttendanceEventName(
                effectiveDailyAttendanceEvent
            );

        const employeeDailyAttendanceEventDate =
            this.getEffectiveEmployeeDailyAttendanceEventDate(
                effectiveEmployeeDailyAttendanceEvent
            );

        const extraClass = this.getExtraClass(effectiveDailyAttendanceEvent);

        return (
            <FontAwesomeIcon
                icon={faUserTag}
                className={cls('cursor-pointer', extraClass)}
                title={`${dailyAttendanceEventName} (${employeeDailyAttendanceEventDate})`}
            />
        );
    };
}
