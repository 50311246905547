import {
    faEllipsisV,
    faMinusCircle,
    faTruck,
} from '@fortawesome/fontawesome-free-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
    ButtonGroup,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardFooter,
} from 'reactstrap';
import { FlexStartRow } from '../common/forms/FormElements';
import cls from 'classnames';
import { Weekdays } from '../Common';
import LongTermStatusIndicator from './LongTermStatusIndicator';
import DailyAttendanceEventIndicator from './DailyAttendanceEventIndicator';
import { faFlag } from '@fortawesome/free-solid-svg-icons';
import { isCancelAssignmentTreatedAsUnassigned } from './Dispatch';

export default class StaffCard extends React.Component {
    isActive = (day) => {
        const { jobs, staff } = this.props;

        return jobs.find(
            (j) =>
                !!j.assignments[day.id].staff.find(
                    (g) => !!g.assignment && g.assignment.id == staff.id
                )
        );
    };

    isCancelledButVisible = (cancelledAssignment) => {
        const { jobAssignmentCancellationTypes } = this.props;

        return (
            jobAssignmentCancellationTypes &&
            jobAssignmentCancellationTypes.some(
                (jact) =>
                    jact.id ==
                        cancelledAssignment.jobAssignmentCancellationTypeId &&
                    jact.includeOnRosterView
            )
        );
    };

    isCancelled = (day) => {
        const {
            cancellationJobs,
            staff,
            jobAssignmentCancellationTypeIdsThatPreventReassignment,
            jobAssignmentCancellationTypes,
        } = this.props;

        return cancellationJobs.find((j) =>
            j.assignments[day.id].cancelledAssignments.find(
                (ca) =>
                    ca.assignedEmployeeId == staff.id &&
                    (this.isCancelledButVisible(ca) ||
                        ca.thresholdCancel != 0 ||
                        jobAssignmentCancellationTypeIdsThatPreventReassignment.includes(
                            ca.jobAssignmentCancellationTypeId
                        )) &&
                    !isCancelAssignmentTreatedAsUnassigned(
                        ca,
                        jobAssignmentCancellationTypes
                    )
            )
        );
    };

    getWorkTruckDetails = () => {
        const { staff } = this.props;

        if (!staff.assignedEquipment) {
            return null;
        }

        const workTrucks = staff.assignedEquipment.filter(
            (e) =>
                e.equipmentType.description
                    .toLowerCase()
                    .indexOf('work truck') > -1
        );

        if (!workTrucks || workTrucks.length === 0) {
            return null;
        }

        return workTrucks[0];
    };

    getFlagEquipment = () => {
        const { staff } = this.props;

        if (!staff.assignedEquipment) {
            return [];
        }

        return staff.assignedEquipment.filter(
            (e) =>
                e.equipmentType.description.toLowerCase().indexOf('flag') > -1
        );
    };

    render = () => {
        let {
            staff,
            provided,
            innerRef,
            jobIndex,
            day,
            assignmentIndex,
            group,
            longTermStatuses,
            employeeLongTermStatuses,
            dailyAttendanceEvents,
            employeeDailyAttendanceEvents,
            hiddenStaffIds,
            weekdays,
            compact,
            minimal,
            removable,
        } = this.props;

        const workTruckDetails = this.getWorkTruckDetails();
        const flagEquipment = this.getFlagEquipment();

        if (hiddenStaffIds.includes(staff.id)) {
            return (
                <div
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={innerRef}
                />
            );
        }

        return (
            <div
                className={cls('card staffCard', {
                    compact: !!compact,
                    minimal: !!minimal,
                })}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={innerRef}
            >
                {!compact && (
                    <div className="dragIndicator">
                        <FontAwesomeIcon icon={faEllipsisV} />
                        <FontAwesomeIcon icon={faEllipsisV} />
                    </div>
                )}
                <CardBody>
                    <CardTitle className="text-left d-flex flex-row justify-content-between align-items-center">
                        <span className="d-flex flex-row flex-fill justify-content-between align-items-center">
                            {group && group.assignment && (
                                <label className="mr-1 dropPlaceholder">{`${group.name}`}</label>
                            )}
                            <span className="mr-2 staff-name">
                                {staff.name}
                            </span>
                            <DailyAttendanceEventIndicator
                                staff={staff}
                                dailyAttendanceEvents={dailyAttendanceEvents}
                                employeeDailyAttendanceEvents={
                                    employeeDailyAttendanceEvents
                                }
                            />
                            <LongTermStatusIndicator
                                staff={staff}
                                longTermStatuses={longTermStatuses}
                                employeeLongTermStatuses={
                                    employeeLongTermStatuses
                                }
                            />
                        </span>
                        {!!removable && (
                            <FontAwesomeIcon
                                title="Remove this personnel"
                                icon={faMinusCircle}
                                className="text-danger cursor-pointer"
                                onClick={() =>
                                    this.props.onRemoveStaffAssignment(
                                        jobIndex,
                                        day.getDay(),
                                        assignmentIndex
                                    )
                                }
                            />
                        )}
                    </CardTitle>
                    <CardSubtitle
                        className="mt-1 mb-1 text-muted"
                        hidden={!!compact}
                    >
                        <div className="d-flex flex-row">
                            <FlexStartRow>
                                {
                                    <span
                                        title={staff.groups
                                            .map((g) => g.groupName)
                                            .join(', ')}
                                        className="badge badge-lg badge-secondary cursor-pointer mr-1"
                                    >
                                        {staff.groups
                                            .map((g) =>
                                                g.groupName
                                                    .charAt(0)
                                                    .toUpperCase()
                                            )
                                            .join(', ')}
                                    </span>
                                }
                                {Boolean(workTruckDetails) && (
                                    <div
                                        className="cursor-pointer"
                                        title={`${workTruckDetails.equipmentType.description} - ${workTruckDetails.identifier}`}
                                    >
                                        <FontAwesomeIcon icon={faTruck} />
                                    </div>
                                )}
                                {flagEquipment.length > 0 &&
                                    flagEquipment.map(
                                        (flagEquipmentDetails) => (
                                            <div
                                                className="cursor-pointer ml-1"
                                                key={flagEquipmentDetails.id}
                                                title={`${flagEquipmentDetails.equipmentType.description} - ${flagEquipmentDetails.identifier}`}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faFlag}
                                                />
                                            </div>
                                        )
                                    )}
                            </FlexStartRow>
                            <div
                                className={cls(
                                    'd-flex flex-row justify-content-end'
                                )}
                            >
                                {
                                    //<FontAwesomeIcon icon={faClock} />
                                    //<span></span>
                                }
                            </div>
                        </div>
                    </CardSubtitle>
                    {!compact && (
                        <CardFooter>
                            <ButtonGroup className="" size="sm">
                                {weekdays.map((dayDate) => {
                                    const day = Weekdays.find(
                                        (x) => x.id === dayDate.getDay()
                                    );
                                    return (
                                        <span
                                            key={day.id}
                                            className={cls(
                                                'btn btn-sm btn-outline-secondary scheduledDayIndicator',
                                                {
                                                    active: this.isActive(day),
                                                    cancelled:
                                                        this.isCancelled(day),
                                                }
                                            )}
                                        >
                                            {day.abbreviationShort}
                                        </span>
                                    );
                                })}
                            </ButtonGroup>
                        </CardFooter>
                    )}
                </CardBody>
            </div>
        );
    };
}
